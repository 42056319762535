import React, { useEffect, useState, useMemo } from "react";
import Card from "./Card";
import aporiLogo from "../../assets/V4/apori.svg";
import sideIllustration from "../../assets/V4/sidemask.svg";
import Ranking from "./Ranking";
import {
  AiFillThunderbolt,
  AiOutlineClose,
  AiOutlineArrowRight,
} from "react-icons/ai";
import MainHeader from "./MainHeader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ExploreDApp from "./Exploredapp";
import PrevHeader from "./Header";
import { useWallet } from "./hooks/content";
import Tslogin from "./Tsconnect";
import { ExplorerTabs, walletType } from "./hooks/types";
import { Appplyreferral } from "./hooks/helper";
import StakeAndEarn from "./StakeAndEarn";
import TownesquareNFT from "./TownesquareNFT";
import ArtistPage from "./Artist";
import MonadVolume from "./MonadVolume";
import { DiscordIcon, XIcon } from ".";
import { lazy, Suspense } from "react";
import SvgRankComponent from "../../assets/SVG/rank5";
import SvgExploreComponent from "../../assets/SVG/Explore1";
import SvgStakeComponent from "../../assets/SVG/StakeIcon";
import SvgArtistComponent from "../../assets/SVG/ArtistIcon";
import SvgVolComponent from "../../assets/SVG/volume";
import { toast, ToastContainer } from "react-toastify";

interface LoginButtonProps {
  title: string;
  logo: string;
  onClick?: () => void;
  roundedTop?: boolean;
  roundedBottom?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  title,
  logo,
  onClick,
  roundedTop,
  roundedBottom,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex h-[58px] items-center px-5 -mb-2 bg-[#FFFFFF08] border border-white/40 
      ${roundedTop ? "rounded-t-[8px]" : ""} 
      ${roundedBottom ? "rounded-b-[8px]" : ""}
      ${!roundedTop && !roundedBottom ? "rounded-[1px]" : ""} 
      justify-between w-[490px] hover:bg-[#FFFFFF1A]`}
    >
      <div className="flex items-center gap-2">
        <img src={logo} className="px-2" alt={title} />
        <p className="text-base font-medium text-white">{title}</p>
      </div>
      <div>
        <AiOutlineArrowRight size={20} color="#B882ff" />
      </div>
    </button>
  );
};

//LoginModal
export const ReferralPageModal: React.FC<{ onCancelProcess: () => void }> = ({
  onCancelProcess,
}) => {
  const [referralCode, setReferralCode] = useState<string[]>(Array(5).fill(""));
  const [error, setError] = useState<string>("");

  const handleInputChange = (value: string, index: number) => {
    const newCode = [...referralCode];
    if (/^[a-zA-Z0-9]?$/.test(value)) {
      newCode[index] = value.toUpperCase();
      setReferralCode(newCode);
      if (value && index < 4) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData
      .getData("Text")
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, "");
    const newCode = Array(5).fill("");

    for (let i = 0; i < Math.min(pastedData.length, 5); i++) {
      newCode[i] = pastedData[i];
    }

    setReferralCode(newCode);

    // Focus the last filled input
    const lastFilledIndex = Math.min(pastedData.length, 5) - 1;
    const nextInput = document.getElementById(`input-${lastFilledIndex}`);
    nextInput?.focus();
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (!referralCode[index] && index > 0) {
        const prevInput = document.getElementById(`input-${index - 1}`);
        prevInput?.focus();
      }
    }
  };

  const isCodeComplete = referralCode.every((char) => char.length > 0);
  const handleReferralSubmit = async () => {
    if (!isCodeComplete) {
      setError("Please fill in all boxes.");
      return;
    }
  
    setError("");
    const fullReferralCode = referralCode.join("");
  
    try {
      const { success, message } = await Appplyreferral({
        referralcode: fullReferralCode,
      });
  
      if (success) {
        alert("Referral code applied successfully!");
        toast.success("Referral code is correct", {
          position: "top-right",
          autoClose: 2000,  
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        

  
        // Delay modal closing slightly
        setTimeout(() => {
          onCancelProcess();
        }, 1500);  
      } else {
        setError(message);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setError("An unexpected error occurred. Please try again.");
    }
  };
  

  return (
    <>
    <ToastContainer />
    <div className="fixed inset-0 flex items-center justify-center bg-black z-max bg-opacity-90">
       
      <div className="relative bg-[#222222] rounded-2xl px-8 w-[320px] md:w-[415px] md:h-[368px] h-[330px] flex flex-col items-center border border-[#FFFFFF66] ">
        <button className="absolute mt-5 right-8" onClick={onCancelProcess}>
          <img src="/assets/main_v4/close.svg" className="h-[24px] w-[24px]" />
        </button>
        <p className="text-center mt-5 p-4 font-Outfit md:text-[23px] text-[20px] text-white">
          Insert referral code <br /> to increase your ranking
        </p>
        <div className="flex gap-2 mt-8 mb-4">
          {referralCode.map((char, index) => (
            <input
              key={index}
              id={`input-${index}`}
              type="text"
              maxLength={1}
              value={char}
              onPaste={handlePaste}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 text-center text-lg bg-[#121212] text-white border border-[#555555] rounded-md focus:outline-none"
            />
          ))}
        </div>
        {error && <p className="mb-2 text-sm text-red-500">{error}</p>}
        <button
          onClick={handleReferralSubmit}
          disabled={!isCodeComplete}
          className={`mb-4 mt-4 w-[283px] h-[44px] rounded-4xl ${
            isCodeComplete
              ? "bg-[#9264F8] text-white"
              : "bg-[#6B549E] text-gray-300 cursor-not-allowed"
          }`}
        >
         Insert referral code
        </button>
        <button
          onClick={onCancelProcess}
          className="text-[16px] font-medium leading-[20.16px] tracking-[0.02em] text-center text-[#B882FF]"
        >
          I don’t have a referral code
        </button>
      </div>
    </div>
    </>
  );
};

//EpochFlow Modal
export const EpochFlow: React.FC<{ onCancelProcess: () => void }> = ({
  onCancelProcess,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center mt-8 bg-black bg-opacity-70">
      <div className="md:w-[794px] md:h-[394px] h-[731px] w-[320px] border border-[#FFFFFF66] md:pt-7 md:px-12 md:pb-16 px-4 py-6 bg-[#222222] rounded-lg text-white">
        {/* Header */}
        <div className="flex flex-col items-center md:justify-between md:mb-10 md:flex-row">
        <button onClick={onCancelProcess} className="flex items-end block p-1 ml-auto md:hidden hover:opacity-80">
            <AiOutlineClose size={22} color="#ffffff" />
          </button>
          <p className="text-[30px] font-bold">Epoch 1</p>
          <button onClick={onCancelProcess} className="hidden p-1 md:flex hover:opacity-80">
            <AiOutlineClose size={22} color="#ffffff" />
          </button>
        </div>

        <p className="md:text-lg text-[20px] font-normal leading-[26.84px] mb-10 text-center md:text-start">
          Perform actions on TowneSquare to improve your ranking
        </p>

        {/* Actions Grid */}
        <div className="flex flex-col items-center gap-3 text-sm text-gray-300 md:flex-row md:justify-between md:space-x-18 ">
          {[
            {
              src: "/assets/main_v4/sswap.svg",
              text: "Swap tokens on any Onboarding app",
              class: "w-[102px] h-[85px]",
            },
            {
              src: "/assets/main_v4/lend.svg",
              text: "Lend tokens from any Onboarding app",
              class: "w-[85px] h-[96px]",
            },
            {
              src: "/assets/main_v4/stake.svg",
              text: "Stake tokens on any Onboarding app",
              class: "w-[100px] h-[85px]",
            },
            {
              src: "/assets/main_v4/bborow.svg",
              text: "Borrow tokens from any onboarding app",
              class: "w-[100px] h-[85px]",
            },
          ].map((action, index) => (
            <div
              key={index}
              className="flex flex-row items-start space-x-2 md:flex-col"
            >
              <img
                src={action.src}
                alt={action.text}
                className={action.class}
              />
              <span className="text-[18px] text-start font-normal block w-full leading-[24px] mt-2">
                {action.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const OnboardPage = () => {
  const [isEpochnOpen, setisEpochnOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pubAddress, setPubAddress, connectedWallet } = useWallet();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("ExplorerTab");
    return location.state?.ExplorerTab || storedTab || "Explore apps";
  });
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  // useEffect(() => {
  //   const checkMobile = () =>
  //     setIsMobile(window.matchMedia("(max-width: 768px)").matches);

  //   checkMobile();
  //   window.addEventListener("resize", checkMobile);

  //   return () => window.removeEventListener("resize", checkMobile);
  // }, []);

  useEffect(() => {
    if (location.state?.ExplorerTab) {
      setActiveTab(location.state.ExplorerTab);
      sessionStorage.setItem("ExplorerTab", location.state.ExplorerTab);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.ExplorerTab) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state]);

  const handleCloseModal = () => {
    setisEpochnOpen(false);
  };

  const handleOConnect = () => {
    setShowLoginModal(true);
  };
  const handleOfConnect = () => {
    setShowLoginModal(false);
  };

  const disconnectWallet = () => {
    console.log("Disconnecting wallet...");
    setPubAddress(null);
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("pubAddress");
  };

  useEffect(() => {
    if (pubAddress) {
      setShowLoginModal(false);
    } else {
      setShowLoginModal(false);
    }
  }, [pubAddress]);

  useEffect(() => {
    if (!tab) {
      navigate("/onboard/explore-apps", { replace: true });
    }
  }, [tab, navigate]);

  if (isMobile) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          color: "white",
          backgroundColor: "black",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2>The mobile version is currently in progress</h2>
        <p>In the meantime, please access TowneSquare on a PC.</p>
      </div>
    );
  }

  return (
    <div className="flex text-white bg-black ">
      <ToastContainer />
      {/* Sidebar */}
      <aside className="w-[244px] z-30 fixed h-screen bg-[#121212] p-[22px] hidden md:flex flex-col justify-between">
        <div>
          <div className="flex items-center mt-4 w-[166px]">
            <Link to="/">
              <img src="/assets/main_v4/theloggo.svg" alt="Townesquare logo" />
            </Link>
          </div>
          <img src="/assets/main_v4/sidemask.svg" alt="Townesquare sidemask" />
          <div className="">
            <button
              onClick={() => setisEpochnOpen(!isEpochnOpen)}
              className="flex items-center justify-between w-[126px] h-[44px] px-8 py-6  -mt-6 gap-2 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
            >
              <span className="text-white text-[15px]">Epoch</span>
              <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px]  pr-2 pl-2">
                1
              </span>
            </button>
          </div>

          {/* <img
            className="w-auto h-auto my-8 cursor-pointer"
            src="assets/main_v4/SideBar.svg"
            onClick={() => {
              setActiveTab("TownSquare NFT");
              sessionStorage.setItem("ExplorerTab", "TownSquare NFT");
            }}
            alt="Sidebar"
          /> */}
          <Link to="/onboard/townesquare-nft">
            <img
              className="w-auto h-auto my-8 cursor-pointer"
              src="/assets/main_v4/sidelogoo.png"
              alt="Townesquare NFT"
            />
          </Link>

          <nav>
            <ul className="flex flex-col gap-[32px] text-sm font-bold">
              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "ranking" ? "text-purple-400" : ""
                }`}
              >
                <Link to="/onboard/ranking" className="flex items-center gap-3">
                  <SvgRankComponent
                    fill={tab === "ranking" ? "#B882FF" : "white"}
                  />
                  Testnet Ranking
                </Link>
              </li>

              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "explore-apps" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/explore-apps"
                  className="flex items-center gap-3"
                >
                  <SvgExploreComponent
                    fill={tab === "explore-apps" ? "#B882FF" : "white"}
                  />
                  Explore apps
                </Link>
              </li>

              {/* <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "stake-earn" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/stake-earn"
                  className="flex items-center gap-3"
                >
                  <SvgStakeComponent
                    fill={tab === "stake-earn" ? "#B882FF" : "white"}
                  />
                  Stake & Earn
                </Link>
              </li> */}

              <li
                className={`flex items-center cursor-pointer gap-3 text-[15px] ${
                  tab === "townesquare-nft" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/townesquare-nft"
                  className="flex items-center gap-3"
                >
                  <link
                    rel="preload"
                    href="/assets/main_v4/DFNFT.svg"
                    as="image"
                  />
                  <link
                    rel="preload"
                    href="/assets/main_v4/RNNFT.svg"
                    as="image"
                  />
                  <img
                    src={
                      tab === "townesquare-nft"
                        ? "/assets/main_v4/DFNFT.svg"
                        : "/assets/main_v4/RNNFT.svg"
                    }
                    alt="NFT Icon"
                  />
                  TownSquare NFTs
                </Link>
              </li>

              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "artist-spotlight" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/artist-spotlight"
                  className="flex items-center gap-3"
                >
                  <link
                    rel="preload"
                    href="/assets/main_v4/Ar.svg"
                    as="image"
                  />
                  <link
                    rel="preload"
                    href="/assets/main_v4/Articon.svg"
                    as="image"
                  />
                  <img
                    src={
                      tab === "artist-spotlight"
                        ? "/assets/main_v4/Ar.svg"
                        : "/assets/main_v4/Articon.svg"
                    }
                    className="w-6 h-6"
                    alt="aRT Icon"
                  />
                  Artist Spotlight
                </Link>
              </li>

              {/* <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "monad-volume-share" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/monad-volume-share"
                  className="flex items-center gap-3"
                >
                  <SvgVolComponent
                    fill={tab === "monad-volume-share" ? "#B882FF" : "white"}
                  />
                  Monad Volume Share
                </Link>
              </li>  */}
            </ul>
          </nav>
        </div>
      </aside>

      {isMobileNavOpen && (
        <div className="fixed top-0 left-0 w-[288px] h-full bg-[#121212] z-40 flex flex-col items-start px-6 pt-8 pb-6">
          {/* Header Section */}
          <div className="flex items-center justify-between w-full">
            <img
              src="/assets/main_v4/epochlogo.svg"
              alt="Epoch Logo"
              className="h-8"
            />
            <button
              onClick={() => setIsMobileNavOpen(false)}
              className="text-2xl text-white"
            >
              ✕
            </button>
          </div>

          <div className="flex items-center mt-4 w-[166px]">
            <Link to="/">
              <img src="/assets/main_v4/theloggo.svg" alt="Townesquare logo" />
            </Link>
          </div>
          <img src="/assets/main_v4/sidemask.svg" alt="Townesquare sidemask" />
          <div>
            <button
              onClick={() => setisEpochnOpen(!isEpochnOpen)}
              className="flex items-center justify-between w-[126px] h-[44px] px-8 py-6  -mt-6 gap-2 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
            >
              <span className="text-white text-[15px]">Epoch</span>
              <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px]  pr-2 pl-2">
                1
              </span>
            </button>
          </div>
          <Link to="/onboard/townesquare-nft">
            <img
              className="w-auto h-auto my-4 cursor-pointer w-[160px]"
              src="/assets/main_v4/sidelogoo.png"
              alt="Townesquare NFT"
            />
          </Link>

          {/* Navigation Links */}
          <nav className="flex flex-col items-start w-full gap-4 mt-4">
            {[
              {
                path: "/onboard/ranking",
                icon: "rankicon.svg",
                active: "ranksel.svg",
                label: "Testnet Ranking",
              },
              {
                path: "/onboard/explore-apps",
                icon: "Exploreicon.svg",
                active: "exploresel.svg",
                label: "Explore Apps",
              },
              {
                path: "/onboard/stake-earn",
                icon: "stakeicon.svg",
                active: "stakesel.svg",
                label: "Stake & Earn",
              },
              {
                path: "/onboard/townesquare-nft",
                icon: "RNNFT.svg",
                active: "DFNFT.svg",
                label: "TownSquare NFTs",
              },
              {
                path: "/onboard/artist-spotlight",
                icon: "Articon.svg",
                active: "Ar.svg",
                label: "Artist Spotlight",
              },
              {
                path: "/onboard/monad-volume-share",
                icon: "volume.svg",
                active: "volumeSe.svg",
                label: "Monad Volume Share",
              },
            ].map(({ path, icon, active, label }) => {
              const isActive = location.pathname === path;

              return (
                <Link
                  key={path}
                  to={path}
                  className={`flex items-center gap-3 text-lg w-full py-3 ${
                    isActive ? "text-[#B882FF] font-semibold" : "text-white"
                  }`}
                  onClick={() => setIsMobileNavOpen(false)}
                >
                  <img
                    src={
                      isActive
                        ? `/assets/main_v4/${active}`
                        : `/assets/main_v4/${icon}`
                    }
                    className="w-6 h-6"
                    alt={`${label} Icon`}
                  />
                  {label}
                </Link>
              );
            })}
          </nav>

          {/* Action Button */}
          <button
            className="mt-auto w-full bg-white text-black text-lg font-semibold py-3 rounded-xl h-[48px]"
            onClick={() => setIsMobileNavOpen(false)}
          >
            Get Started
          </button>
        </div>
      )}

      {/* mobile header */}
      {/* <div className="fixed flex justify-center w-[260px] flex md:hidden w-[380px] px-3 mt-6 z-max">
        <div className="flex items-center justify-between w-full px-4 py-6 bg-black/80">
          <img
            onClick={() => {
              navigate("/");
            }}
            src="/assets/main_v4/tlogo.svg"
            className="w-auto h-auto"
            alt="logo"
          />
          <img
            onClick={() => setisEpochnOpen(!isEpochnOpen)}
            src="/assets/main_v4/epoc.svg"
            className="w-auto h-auto"
            alt="logo"
          />
          <div className="flex items-center gap-x-6">
            <XIcon />
            <DiscordIcon />
            <img
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
              src="/assets/main_v4/List.svg"
              className="w-auto h-auto"
              alt="menu"
            />
          </div>
        </div>
      </div> */}

      {/* Main Content */}
      <main className="flex-1 md:ml-[240px] min-h-screen  w-[calc(100vw-240px)]">
        {tab === "explore-apps" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />

            <ExploreDApp />
          </div>
        )}
        {tab === "ranking" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={"Ranking"}
            />
            <Ranking />
          </div>
        )}
        {/* {tab === "stake-earn" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div
              className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"
              style={{
                backgroundImage: "url(/assets/main_v4/StakeAndEarnBg.svg)",
                backgroundSize: "contain",
              }}
            ></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title="Stake & Earn"
            />
            <StakeAndEarn onConnect={handleOConnect} />
          </div>
        )} */}

        {tab === "townesquare-nft" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={"TowneSquare NFTs"}
            />
            <TownesquareNFT onConnect={handleOConnect} />
          </div>
        )}

        {tab === "artist-spotlight" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={"Artist Spotlight"}
            />
            <ArtistPage />
          </div>
        )}
      </main>
      {showLoginModal && <Tslogin onCancelProcess={handleOfConnect} />}
      {isEpochnOpen && <EpochFlow onCancelProcess={handleCloseModal} />}
    </div>
  );
};

export default OnboardPage;
