import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dialogState {
  isSolanaConnectWalletModalOpen: boolean;
} 

const initialState: dialogState = {
  isSolanaConnectWalletModalOpen: false,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    toggleSolanaConnectWalletModal: (state, action: PayloadAction<boolean>) => {
      state.isSolanaConnectWalletModalOpen = action.payload;
    },
    
  },
});

export const { toggleSolanaConnectWalletModal  } = dialogSlice.actions;
export default dialogSlice.reducer;
