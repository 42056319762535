import { useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ScriptableContext,
  TooltipItem,
  ChartType,
} from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

interface TreemapData {
  name: string;
  percent: string;
  value: string;
  chart: string;
  logo: string;
  rank?: string;
}

interface TreemapNode {
  _data?: TreemapData;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}

const data: TreemapData[] = [
  {
    name: "aPriori",
    percent: "15.2%",
    value: "$1,200.45",
    chart: "-4.2%",
    logo: "/assets/main_v4/apori.svg",
    rank: "1",
  },
  {
    name: "Kuru",
    percent: "14.4%",
    value: "$1,186.26",
    chart: "+5.1%",
    logo: "/assets/main_v4/kurudlogo.svg",
    rank: "2",
  },
  {
    name: "Ethena",
    percent: "9.8%",
    value: "$1,126.16",
    chart: "+3.9%",
    logo: "/assets/main_v4/ethena.svg",
    rank: "3",
  },
  {
    name: "Magma",
    percent: "7.2%",
    value: "$1,200.45",
    chart: "-4.2%",
    logo: "/assets/main_v4/magma.svg",
  },
  {
    name: "Wormhole",
    percent: "6.4%",
    value: "$1,200.45",
    chart: "+1.2%",
    logo: "/assets/main_v4/worhh.svg",
  },

  {
    name: "Narwhal",
    percent: "6.4%",
    value: "$1,200.45",
    chart: "+2.2%",
    logo: "/assets/main_v4/narwa.svg",
  },
  {
    name: "Aave",
    percent: "3.2%",
    value: "$1,200.45",
    chart: "-1.2%",
    logo: "/assets/main_v4/AAVE.svg",
  },
  {
    name: "Lido",
    percent: "4.8%",
    value: "$1,200.45",
    chart: "+3.1%",
    logo: "/assets/main_v4/lido.svg",
  },
  {
    name: "1inch",
    percent: "3.9%",
    value: "$1,200.45",
    chart: "-1.4%",
    logo: "/assets/main_v4/inch.svg",
  },
  {
    name: "Perpl",
    percent: "3.5%",
    value: "$1,200.45",
    chart: "+0.4%",
    logo: "/assets/main_v4/pearl.svg",
  },

  {
    name: "Uniswap",
    percent: "5.9%",
    value: "$1,200.45",
    chart: "-0.9%",
    logo: "/assets/main_v4/unnisw.svg",
  },
  {
    name: "PancakeSwap",
    percent: "3.8%",
    value: "$1,200.45",
    chart: "+0.4%",
    logo: "/assets/main_v4/pancake.svg",
  },
  {
    name: "Jupiter",
    percent: "4.9%",
    value: "$1,200.45",
    chart: "-2.2%",
    logo: "/assets/main_v4/jupite.svg",
  },
];

const options: ChartJS<"treemap">["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: { display: false },
    legend: { display: false },
  },
};

const config = {
  type: "treemap" as const,
  data: {
    datasets: [
      {
        tree: data.map((item) => ({
          ...item,
          percent: parseFloat(item.percent),
        })),
        data: [] as TreemapNode[],
        key: "percent",
        captions: {
          display: false,
          formatter: (ctx: ScriptableContext<"treemap">) => {
            const node = ctx.raw as TreemapNode;
            return node?._data?.name || "";
          },
          font: { size: 11 },
        },
        backgroundColor: (ctx: ScriptableContext<"treemap">) => {
          const node = ctx.raw as TreemapNode;
          const change = parseFloat(node?._data?.chart || "0");
          return change >= 0
            ? "rgba(42, 182, 118, 0.4)"
            : "rgba(255, 68, 113, 0.4)";
        },
        spacing: 2,
        labels: {
          display: false,
          formatter: (ctx: ScriptableContext<"treemap">) => {
            const node = ctx.raw as TreemapNode;
            return [node?._data?.name].filter(Boolean) as string[];
          },
          font: { size: 12, family: "Outfit" },
          color: "#333",
        },
      },
    ],
  },
  plugins: [
    {
      id: "customDraw",
      afterDraw: (chart: ChartJS<"treemap", TreemapNode[], unknown>) => {
        const ctx = chart.ctx;
        ctx.save();

        const lightningImg = new Image();
        lightningImg.src = "/assets/main_v4/poster_images/Lightning.png";

        try {
          (chart.data.datasets[0].data as TreemapNode[]).forEach(
            (node, index) => {
              const meta = chart.getDatasetMeta(0);
              const element = meta.data[index];

              if (element && node?._data) {
                const { x = 0, y = 0 } = element;
                const itemData = node._data;

                // Draw logo
                if (itemData.logo) {
                  const img = new Image();
                  img.src = itemData.logo;
                  img.onload = () => {
                    if (chart.canvas) {
                      ctx.drawImage(img, x + 8, y + 8, 44, 44);
                    }
                  };
                }

                // Draw text
                ctx.fillStyle = "#FFF";
                ctx.font = "600 18px Outfit";
                ctx.textBaseline = "top";
                ctx.fillText(itemData.name, x + 60, y + 25);

                // Draw bonus badge (Image + "40% bonus")
                const badgeX = x + 20;
                const badgeY = y + 64;
                const badgeWidth = 98;
                const badgeHeight = 26;
                const borderRadius = 14;

                // Draw rounded rectangle
                ctx.fillStyle = "#222222";
                ctx.beginPath();
                ctx.moveTo(badgeX + borderRadius, badgeY);
                ctx.lineTo(badgeX + badgeWidth - borderRadius, badgeY);
                ctx.quadraticCurveTo(
                  badgeX + badgeWidth,
                  badgeY,
                  badgeX + badgeWidth,
                  badgeY + borderRadius
                );
                ctx.lineTo(
                  badgeX + badgeWidth,
                  badgeY + badgeHeight - borderRadius
                );
                ctx.quadraticCurveTo(
                  badgeX + badgeWidth,
                  badgeY + badgeHeight,
                  badgeX + badgeWidth - borderRadius,
                  badgeY + badgeHeight
                );
                ctx.lineTo(badgeX + borderRadius, badgeY + badgeHeight);
                ctx.quadraticCurveTo(
                  badgeX,
                  badgeY + badgeHeight,
                  badgeX,
                  badgeY + badgeHeight - borderRadius
                );
                ctx.lineTo(badgeX, badgeY + borderRadius);
                ctx.quadraticCurveTo(
                  badgeX,
                  badgeY,
                  badgeX + borderRadius,
                  badgeY
                );
                ctx.closePath();
                ctx.fill();
                ctx.strokeStyle = "#00EEFD";
                ctx.lineWidth = 2;
                ctx.stroke();

                // Draw Image (Lightning icon)
                if (lightningImg.complete) {
                  ctx.drawImage(lightningImg, badgeX + 6, badgeY + 5, 14, 14);
                } else {
                  lightningImg.onload = () => {
                    ctx.drawImage(lightningImg, badgeX + 6, badgeY + 5, 14, 14);
                  };
                }

                // Draw bonus text
                ctx.fillStyle = "#00EEFD";
                ctx.font = "700 12px Outfit";
                ctx.fillText("40%", badgeX + 24, badgeY + 7);

                ctx.fillStyle = "#FFF";
                ctx.font = "12px Outfit";
                ctx.fillText("bonus", badgeX + 52, badgeY + 7);

                // Draw other text elements
                ctx.font = "600 26px Outfit";
                ctx.fillText(itemData.percent, x + 20, y + 100);

                ctx.font = "normal 14px Outfit";
                ctx.fillText(itemData.value, x + 20, y + 136);

                // Draw change indicator
                if (itemData.chart) {
                  ctx.fillStyle =
                    parseFloat(itemData.chart) >= 0 ? "#4CAF50" : "#F44336";
                  ctx.fillText(itemData.chart, x + 80, y + 107);
                }
              }
            }
          );
        } catch (error) {
          console.error("Chart drawing error:", error);
        } finally {
          ctx.restore();
        }
      },
    },
  ],
};

export default function TreemapChart() {
  const chartRef = useRef<ChartJS<"treemap", TreemapNode[], unknown>>(null);

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "600px" }}>
      <Chart
        ref={chartRef}
        type="treemap"
        data={config.data}
        options={options}
        plugins={config.plugins}
      />
    </div>
  );
}
