import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { InputToken, OutputToken } from "./types";
import { useFetchStakeAggregatorAssets } from ".";
import { disconnect } from "process";
import { useAppDispatch } from "../../../controller/state/hooks";
import { toggleSolanaConnectWalletModal } from "../../../controller/dialog/dialog";
import { updateUserSolanaAddress } from "./helper";

interface WalletContextProps {
  pubAddress: string | null;
  setPubAddress: (pubAddress: string | null) => void;
  connectedWallet: "metamask" | "phantom" | "backpack" | "okxos" | "haha" | null;
  setConnectedWallet: (wallet: "metamask" | "phantom" | "backpack" | "okxos" | "haha" | null) => void;
}

const WalletContext = createContext<WalletContextProps | undefined>(undefined);

export const WalletProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [pubAddress, setPubAddress] = useState<string | null>(null);
  const [connectedWallet, setConnectedWallet] = useState<
    "metamask" | "phantom" | "backpack" | "okxos" | "haha" | null
  >(null);

  useEffect(() => {
    // Load the address from sessionStorage on component mount
    const storedAddress = sessionStorage.getItem("pubAddress");
    if (storedAddress) {
      setPubAddress(storedAddress);
    }
  }, []);

  useEffect(() => {
    // Save the address to sessionStorage whenever it changes
    if (pubAddress) {
      sessionStorage.setItem("pubAddress", pubAddress);
    } else {
      sessionStorage.removeItem("pubAddress"); // Clear storage if address is null
    }
  }, [pubAddress]);

  const contextValue = {
    pubAddress,
    setPubAddress,
    connectedWallet,
    setConnectedWallet,
  };

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextProps => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};

interface AggregatorContextProps {
  outputToken: OutputToken | null;
  inputToken: InputToken | null;
  handleSetOutputToken: (outputToken: OutputToken) => void;
  handleSetInputToken: (inputToken: InputToken) => void;
}

const AggregatorContext = createContext<AggregatorContextProps | undefined>(
  undefined
);

export const AggregatorProvider = ({ children }: { children: ReactNode }) => {
  const [outputToken, setOutputToken] = useState<OutputToken | null>(null);
  const [inputToken, setInputToken] = useState<InputToken | null>({
    id: 2,
    category: "Stake",
    chain: "10143",
    from_asset: "USDC",
    from_asset_logo:
      "https://static.okx.com/cdn/web3/currency/token/784-0xdba34672e30cb065b1f93e3ab55318768fd6fef66c15942c9f7cb846e2f900e7::usdc::USDC-1.png/type=default_350_0?v=1735272019523",
    from_asset_address: "0xf817257fed379853cDe0fa4F97AB987181B1E5Ea",
    from_asset_decimal: "18",
    from_network: "Monad",
    from_network_url: "https://docs.monad.xyz/img/monad_logo.png",
  });

  const handleSetOutputToken = (outputToken: OutputToken) => {
    setOutputToken(outputToken);
  };

  const handleSetInputToken = (inputToken: InputToken) => {
    setInputToken(inputToken);
  };

  return (
    <AggregatorContext.Provider
      value={{
        outputToken,
        handleSetOutputToken,
        inputToken,
        handleSetInputToken,
      }}
    >
      {children}
    </AggregatorContext.Provider>
  );
};

export const useAggregator = () => {
  const context = useContext(AggregatorContext);
  if (!context) {
    throw new Error("useAggregator must be used within an AggregatorProvider");
  }
  return context;
};

interface SolanaWalletContext {
  solanaAddress: string | undefined;
  connectPhantomWallet: () => void;
  connectBackpackWallet: () => void;
  disconnectWallet: () => void;
  isConnected: boolean;
}

const SolanaWalletContext = createContext<SolanaWalletContext | undefined>(
  undefined
);

export const SolanaWalletProvider = ({ children }: { children: ReactNode }) => {
  const [solanaAddress, setSolanaAddress] = useState<string | undefined>("");
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getPhantomProvider = () => {
    const windowWithPhantom = window as any;
    if ("phantom" in window) {
      const anyWindow: any = window;
      const provider = anyWindow.phantom.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    window.open("https://phantom.app/", "_blank");
  };

  const connectPhantomWallet = async () => {
    const phantomProvider = getPhantomProvider();
    console.log("phantommmm is connecting");
    try {
      const resp = await phantomProvider.connect();
      setSolanaAddress(resp.publicKey.toBase58());
      dispatch(toggleSolanaConnectWalletModal(false));
      setIsConnected(true);
      await updateUserSolanaAddress(resp.publicKey.toBase58());
    } catch (error) {
      console.error("Phantom connection failed", error);
    }
  };

  const getBackpackProvider = () => {
    const BackpackWindow = window as any;
    if (!BackpackWindow?.backpack?.solana) {
      // alert("Backpack Wallet not found. Install Backpack extension.");
      window.open("https://backpack.app/", "_blank");
      return;
    }
    return BackpackWindow.backpack.solana;
  };
  const connectBackpackWallet = async () => {
    const backpackProvider = getBackpackProvider();
    try {
      const { publicKey } = await backpackProvider.connect();
      setSolanaAddress(publicKey.toBase58());
      setIsConnected(true);
      dispatch(toggleSolanaConnectWalletModal(false));
      await updateUserSolanaAddress(publicKey.toBase58());
    } catch (error) {
      console.error("Backpack connection failed:", error);
    }
  };

  const disconnectWallet = async () => {
    const backpackProvider = getBackpackProvider();
    const phantomProvider = getPhantomProvider();
    if (phantomProvider.isConnected) {
      await phantomProvider.disconnect();
      setSolanaAddress("");
    }
    if (backpackProvider.isConnected) {
      await backpackProvider.disconnect();
      setSolanaAddress("");
    }
    setIsConnected(false);
  };

  useEffect(() => {
    const phantomProvider = getPhantomProvider();
    const backpackProvider = getBackpackProvider();

    setSolanaAddress(
      phantomProvider?.publicKey?.toBase58() ||
        backpackProvider?.publicKey?.toBase58()
    );
    setIsConnected(
      phantomProvider?.isConnected || backpackProvider?.isConnected
    );
  }, []);

  return (
    <SolanaWalletContext.Provider
      value={{
        solanaAddress,
        connectPhantomWallet,
        connectBackpackWallet,
        disconnectWallet,
        isConnected,
      }}
    >
      {children}
    </SolanaWalletContext.Provider>
  );
};

export const useSolanaWalletProvider = () => {
  const context = useContext(SolanaWalletContext);
  if (!context) {
    throw new Error(
      "useSolanaProvider must be used within an SolanaWalletProvider"
    );
  }
  return context;
};
