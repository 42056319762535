import React, { useEffect, useMemo, useState } from "react";
import aporiLogo from "../../assets/V4/apori.svg";
import copyicon from "../../assets/V4/copy.svg";
import HistoryTab from "./Historytab";
import UserRanking from "./UserRanking";
import HistoryTab2 from "./HistoryTab2";
import {
  getUserDetails,
  getWalletBonus,
  getWalletIcon,
  setUserPrimaryName,
  useGetAllReferral,
  useUserDetails,
} from "./hooks/helper";
import { useQuery } from "@tanstack/react-query";
import { UserDetailsResponse } from "./hooks/types";
import { useAppSelector } from "../../controller/state/hooks";
import { updateUser } from "../../controller/profile/user";
import { useAppDispatch } from "../../controller/state/hooks";
import { toast, ToastContainer } from "react-toastify";
import { useSolanaWalletProvider, useWallet } from "./hooks/content";
import {
  useAccount,
  useReadContract,
  useReadContracts,
  useWriteContract,
} from "wagmi";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { Tooltip } from "react-tooltip";

import { FaRegCircleCheck } from "react-icons/fa6";

import { GoCircle } from "react-icons/go";
import {
  lilChogstarsContract,
  towneSquareContract,
} from "../../constants/constants";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { capitalize } from "lodash";
import { useCheckUserHasMadladsNFT } from "./hooks";
import ReferredModal from "./Refermodal";
import { useAuth } from "./hooks/Authstate";

const Ranking = () => {
  const [activeTab, setActiveTab] = useState<"history" | "ranking">("ranking");

  const MyRankAndReferral = () => {
    const [username, setUsername] = useState<string | undefined>("");
    const [listbtnIsOpem, setListbtnIsOpen] = useState<boolean>(false);
    const account = useAccount();
    const dispatch = useAppDispatch();
    const { token } = useAuth();
    const queryClient = useQueryClient();
    const { data, isLoading } = useGetAllReferral();
    const [showReferalList, setShowReferalList] = useState(false);
    const [referrals, setReferrals] = useState<
      { created_at: string; user: { wallet_address: string } }[]
    >([]);
    const { writeContract, writeContractAsync } = useWriteContract();
    const { solanaAddress } = useSolanaWalletProvider();

    const referral = data?.referrals || [];

    console.log("the test", account.connector?.name);
    const userDetails = useUserDetails(
      account.address,
      account.connector?.name.toLowerCase()
    );


    useEffect(() => {
      if (account?.isConnected && token) {
        queryClient.invalidateQueries({ 
          queryKey: ['userReferral'],
          refetchType: 'active'
        });
      }
    }, [account?.isConnected, token, queryClient]);


    const hasMadLadsNFT = useCheckUserHasMadladsNFT(solanaAddress);

    const copyToClipboard = () => {
      if (userDetails.data?.user.referral_code) {
        navigator.clipboard
          .writeText(userDetails.data?.user.referral_code)
          .then(() => {
            toast.success("Referral code copied to clipboard!", {
              position: "top-right",
              autoClose: 3000,
              theme: "dark",
            });
          })
          .catch((err) => {
            console.error("Failed to copy text:", err);
            toast.error("Failed to copy referral code.", {
              position: "top-right",
              autoClose: 3000,
              theme: "dark",
            });
          });
      } else {
        console.warn("No referral code available to copy.");
      }
    };

    const {
      data: userPrimaryNFT,
      isSuccess,
    }: { data: [string, string] | undefined; isSuccess: boolean } =
      useReadContract({
        ...towneSquareContract,
        functionName: "userPrimaryNFT",
        args: [account.address],
      });

    const result = useReadContract({
      ...towneSquareContract,
      functionName: "balanceOf",
      args: [account.address],
    });

    const { data: userTokenID }: { data: number[] | undefined } =
      useReadContract({
        ...towneSquareContract,
        functionName: "tokenIdbyOwner",
        args: [account.address],
      });

    const { data: userNFTName } = useReadContracts({
      contracts: userTokenID?.map((tokenId) => ({
        ...towneSquareContract,
        functionName: "_userNames",
        args: [tokenId],
      })),
    });

    useEffect(() => {
      const storedUsername = localStorage.getItem("username") || "-";
      setUsername(storedUsername);
    }, []);

    // useMemo(() => {
    //   if (userDetails.data) {
    //     setReferralCode(
    //       !userDetails.data.user.referral_code
    //         ? null
    //         : userDetails.data.user.referral_code
    //     );
    //     setRankvalue(
    //       userDetails.data.point.rank == 0 ? null : userDetails.data.point.rank
    //     );
    //     setPointValue(
    //       userDetails.data.point.points !== null &&
    //         userDetails.data.point.points >= 0
    //         ? userDetails.data.point.points
    //         : null
    //     );
    //   }
    // }, [userDetails.data]);

    const handleSetPrimaryNFT = async (tokenId: number) => {
      writeContract(
        {
          ...towneSquareContract,
          functionName: "setPrimaryNFT",
          args: [tokenId],
        },
        {
          onSuccess: async () => {},
          onError(error: any, variables, context) {
            console.log(error.message);
          },
        }
      );
    };
    // useEffect(() => {
    //   (async () => {
    //     const userDetails = await getUserDetails();
    //     //const userNames = await getUserNFTNames();
    //     // setUserNFTNames(userNames);
    //     //console.log(userNames)
    //     dispatch(updateUser(userDetails));
    //   })();
    // }, [account.address]);

    const towneSquareBalance = useReadContract({
      ...towneSquareContract,
      functionName: "balanceOf",
      args: [account.address],
    });

    const chogStarBalance = useReadContract({
      ...lilChogstarsContract,
      functionName: "balanceOf",
      args: [account.address],
    });

    useEffect(() => {
      if (userPrimaryNFT) {
        setUsername(userPrimaryNFT[1]);
      }
    }, [userPrimaryNFT, account.address]);

    return (
      <div className="flex flex-col items-center justify-center p-4 mt-4 md:mb-4 rounded-lg">
        <div className="md:hidden w-full">
          <span className="block text-[23px] font-semibold mb-4  ml-4 text-start">
            Ranking
          </span>
        </div>
        <ToastContainer />
        <div className="flex items-center md:items-start md:flex-row md:justify-center gap-[41px] md:mb-4 flex-col">
          <div className="flex flex-col items-center gap-4">
            <span className=" flex flex-row text-white  gap-2 font-semibold text-[23px]">
              My <img src="/assets/main_v4/tlogo.svg" /> Profile
            </span>

            {account.isConnected ? (
              <>
                {result.isSuccess && Number(result.data) == 0 && (
                  <div className="flex flex-col items-center justify-center gap-3 ">
                    <span className="text-white border-[2px] b font-normal drop-shadow-lg shadow-sm border-[#9264F8] bg-[#6646AE] text-3xl h-16 gap-1 bg-opacity-30 items-center text-center rounded-lg py-3 px-14 flex justify-center">
                      Mint Profile NFT
                    </span>
                    <p className="text-[14px] w-[264px] text-center">
                      {" "}
                      Enjoy extra reward boost with TowneSquare Profile NFT!
                    </p>
                  </div>
                )}
                {result.isSuccess && Number(result.data) == 1 && (
                  <span className="text-white font-normal text-3xl h-16 gap-1 border items-center text-center border-[#666666] rounded-lg py-3 px-14 flex justify-center bg-[#222222]">
                    <img
                      src="/assets/main_v4/dbadge.svg"
                      className="object-contain"
                    />
                    <span>{`@${username ? username : "-"}`}</span>
                  </span>
                )}
                {result.isSuccess && Number(result.data) > 1 && (
                  <Listbox value={username}>
                    <ListboxButton
                      onClick={() => setListbtnIsOpen(!listbtnIsOpem)}
                      className="text-white font-normal text-3xl h-16 gap-1 border items-center text-center border-[#666666] rounded-lg py-3 px-14 flex justify-center bg-[#222222]"
                    >
                      <img
                        src="/assets/main_v4/dbadge.svg"
                        className="object-contain"
                      />
                      <span>{`@${username ? username : "-"}`}</span>
                      {listbtnIsOpem ? (
                        <IoMdArrowDropup />
                      ) : (
                        <IoMdArrowDropdown />
                      )}
                    </ListboxButton>
                    <ListboxOptions
                      className="w-[var(--button-width)]  z-50 border-[2px] border-[#B882FF] rounded-[12px] flex flex-col [--anchor-gap:5px] gap-[16px] px-[16px] py-[24px] bg-[#222222]"
                      anchor="bottom"
                    >
                      {userNFTName &&
                        userNFTName.map((nftName, index) => {
                          return (
                            isSuccess && (
                              <ListboxOption
                                onClick={() => {}}
                                className={`group flex gap-1`}
                                value={nftName.result}
                                key={index}
                              >
                                <FaRegCircleCheck
                                  className="rounded-full group-data-[selected]:flex hidden bg-[#B882FF]"
                                  fill="#FFF"
                                  size={24}
                                />
                                <GoCircle
                                  className="group-data-[selected]:hidden cursor-custom flex"
                                  size={24}
                                  onClick={() => {
                                    if (userTokenID) {
                                      handleSetPrimaryNFT(
                                        Number(userTokenID[index])
                                      );
                                    }
                                  }}
                                />
                                <p
                                  className={`group-data-[selected]:text-[#FFFFFF] font-Inter group-data-[selected]:font-semibold text-[#B9B9B9] leading-[21px] text-[16px]`}
                                >
                                  {" "}
                                  {`@${nftName.result}`}
                                </p>
                              </ListboxOption>
                            )
                          );
                        })}
                    </ListboxOptions>
                  </Listbox>
                )}
              </>
            ) : (
              <span className="text-white font-normal text-3xl h-16 gap-1 border items-center text-center border-[#666666] rounded-lg py-3 px-14 flex justify-center bg-[#222222]">
                <img
                  src="/assets/main_v4/dbadge.svg"
                  className="object-contain"
                />
                <span>{`@-`}</span>
              </span>
            )}
          </div>

          {/* Boost */}
          <div className="flex flex-col items-center block gap-2 md:hidden">
            <span className="text-white font-semibold text-[23px] ">
              Rank Boost
            </span>
            {account.isConnected ? (
              <div className="flex items-center justify-center gap-3 border border-[#666666] h-[64px] w-[310px] rounded-lg px-[32px] bg-[#222222]">
                {Number(towneSquareBalance.data) >= 1 && (
                  <>
                    <Tooltip
                      anchorSelect=".nft-collection-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="bottom"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]"> +30% bonus rank </span>
                      by holding{" "}
                      <span className="font-semibold">
                        NAD Profile NFT - OG edition{" "}
                      </span>
                    </Tooltip>

                    <img
                      src="/assets/main_v4/Nad_Logo.svg"
                      className="w-[32px] h-[32px] cursor-custom nft-collection-tooltip "
                    />
                  </>
                )}
                {Number(chogStarBalance.data) >= 1 && (
                  <>
                    <Tooltip
                      anchorSelect=".chogStar-tooltip"
                      className="text-[14px] w-[167px] bg-black border text-white border-[#404040]"
                      place="bottom"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]"> +30% bonus rank </span>
                      by holding{" "}
                      <span className="font-semibold">Lil Chogstars </span>
                    </Tooltip>

                    <img
                      src="/assets/main_v4/chogstar.svg"
                      className="w-[32px] cursor-custom chogStar-tooltip h-[32px]"
                    />
                  </>
                )}
                {hasMadLadsNFT.data && (
                  <>
                    <Tooltip
                      anchorSelect=".chogStar-tooltip"
                      className="text-[14px] w-[167px] bg-black border text-white border-[#404040]"
                      place="bottom"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]"> +10% bonus rank </span>
                      by holding <span className="font-semibold">MadLads </span>
                    </Tooltip>

                    <img
                      src="/assets/main_v4/madlads.svg"
                      className="w-[32px] cursor-custom chogStar-tooltip h-[32px]"
                    />
                  </>
                )}
                {account.connector?.name.toLowerCase() !== "metamask" && (
                  <>
                    <Tooltip
                      anchorSelect=".wallet-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="top"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      {`Connected with ${account.connector?.name}: You are earning `}
                      <span className="text-[#00EEFD]">
                        {account.connector?.name &&
                          `+${getWalletBonus(account.connector?.name)}%`}
                      </span>
                    </Tooltip>

                    <img
                      src={
                        account.connector?.name &&
                        getWalletIcon(account.connector?.name)
                      }
                      className="w-[32px] cursor-custom wallet-tooltip h-[32px]"
                    />
                  </>
                )}
                {!Number(chogStarBalance.data) &&
                  !Number(towneSquareBalance.data) &&
                  account.connector?.name.toLowerCase() == "metamask" &&
                  hasMadLadsNFT.data == false && (
                    <span className="text-[16px] font-semibold text-white">
                      {" "}
                      No active boostssss
                    </span>
                  )}
              </div>
            ) : (
              <div className="flex items-center justify-center gap-3 border border-[#666666] h-[64px] rounded-lg px-[32px] bg-[#222222]">
                <span className="text-3xl text-white">-</span>
              </div>
            )}
          </div>

          <div className="flex flex-row gap-4 md:gap-10">
            {/* My Rank Section */}
            <div className="flex flex-col items-center gap-4">
              <span className="text-white font-semibold text-[23px] px-4">
                My Rank
              </span>
              <span className="text-white font-normal text-3xl h-16 md:w-[136px]   border border-[#666666] rounded-lg py-3 md:px-14  px-8 flex items-center justify-center bg-[#222222]">
                {account.isConnected && userDetails.data
                  ? userDetails.data.point.rank
                  : "-"}
              </span>
            </div>

            {/* Boost */}
            <div className="flex-col items-center hidden gap-4 md:flex">
              <span className="text-white font-semibold text-[23px] ">
                Rank Boost
              </span>
              {account.isConnected ? (
                <div className="flex items-center justify-center gap-3 border border-[#666666] h-[64px] rounded-lg px-[32px] bg-[#222222]">
                  {Number(towneSquareBalance.data) >= 1 && (
                    <>
                      <Tooltip
                        anchorSelect=".nft-collection-tooltip"
                        className="text-[14px] bg-black border text-white border-[#404040]"
                        place="bottom"
                        classNameArrow="border-b border-r border-[#404040]"
                      >
                        You are earning
                        <span className="text-[#00EEFD]">
                          {" "}
                          +30% bonus rank{" "}
                        </span>
                        by holding{" "}
                        <span className="font-semibold">
                          NAD Profile NFT - OG edition{" "}
                        </span>
                      </Tooltip>

                      <img
                        src="/assets/main_v4/Nad_Logo.svg"
                        className="w-[32px] h-[32px] cursor-custom nft-collection-tooltip "
                      />
                    </>
                  )}
                  {Number(chogStarBalance.data) >= 1 && (
                    <>
                      <Tooltip
                        anchorSelect=".chogStar-tooltip"
                        className="text-[14px] w-[167px] bg-black border text-white border-[#404040]"
                        place="bottom"
                        classNameArrow="border-b border-r border-[#404040]"
                      >
                        You are earning
                        <span className="text-[#00EEFD]">
                          {" "}
                          +30% bonus rank{" "}
                        </span>
                        by holding{" "}
                        <span className="font-semibold">Lil Chogstars </span>
                      </Tooltip>

                      <img
                        src="/assets/main_v4/chogstar.svg"
                        className="w-[32px] cursor-custom chogStar-tooltip h-[32px]"
                      />
                    </>
                  )}
                  {hasMadLadsNFT.data && (
                    <>
                      <Tooltip
                        anchorSelect=".chogStar-tooltip"
                        className="text-[14px] w-[167px] bg-black border text-white border-[#404040]"
                        place="bottom"
                        classNameArrow="border-b border-r border-[#404040]"
                      >
                        You are earning
                        <span className="text-[#00EEFD]">
                          {" "}
                          +10% bonus rank{" "}
                        </span>
                        by holding{" "}
                        <span className="font-semibold">MadLads </span>
                      </Tooltip>

                      <img
                        src="/assets/main_v4/madlads.svg"
                        className="w-[32px] cursor-custom chogStar-tooltip h-[32px]"
                      />
                    </>
                  )}
                  {account.connector?.name.toLowerCase() !== "metamask" && (
                    <>
                      <Tooltip
                        anchorSelect=".wallet-tooltip"
                        className="text-[14px] bg-black border text-white border-[#404040]"
                        place="top"
                        classNameArrow="border-b border-r border-[#404040]"
                      >
                        {`Connected with ${account.connector?.name}: You are earning `}
                        <span className="text-[#00EEFD]">
                          {account.connector?.name &&
                            `+${getWalletBonus(account.connector?.name)}%`}
                        </span>{" "}
                        rank boost
                      </Tooltip>

                      <img
                        src={
                          account.connector?.name &&
                          getWalletIcon(account.connector?.name)
                        }
                        className="w-[32px] cursor-custom wallet-tooltip h-[32px]"
                      />
                    </>
                  )}
                  {!Number(chogStarBalance.data) &&
                    !Number(towneSquareBalance.data) &&
                    account.connector?.name.toLowerCase() == "metamask" &&
                    !hasMadLadsNFT.data && (
                      <span className="text-[16px] font-semibold text-white">
                        {" "}
                        No active boosts
                      </span>
                    )}
                </div>
              ) : (
                <div className="flex items-center justify-center gap-3 border border-[#666666] h-[64px] rounded-lg px-[32px] bg-[#222222]">
                  <span className="text-3xl text-white">-</span>
                </div>
              )}
            </div>

            {/* Referral Code Section */}
            <div className="flex flex-col items-center gap-4">
              <span className="text-white font-semibold text-[23px] ">
                My Referral Code
              </span>
              <div className="flex items-center justify-center gap-4 border border-[#666666] h-[64px]  md:w-[280px] w-[225px] rounded-lg py-3 md:px-14  px-6 bg-[#222222]">
                <span className="text-3xl text-white">
                  {" "}
                  {account.isConnected && userDetails.data
                    ? userDetails.data.user.referral_code
                    : "-"}{" "}
                </span>
                {account.isConnected && (
                  <img
                    src={copyicon}
                    alt="Copy"
                    className="cursor-pointer"
                    onClick={copyToClipboard}
                  />
                )}

                {referral.length > 0 && (
                  <div
                    onClick={() => setShowReferalList(true)}
                    className="bg-[#CC96FF]/20 rounded-[40px] flex justify-center items-center cursor-pointer"
                  >
                    <p className="text-[#CC96FF] px-4 py-[6px] text-[16px] cursor-pointer whitespace-nowrap">
                      {referral.length}  Joined
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showReferalList && (
          <ReferredModal
            referrals={referral}
            onClose={() => setShowReferalList(false)}
          />
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col items-center text-white bg-black mb-6">
      <MyRankAndReferral />

      <div className="flex flex-col items-center md:w-full">
        {/* Button Group */}
        <div className="flex space-x-4">
          <button
            className={`p-2 w-[168px] text-base font-normal transition ${
              activeTab === "history"
                ? "text-[#B882FF] border-b-2 border-spacing-2 border-[#B882FF]"
                : "text-[#fff]"
            }`}
            onClick={() => setActiveTab("history")}
          >
            My history
          </button>
          <button
            className={`p-2 w-[168px] text-base font-normal transition ${
              activeTab === "ranking"
                ? "text-[#B882FF] border-b-2 border-spacing-2 border-[#B882FF]"
                : "text-[#fff]"
            }`}
            onClick={() => setActiveTab("ranking")}
          >
            Rankings
          </button>
        </div>
        {/* Divider */}
        <div className="w-full h-[1px] bg-[#222222] border border-[#222222]"></div>
      </div>
      {activeTab === "ranking" ? (
        <div className="flex flex-col justify-center space-y-4">
          <UserRanking />
        </div>
      ) : (
        <div className="flex justify-center gap-4 pt-10 md:px-12 ">
          <div className="flex flex-wrap gap-6 pt-6 pb-8 md:px-8 rounded-2xl ">
            <HistoryTab />
          </div>
        </div>
      )}
    </div>
  );
};

export default Ranking;