import { Metadata } from "./../../../../node_modules/aws-sdk/clients/chimesdkidentity.d";
import axios, { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ApiResponse,
  ArtistCollection,
  ArtistResponse,
  BridgeTxnType,
  CheckUsernameResponse,
  checkUserResponse,
  GetNewcommerResponse,
  GetPrimaryNameResponse,
  GetRefferalResponse,
  HistoryResponse,
  LendTxnType,
  OnboardingApp,
  Protocol,
  RankResponse,
  signInResponse,
  signUpResponse,
  StakeAggregatorAssetsResponse,
  StakeTxnType,
  SwapTxnType,
  Transaction,
  TransactionResponse,
  UserDetailsResponse,
  walletType,
} from "./types";
import { BACKEND_URL, PINATA_GATEWAY } from "./env";
import Web3 from "web3";
import { ethers } from "ethers";
import { s3 } from "../../../constants/config";
import * as KuruSdk from "@kuru-labs/kuru-sdk";
import { useEffect, useState } from "react";
import { useAuth } from "./Authstate";

export const getAllProtocol = async (): Promise<Protocol[]> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get<ApiResponse<Protocol[]>>(
      `${BACKEND_URL}api/projects`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};


export const useGetAllReferral = () => {
  const { token } = useAuth();

  return useQuery({
    queryKey: ["userReferral", token],
    queryFn: async () => {
      if (!token) return null;
      
       
      const res = await axios.get<{ 
        statusCode: number;
        data: GetRefferalResponse 
      }>(`${BACKEND_URL}api/users/me`, {
        headers: { Authorization: `Bearer ${token}` }
      });

       
      return res.data.data;  
    },
    enabled: !!token,
    staleTime: 0,
    refetchOnMount: 'always',
    retry: 2
  });
};

// export async function getNewComer() {
//   try {
//     const response = await fetch("https://backend.townesquare.xyz/rank/new");
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const data = await response.json();
//     console.log("Response data:", data);
//     return data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// }

export async function getNewusers(): Promise<GetNewcommerResponse | null> {
  try {
    const response = await fetch(
      "https://action-link-stage.townesquare.xyz/api/users"
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("newcommer Response data:", data);
    return data as GetNewcommerResponse;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

export const useGetalllNewcomers = () => {
  const getnewcomerdata = async () => {
    const response = await getNewusers();
    return response;
  };
  return useQuery({
    queryKey: ["getAllNecomers"],
    queryFn: () => getnewcomerdata,
  });
};

export const useGetArtistCollection = () => {
  return useQuery({
    queryKey: ["getArtistCollection"],
    queryFn: async (): Promise<ArtistCollection[]> => {
      try {
        const response = await axios.get<ArtistResponse>(
          "https://action-link-stage.townesquare.xyz/api/artist-spotlight"
        );
        console.log("THE ARTIST", response);
        return response.data.data;
      } catch (error) {
        console.error("Error fetching collection:", error);
        return [];
      }
    },
  });
};

// export async function getRanking(): Promise<RankResponse> {
//   try {
//     const response = await fetch(
//       `https://action-link-stage.townesquare.xyz/api/users/ranks`
//     ).then((res) => res.json());
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const data = await response.json();
//     console.log("Response rank data:", data);
//     return data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     throw error;
//   }
// }

export async function getRanking(): Promise<RankResponse> {
  try {
    const res = await axios.get(
      `https://action-link-stage.townesquare.xyz/api/users/ranks`
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
}

export const getOnboardingAppsForProject = async (project_id: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get<ApiResponse<OnboardingApp[]>>(
      `${BACKEND_URL}api/onboardings?project_id=${project_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};

export const getOnboardingAppsForProjects = async () => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get<ApiResponse<OnboardingApp[]>>(
      `${BACKEND_URL}api/onboardings/get-all-onboarding`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      return res.data.data;
    } else return [];
  } catch (error) {
    return [];
  }
};

export const getPhantomProvider = (): any | undefined => {
  if ("phantom" in window) {
    console.log("Phantom wallet detected");
    const anyWindow: any = window;
    const provider = anyWindow.phantom;

    if (provider) {
      console.log(provider);
      return provider;
    }
  } else alert("Please install Phantom wallet");

  window.open("https://phantom.app/", "_blank");
};

export const requestPhantomWallet = async () => {
  const provider = getPhantomProvider();
  if (!provider) return;
  try {
    const accounts = await provider?.ethereum.request({
      method: "eth_requestAccounts",
    });
  } catch (err) {
    console.log("Failed to connect wallet", err);
  }
};

export const getUsdcBalance = async (address: string) => {
  const web3_instance = new Web3(
    "https://sepolia.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d"
  );
  const abiErc20 = [
    {
      constant: true,
      inputs: [{ name: "who", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ];
  const usdcContract = "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238";
  const usdtContractInstance = new web3_instance.eth.Contract(
    abiErc20,
    usdcContract
  );

  const balance = await usdtContractInstance.methods.balanceOf(address).call();
  return Number(balance) / 10 ** 6;
};

export const chainRPCS = [
  {
    chainHex: "0x1",
    chainId: 1,
    chainName: "Mainnet",
    rpcUrls: ["https://mainnet.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d"],
    blockExplorerUrls: ["https://etherscan.io/"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  {
    chainHex: "0x2105",
    chainId: 8453,
    chainName: "Base",
    rpcUrls: [
      "https://base-mainnet.infura.io/v3/60b469b5df9544f99339f0a5d2bdb70d",
    ],
    blockExplorerUrls: ["https://basescan.org/"],
    nativeCurrency: {
      name: "BASE",
      symbol: "BASE",
      decimals: 18,
    },
  },
];

export const switchMetamaskNetwork = async (chainId: number) => {
  if (!window.ethereum) return alert("MetaMask is not installed");

  const chainDetails = chainRPCS.find((chain) => chain.chainId === chainId);
  if (!chainDetails) {
    return alert("Chain details not found");
  }

  try {
    if (window.ethereum?.request) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainDetails.chainHex }],
      });
      // Listen for the `chainChanged` event
    } else {
      throw new Error("Ethereum request method not available");
    }
  } catch (error: any) {
    if (error.code === 4902) {
      // Chain has not been added
      try {
        if (window.ethereum?.request) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chainDetails.chainHex,
                chainName: chainDetails.chainName,
                rpcUrls: chainDetails.rpcUrls,
                nativeCurrency: chainDetails.nativeCurrency,
                blockExplorerUrls: chainDetails.blockExplorerUrls,
              },
            ],
          });
        } else {
          throw new Error("Ethereum request method not available");
        }
      } catch (addError) {
        console.error("Failed to add network:", addError);
      }
    } else {
      console.error("Failed to switch network:", error);
    }
  }
};

export const switchPhantomNetwork = async (chainId: number) => {
  const provider = getPhantomProvider();
  if (!provider) return;
  const chainDetails = chainRPCS.find((chain) => chain.chainId === chainId);
  if (!chainDetails) {
    return alert("Chain details not found");
  }
  await requestPhantomWallet();

  try {
    await provider.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainDetails.chainHex }],
    });
    console.log(`Switched to network ${chainId}`);
  } catch (switchError: any) {
    if (switchError?.code === 4902) {
      console.log("Network has not been added");
      // Chain has not been added
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chainDetails.chainHex,
              chainName: chainDetails.chainName,
              rpcUrls: chainDetails.rpcUrls,
              nativeCurrency: chainDetails.nativeCurrency,
              blockExplorerUrls: chainDetails.blockExplorerUrls,
            },
          ],
        });
        console.log(`Added and switched to network ${chainId}`);
      } catch (addError) {
        console.error("Failed to add network", addError);
      }
    } else {
      console.error("Failed to switch network", switchError);
    }
  }
};

export async function getCurrentPhantomChainId() {
  const provider = getPhantomProvider();
  const network = await provider.ethereum.chainId;
  const networkToNumber = parseInt(network, 16);

  console.log("===== phantom chain id =====");
  console.log(networkToNumber);
  return networkToNumber;
}

export const useGetAllProtocols = () => {
  const getProtocols = async () => {
    const response = await getAllProtocol();
    return response;
  };

  return useQuery({
    queryKey: ["getAllProtocols"],
    queryFn: () => getProtocols(),
  });
};

export const useGetAllOnboardingApps = (projectId: string) => {
  const getOnboardingApps = async () => {
    const response = await getOnboardingAppsForProject(projectId);
    return response;
  };
  return useQuery({
    queryKey: ["getOnboardingApps", projectId],
    queryFn: () => getOnboardingApps(),
  });
};

export const useGetAllOnboardingLinks = () => {
  const getOnboardingApps = async () => {
    const response = await getOnboardingAppsForProjects();
    return response;
  };
  return useQuery({
    queryKey: ["getOnboardingApps"],
    queryFn: () => getOnboardingApps(),
  });
};

export const checkUserExist = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: walletType;
}): Promise<checkUserResponse> => {
  try {
    const response = await axios.post(
      `https://action-link-stage.townesquare.xyz/api/users/check-user-exist`,
      {
        wallet_type: walletType.toLowerCase(),
        wallet_address: accountAddress,
      }
    );
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error: unknown) {
    const axiosError = error as AxiosError;

    console.error(axiosError.response?.data, "check-record");

    return {
      statusCode: axiosError.response?.status || 500,
      data: {
        id: "",
        is_active: false,
        referral_code: "",
        wallet_address: "",
        wallet_type: "",
        created_at: "",
        updated_at: "",
      },
    };
  }
};

export const Appplyreferral = async ({
  referralcode,
}: {
  referralcode: string;
}) => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    console.error("No token available");
    return { success: false, message: "User is not authenticated" };
  }

  try {
    const response = await axios.post(
      `https://action-link-stage.townesquare.xyz/api/users/referrals`,
      { referral_code: referralcode },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log("Referral response", response.data);
    return { success: true, message: "Referral code applied successfully" };
  } catch (error: any) {
    console.error("Error applying referral code", error);
    const errorMessage =
      error.response?.data?.message || "An unexpected error occurred";
    return { success: false, message: errorMessage };
  }
};

export const signInWithWallet = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: string;
}): Promise<signInResponse> => {
  try {
    const response = await axios.post(
      `https://action-link-stage.townesquare.xyz/api/users/sign-in`,
      {
        wallet_type: walletType.toLowerCase(),
        wallet_address: accountAddress,
      },

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log("sign in response", response);
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error) {
    return {
      statusCode: 400,
      data: {
        token: "",
      },
      error: error,
    };
  }
};

export const signUpWithWallet = async ({
  accountAddress,
  walletType,
}: {
  accountAddress: string;
  walletType: walletType;
}): Promise<signUpResponse> => {
  try {
    const response = await axios.post(
      `https://action-link-stage.townesquare.xyz/api/users/sign-up`,
      {
        wallet_type: walletType.toLowerCase().trim(),
        wallet_address: accountAddress,
      }
    );
    console.log("signUpWithWallet response:", response);
    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
    };
  } catch (error) {
    console.error(error, "signInWithWallet");
    return {
      statusCode: 400,
      data: {
        token: "",
      },
      error: error,
    };
  }
};

export const getUserDetails = async (
  userAddress?: string,
  walletType?: string
): Promise<UserDetailsResponse | null> => {
  console.log("not using this...........");
  try {
    const response = await axios.get(
      `${BACKEND_URL}api/users/${userAddress}/find-user/${walletType}`,
      {}
    );
    const { data } = response;
    if (data?.statusCode === 200 && data?.data) {
      console.log("User Details:", data.data);
      return data as UserDetailsResponse;
    } else {
      console.error("Unexpected response structure:", data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

export const useUserDetails = (userAddress?: string, walletType?: string) => {
  const normalizedWalletType = walletType?.toLowerCase().includes("okx")
    ? "okxos"
    : walletType?.toLowerCase().includes("haha")
    ? "haha"
    : walletType?.toLowerCase().trim();

  const normalizedUserAddress =
    normalizedWalletType === "okxos" || normalizedWalletType === "haha"
      ? userAddress?.toLowerCase().trim()
      : userAddress?.trim();

  return useQuery({
    queryKey: [
      "getFetchUserDetails",
      normalizedUserAddress,
      normalizedWalletType,
    ],
    queryFn: async () => {
      if (!normalizedUserAddress || !normalizedWalletType) return null;
      try {
        const response = await axios.get(
          `${BACKEND_URL}api/users/${normalizedUserAddress}/find-user/${normalizedWalletType}`,
          {}
        );
        return response.data.data;
      } catch (error) {
        console.error("Error fetching user details:", error);
        throw new Error("Failed to fetch user details");
      }
    },
    staleTime: 86400000,
    enabled: !!normalizedUserAddress && !!normalizedWalletType,
  });
};

// export const useUserDetails = (userAddress?: string, walletType?: string) => {
//   const getFetchUserDetails = async () => {
//     if (!userAddress || !walletType) {
//       return;
//     }
//     const response = await getUserDetails(userAddress, walletType);
//     return response?.data;
//   };

//   return useQuery({
//     queryKey: ["getFetchUserDetails", userAddress],
//     queryFn: () => getFetchUserDetails(),
//     staleTime: 86400000,
//     refetchOnWindowFocus: false,
//   });
// };

export const getAllHistory = async (
  userAddress: string
): Promise<HistoryResponse[]> => {
  const token = localStorage.getItem("token");

  // console.log("add and token", token, userAddress);

  if (!token || !userAddress) {
    console.error("Missing token or user address");
    return [];
  }
  try {
    const res = await axios.get<ApiResponse<HistoryResponse[]>>(
      `${BACKEND_URL}api/transactions/history?user_address=${userAddress}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.data.statusCode === 200 && res.data.data) {
      console.log("======History Response", res.data);
      return res.data.data;
    } else {
      console.warn("Unexpected response:", res.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching history:", error);
    return [];
  }
};

// Function to get the wallet icon based on walletType
export const getWalletIcon = (walletType: string) => {
  switch (walletType.toLowerCase()) {
    case "metamask":
      return "/assets/main_v4/metamask.svg";
    case "phantom":
      return "/assets/main_v4/phantom.svg";
    case "backpack":
      return "/assets/main_v4/backpack.svg";
    case "haha wallet":
      return "/assets/main_v4/HahaIcon.svg";
    case "okx wallet":
      return "/assets/main_v4/Okxicon.svg";
    default:
      return "/assets/main_v4/default-wallet.svg";
  }
};

export const getWalletBonus = (walletType: string): number => {
  console.log("walletType", walletType.toLowerCase());
  switch (walletType.toLowerCase()) {
    case "metamask":
      return 0;
    case "phantom":
      return 40;
    case "backpack":
      return 40;
    case "okx wallet":
      return 40;
    case "haha wallet":
      return 40;
    default:
      return 0;
  }
};

export const createNFTProfile = async (username: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = "/assets/profile_nft.svg";
    new FontFace("outfit", "url(/fonts/Outfit-VariableFont_wght.ttf)");

    img.onload = async () => {
      canvas.width = 412;
      canvas.height = 413;

      if (ctx) {
        ctx.drawImage(img, 0, 0, 413, 413);
        ctx.font = " 21px outfit";
        ctx.fillStyle = "white";
        const textWidth = ctx.measureText(username).width;
        const x = canvas.width / 2 - textWidth / 2;
        ctx.fillText(`@${username}`, x, 203);
      }

      try {
        const imageData = canvas.toDataURL("image/png", 1);
        const blob = await fetch(imageData).then((res) => res.blob());
        const file = new File([new Blob([blob])], `@${username}`);

        const params = {
          Bucket: "townesquare-nad-nft",
          Key: file.name,
          Body: file,
          ACL: "public-read", // Change according to your needs
          ContentType: "image/png",
          Metadata: {
            ipfs: "true",
          },
        };

        const upload = await s3.upload(params).promise();
        console.log(upload.Location, "image url", upload.Key);

        const JSONparams = {
          Bucket: "townesquare-nad-nft",
          Key: `${file.name}.json`,
          Body: JSON.stringify(
            {
              name: `${username}`,
              description: "Nad Profile NFT - OG edition",
              image: upload.Location,
            },
            null,
            2
          ),
          ACL: "public-read", // Change according to your needs
          ContentType: "application/json",
          Metadata: {
            ipfs: "true",
          },
        };

        const JSONupload = await s3.upload(JSONparams).promise();
        console.log(JSONupload.Location, "json url", JSONupload);
        resolve(JSONupload.Location);
      } catch (error) {
        console.error("Error creating NFT profile:", error);
        reject(new Error("❌ Something went wrong!"));
      }
    };

    img.onerror = function () {
      // Handle image load error
      reject("Error loading the image.");
    };
  });
};

export const getStakeAggregatorAssets =
  async (): Promise<StakeAggregatorAssetsResponse> => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(`${BACKEND_URL}api/stake-aggregator/assets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Return the actual error message from the server
        throw error.response?.data || error.message;
      } else {
        // Handle unexpected errors
        throw error;
      }
    }
  };

export const getStakeAggregatorTxn = async ({
  fromAsset,
  toAsset,
  protocolId,
  sourceChain,
  destinationChain,
  sourceChainId,
  destinationChainId,
  userEvmAddress,
  amount,
  getQuote,
}: {
  fromAsset: string;
  toAsset: string;
  protocolId: number;
  sourceChainId: string;
  destinationChainId: string;
  sourceChain: string;
  destinationChain: string;
  userEvmAddress: string;
  amount: number;
  getQuote: boolean;
}): Promise<TransactionResponse> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${BACKEND_URL}api/stake-aggregator/get-txn`,
      {
        fromAsset,
        toAsset,
        protocol_Id: protocolId,
        source_chain_id: sourceChainId,
        destination_chain_id: destinationChainId,
        source_chain: sourceChain,
        dest_chain: destinationChain,
        userEvmAddress,
        amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          getQuote,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const getUserTransactions = async (user_address?: string) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `https://action-link-stage.townesquare.xyz/api/transactions?user_address=${user_address}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.data as Transaction[];
  } catch (e) {
    return null;
  }
};

export const getPendingTransaction = async (
  txn_id: string,
  user_address?: string
) => {
  const token = localStorage.getItem("token");
  try {
    const txns = await getUserTransactions(user_address);
    if (!txns) {
      return null;
    }
    if (txns.length === 0) {
      return null;
    }
    const pendingTxn = txns.find(
      (txn) =>
        txn.status === "failure" &&
        txn.failure_point !== null &&
        txn.onboarding_id === 0x00000 &&
        txn.id === txn_id
    );
    if (pendingTxn) {
      return pendingTxn;
    } else return null;
  } catch (e) {
    return null;
  }
};

export const updateTransActivity = async (
  txn_id: string,
  status: "success" | "failure",
  failure_point?: number
) => {
  const token = localStorage.getItem("token");
  try {
    const payload: any = { status };
    if (status === "failure" && failure_point !== undefined) {
      // Convert failure_point to a string
      payload.failure_point = String(failure_point);
    }
    console.log("Payload to be sent:", payload);
    const response = await axios.put(
      `https://action-link-stage.townesquare.xyz/api/transactions/${txn_id}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Transaction update response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating transaction activity:", error);
    throw error;
  }
};

export enum TxnType {
  APPROVAL = "approval",
  SWAP = "swap",
  BRIDGE = "bridge",
  STAKE = "stake",
  LEND = "lend",
}

export function isBridgeTxnType(value: unknown): value is BridgeTxnType {
  return (
    typeof value === "object" &&
    value !== null &&
    "chainId" in value &&
    "quote" in value &&
    (value as BridgeTxnType).type === TxnType.BRIDGE
  );
}

export function isSwapTxnType(value: unknown): value is SwapTxnType {
  return (
    typeof value === "object" &&
    value !== null &&
    "chainId" in value &&
    "quote" in value &&
    (value as SwapTxnType).type === TxnType.SWAP
  );
}

export function isStakeTxnType(value: unknown): value is StakeTxnType {
  return (
    typeof value === "object" && (value as StakeTxnType).type === TxnType.STAKE
  );
}

export function isLendTxnType(value: unknown): value is LendTxnType {
  return (
    typeof value === "object" && (value as LendTxnType).type === TxnType.LEND
  );
}

export const checkUsername = async (
  username: string
): Promise<CheckUsernameResponse> => {
  try {
    const res = await axios.get(
      `${BACKEND_URL}api/ts-nft/validate/${username}`
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const getUserPrimaryName = async (userAddress: string) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://testnet-rpc2.monad.xyz/52227f026fa8fac9e2014c58fbf5643369b3bfc6"
    );
    const contract = new ethers.Contract(
      "0xFEEbcbABdE84a45D4e86132F791c6DbE6c94e6B4",
      [
        {
          inputs: [
            { internalType: "address", name: "userAddress", type: "address" },
          ],
          name: "userPrimaryNFT",
          outputs: [
            { internalType: "uint256", name: "nftTokenId", type: "uint256" },
            { internalType: "string", name: "nftUsername", type: "string" },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      provider
    );
    const res = await contract.userPrimaryNFT(userAddress);
    return res[1] || "";
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const mintUsername = async (username: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${BACKEND_URL}api/ts-nft/mint-username`,
      {
        username,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const setUserPrimaryName = async (
  username: string,
  userAddress?: string
): Promise<GetPrimaryNameResponse> => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${BACKEND_URL}api/ts-nft/set-primary-username`,
      {
        username,
        userAddress,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const fetchMonadRate = async (
  assetCA: string = "0x0000000000000000000000000000000000000000"
) => {
  const monad_usdc_address = "0xf817257fed379853cDe0fa4F97AB987181B1E5Ea";
  const mon_address = "0x0000000000000000000000000000000000000000";
  if (assetCA?.toLowerCase() == monad_usdc_address.toLowerCase()) {
    return 0.999999;
  }
  if (assetCA?.toLowerCase() == mon_address.toLowerCase()) {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://testnet-rpc2.monad.xyz/52227f026fa8fac9e2014c58fbf5643369b3bfc6"
    );
    const poolFetcher = new KuruSdk.PoolFetcher("https://api.testnet.kuru.io/");
    const result = await poolFetcher.getAllPools(
      "0xf817257fed379853cDe0fa4F97AB987181B1E5Ea",
      mon_address
    );
    const bestPath = await KuruSdk.PathFinder.findBestPath(
      provider,
      mon_address,
      "0xf817257fed379853cDe0fa4F97AB987181B1E5Ea",
      Number(1), // to get equivalent of 1 MON in USDC
      "amountIn",
      undefined,
      result
    );
    return bestPath.output;
  }
};

export const getProtocolWebsite = (ProtocolTitle?: string) => {
  switch (ProtocolTitle?.toLowerCase()) {
    case "apriori":
      return "https://www.apr.io/";
    case "fastlane":
      return "https://www.shmonad.xyz/";
    case "magma":
      return "https://www.magmastaking.xyz/";
    case "thunderhead":
      return "https://thunderhead.xyz/";
    default:
      return "";
  }
};

export const getAssetsByOwner = async (
  owner: string
): Promise<{
  result: {
    items: {
      grouping: [
        {
          group_key: string;
          group_value: string;
          verified: string;
        }
      ];
    }[];
  };
}> => {
  const url =
    "https://mainnet-aura.metaplex.com/8cf28d2f-15b5-4f6b-90ad-bac89d392bc3";
  try {
    const res = await axios.post(
      `${url}`,
      JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "getAssetsByOwner",
        params: {
          ownerAddress: `${owner}`,
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};

export const updateUserSolanaAddress = async (solanaAddress: string) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.put(
      `https://action-link-stage.townesquare.xyz/api/users/update-solana-address`,
      {
        solana_address: `${solanaAddress}`,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("update solana address:", error);
    if (axios.isAxiosError(error)) {
      // Return the actual error message from the server
      throw error.response?.data || error.message;
    } else {
      // Handle unexpected errors
      throw error;
    }
  }
};
