import * as React from "react";

function SvgRankComponent({ fill = "#fff", ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_8016_98037)"
        stroke={fill}  
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 21h6M12 17.25V21M5.438 12H4.5a3 3 0 01-3-3V7.5a.75.75 0 01.75-.75h3M18.563 12h.937a3 3 0 003-3V7.5a.75.75 0 00-.75-.75h-3" />
        <path d="M5.25 4.5h13.5v5.916c0 3.721-2.977 6.806-6.698 6.834A6.751 6.751 0 015.25 10.5v-6z" />
      </g>
      <defs>
        <clipPath id="clip0_8016_98037">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRankComponent;
