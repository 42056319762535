import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../profile/user";
import dialogSlice from "../dialog/dialog";
export const store = configureStore({
  reducer: {
    userState: userSlice,
    dialogState: dialogSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
