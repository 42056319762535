 import { useState } from "react";
import {
  Field,
  Input,
  Label,
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import ImageOverlay from "./ImageOverlay";
import { RiArrowDownSFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { InputToken, OutputToken } from "./hooks/types";
import { useAggregator } from "./hooks/content";
import { useFetchStakeAggregatorAssets } from "./hooks";
 
 
 export function InputTokenModal() {
    let [isOpen, setIsOpen] = useState(false);
    const { inputToken, handleSetInputToken } = useAggregator();
    const {data} = useFetchStakeAggregatorAssets()

    function open() {
      setIsOpen(true);
    }

    function close() {
      setIsOpen(false);
    }

    
    const handleTokenSelect = (token: InputToken) => {
      handleSetInputToken(token);
      close(); // Close the modal after selection
    };

    return (
      <>
        <Button
          onClick={open}
          className="w-[150px] h-[42px] flex justify-between items-center border text-[16px] font-semibold pl-[12px] pr-[8px] border-[#666] bg-white/10 rounded-[40px] text-white outline-none"
        >
          <span className="flex gap-2">
            <ImageOverlay
              mainImageSrc={inputToken?.from_asset_logo}
              overlayImageSrc={inputToken?.from_network_url}
            />
            <p className="text-[16px] font-medium">
              {inputToken?.from_asset}
            </p>
          </span>
          <RiArrowDownSFill color="#FFF" />
        </Button>

        <Dialog
          open={isOpen}
          as="div"
          className="relative z-40 focus:outline-none"
          onClose={close}
        >
          <DialogBackdrop className="fixed inset-0 bg-black/80" />
          <div className="fixed md:w-[calc(100vw-240px)] items-center flex justify-center md:ml-[240px]  inset-0 z-10 overflow-y-auto">
            <div className="">
              <DialogPanel
                transition
                className="w-[417px] border rounded-[16px] bg-[#222] p-[24px] border-white/40 h-fit"
              >
                <DialogTitle
                  as="div"
                  className="flex justify-between text-white"
                >
                  <p className="text-[18px] font-medium leading-[22px]">
                    Select token
                  </p>
                  <IoMdClose
                    onClick={close}
                    className="text-right"
                    size={22}
                    color="#fff"
                  />
                </DialogTitle>
                <div className="w-full mt-[24px]">
                  {data?.sourceAssets && data?.sourceAssets.reverse().map((token, index) => (
                    <button
                      key={index}
                      onClick={() => handleTokenSelect(token)}
                      className="flex items-center cursor-custom w-full hover:bg-[#404040]/90 p-2"
                    >
                      <span className="flex gap-2">
                        <ImageOverlay
                          mainImageSrc={token.from_asset_logo}
                          overlayImageSrc={token.from_network_url}
                        />
                        <p className="text-[16px] text-[#FFF] font-medium">
                          {token.from_asset}
                        </p>
                      </span>
                    </button>
                  ))}
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </>
    );
  }