import { useState } from "react";
import {
  Field,
  Input,
  Label,
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import ImageOverlay from "./ImageOverlay";
import { RiArrowDownSFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { OutputToken } from "./hooks/types";
import { useAggregator } from "./hooks/content";
import { useFetchStakeAggregatorAssets } from "./hooks";
//import { useOutputToken } from "./hooks/content";

export function OutputTokenModal() {
  let [isOpen, setIsOpen] = useState(false);
  let [selectedOutputToken, setSelectedOutputtoken] = useState<{
    tokenLogo: string;
    chainLogo: string;
    tokenName: string;
  } | null>(null);
  const { outputToken, handleSetOutputToken } = useAggregator();
  const { data , isSuccess} = useFetchStakeAggregatorAssets();

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  // const OUTPUT_TOKEN = [
  //   {
  //     tokenLogo: "/assets/main_v4/monad.svg",
  //     chainLogo: "/assets/main_v4/monad.svg",
  //     tokenSymbol: "aprMON",
  //     apy: 8,
  //     protocolName: "aPriori",
  //   },
  //   {
  //     tokenLogo: "/assets/main_v4/monad.svg",
  //     chainLogo: "/assets/main_v4/monad.svg",
  //     tokenSymbol: "sMON",
  //     apy: 9,
  //     protocolName: "Kintsu",
  //   },
  //   {
  //     tokenLogo: "/assets/main_v4/monad.svg",
  //     chainLogo: "/assets/main_v4/monad.svg",
  //     tokenSymbol: "mMON",
  //     apy: 10,
  //     protocolName: "Magma",
  //   },
  //   {
  //     tokenLogo: "/assets/main_v4/monad.svg",
  //     chainLogo: "/assets/main_v4/monad.svg",
  //     tokenSymbol: "mMON",
  //     apy: 10,
  //     protocolName: "Thunderhead",
  //   },
  //   {
  //     tokenLogo: "/assets/main_v4/monad.svg",
  //     chainLogo: "/assets/main_v4/monad.svg",
  //     tokenSymbol: "shMON",
  //     apy: 10,
  //     protocolName: "FastLane",
  //   },
  // ];

  return (
    <>
      <Button
        onClick={open}
        className="w-[150px] h-[42px] flex justify-between items-center border text-[16px] font-semibold pl-[12px] pr-[8px] border-[#666] bg-white/10 rounded-[40px] text-white outline-none"
      >
        {outputToken ? (
          <span className="flex text-[15px] gap-2">
            <ImageOverlay
              mainImageSrc={outputToken.to_asset_logo}
              overlayImageSrc={outputToken.to_network_url}
            />
            {outputToken.to_asset}
          </span>
        ) : (
          <span className="text-[#FFFFFF] text-[15px] leading-5">
            Select token{" "}
          </span>
        )}
        <RiArrowDownSFill color="#FFF" />
      </Button>

      <Dialog
        open={isOpen}
        as="div"
        className="relative z-40 focus:outline-none"
        onClose={close}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed md:w-[calc(100vw-240px)] items-center flex justify-center md:ml-[240px] inset-0 z-10 overflow-y-auto">
          <div className="">
            <DialogPanel
              transition
              className="w-[417px] border rounded-[16px] bg-[#222] p-[24px] border-white/40 h-fit"
            >
              <DialogTitle as="div" className="flex justify-between text-white">
                <p className="text-[18px] font-medium leading-[22px]">
                  Select token
                </p>
                <IoMdClose
                  onClick={close}
                  className="text-right"
                  size={22}
                  color="#fff"
                />
              </DialogTitle>
              <div className="w-full mt-[24px]">
                {data && data?.stakeFeatures?.map((token, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      handleSetOutputToken(token);
                      setIsOpen(false);
                    }}
                    className="flex items-center gap-[12px] cursor-custom w-full hover:bg-[#404040]/90 p-2"
                  >
                    <span className="flex gap-2">
                      <ImageOverlay
                        mainImageSrc={token.to_asset_logo}
                        overlayImageSrc={token.to_network_url}
                      />
                      <span className="text-[16px] text-[#FFF] font-medium">
                        {token.to_asset}
                      </span>
                      <span className="p-1 text-[11px] bg-[#404040] rounded-[4px] text-[#CCC] font-medium">
                        {token.__project__.title}
                      </span>
                    </span>
                    <span className="text-[13px] text-[#FFF]">
                      {token.apr ? `APY ${token.apr}%` : "APY NA"}
                    </span>
                  </button>
                ))}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
