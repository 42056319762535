import React from "react";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";

interface InitializationModalProps {
  onCancel: () => void;
  onText: string;
  progress: number;
   isTxnInitialized: boolean
}

const InitializationModal: React.FC<InitializationModalProps> = ({
  onCancel,
  onText,
  progress,
  isTxnInitialized
}) => {

  let [isWarningOpen, setIsWarningOpen] = useState(false);

  function open() {}

  function close() {
    setIsWarningOpen(true);
  }

  function cancelProcess() {
    setIsWarningOpen(false)
    onCancel()
  }

  return (
    <>
      {/* <Button
        onClick={open}
        className="rounded-md bg-black/20 py-2 px-4 text-sm font-medium text-white focus:outline-none data-[hover]:bg-black/30 data-[focus]:outline-1 data-[focus]:outline-white"
      >
        Open dialog
      </Button> */}

      <Dialog
        open={isTxnInitialized}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={close}
      >
        <DialogBackdrop className="fixed inset-0 bg-black/70" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            {isWarningOpen ? (
              <DialogPanel
                transition
                className="w-[342px] h-[343px] pt-[13px] px-[24px] pb-[24px] rounded-lg bg-[#222] flex flex-col items-center duration-300 ease-out  data-[closed]:opacity-0"
              >
                <DialogTitle
                  as="div"
                  className="flex items-end justify-end w-full"
                >
                  <IoMdClose onClick={()=> {
                    setIsWarningOpen(false)
                  }} className="text-end" size={32} color="#fff" />
                </DialogTitle>

                <div className="">
                  <div className="">
                    <h1 className="text-white text-[20px] font-semibold leading-[24px] text-center">
                      {" "}
                      Warning{" "}
                    </h1>
                    <p className="text-base text-white mt-[24px] leading-[21px] ">
                      If you cancel the process now, your transactions may
                      remain incomplete, and we cannot guarantee the safe return
                      or proper handling of your crypto assets.{" "}
                    </p>
                    <p className="text-white text-base mt-[24px] leading-[21px]">
                      {" "}
                      You are proceeding at your own risk.
                    </p>
                  </div>
                  <div className="flex gap-[40px] mt-[48px] justify-center ">
                    <button
                      onClick={() => {
                        setIsWarningOpen(false);
                      }}
                      className="text-white font-medium text-[18px]"
                    >
                      Back
                    </button>
                    <button onClick={() => {
                      cancelProcess()
                    }}  className="text-[18px] font-medium  text-[#FF4471]">
                      Cancel processs
                    </button>
                  </div>
                </div>
              </DialogPanel>
            ) : (
              <DialogPanel
                transition
                className="min-w-max h-[176px] pt-[48px] px-[24px] pb-[24px] gap-[40px] rounded-lg bg-[#222] flex flex-col items-center justify-between duration-300 ease-out data-[closed]:opacity-0"
              >
                <DialogTitle
                  as="div"
                  className="flex flex-col gap-[16px] min-w-max"
                >
                  <h3 className="text-[15px] font-semibold text-white">
                    {onText}
                  </h3>
                  <div className="relative w-full h-1 mt-2 bg-gray-700 rounded">
                    <div
                      className="h-full duration-500 ease-in-out rounded bg-[#9264F8] transition-width"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </DialogTitle>

                <div className="">
                  <Button
                    className="inline-flex items-center text-[18px] font-medium text-white focus:outline-none outline-none"
                    onClick={close}
                  >
                    Cancel
                  </Button>
                </div>
              </DialogPanel>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default InitializationModal;
