"use client";

import { WagmiProvider } from "wagmi";
import { config } from "../constants/config";


export default function WagmiProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  return <WagmiProvider config={config}>{children}</WagmiProvider>;
}
