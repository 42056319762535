import React, { useEffect, useState } from "react";
import { getNewusers } from "./hooks/helper";
import { GetNewcommerResponse, walletType } from "./hooks/types";
import { toast, ToastContainer } from "react-toastify";

interface UserComerProps {
  title: string;
}

const walletIcons: Record<walletType, string> = {
  [walletType.Phantom]: "/assets/main_v4/bphantom.svg",
  [walletType.Metamask]: "/assets/main_v4/metamask.svg",
  [walletType.BackPack]: "/assets/main_v4/bbackpack.svg",
  [walletType.Okx]: "/assets/main_v4/dokxx.svg",
  [walletType.Haha]: "/assets/main_v4/HahaIcon.svg",
};

const walletDisplayNames: { [key: string]: string } = {
  backpack: "Backpack",
  phantom: "Phantom",
  okxos: "OKX Wallet",
  haha: "HaHa Wallet",
};

const truncateAddress = (address: string): string =>
  address.length > 15
    ? `${address.slice(0, 9)}...${address.slice(-6)}`
    : address;

const truncatAddress = (address: string): string =>
  address.length > 15
    ? `${address.slice(0, 4)}.......${address.slice(-3)}`
    : address;

const generateRandomGradient = (): string => {
  const randomColor1 = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
  const randomColor2 = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0")}`;
  return `linear-gradient(135deg, ${randomColor1}, ${randomColor2})`;
};

const NewcomerComp: React.FC<UserComerProps> = ({ title }) => {
  const [tooltipText, setTooltipText] = useState("Copy referral code");
  const [newComers, setNewComers] = useState<GetNewcommerResponse["data"]>([]);
  const [preGeneratedGradients, setPreGeneratedGradients] = useState<string[]>(
    []
  );

  useEffect(() => {
    const fetchNewComers = async () => {
      try {
        const response = await getNewusers();
        if (response) {
          let data = response.data
            .sort(
              (a, b) =>
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
            )
            .slice(0, 40);
          console.error("newcomers data", data);

          // Pre-generate gradients for each newcomer
          const gradients = data.map(() => generateRandomGradient());
          setNewComers(data);
          setPreGeneratedGradients(gradients);
        }
      } catch (error) {
        console.error("Error fetching newcomers:", error);
      }
    };
    fetchNewComers();
  }, []);
  return (
    <div>
      <h2 className="font-semibold text-[23px] leading-[29.98px] mb-8">
        {title}
      </h2>
      <ToastContainer />
      <div className=" relative w-[360px] flex flex-col gap-[33px] rounded-[12px] border border-[#666666] overflow-hidden overflow-visible">
        <ul className="w-auto ">
          {newComers.map((item, index) => (
            <li
              key={item.id}
              className={`flex items-center gap-4 h-[62px] py-[14px] pr-6 pl-4 ${
                index % 2 === 0 ? "bg-[#222222]" : "bg-[#2D2D2D]"
              } ${index === 0 ? "rounded-t-[12px]" : ""} ${
                index === newComers.length - 1 ? "rounded-b-[12px]" : ""
              }`}
            >
              <span className="text-[12px] w-[65px] text-gray-light-5 opacity-80 ml-1">
                {new Date(item.created_at).toLocaleDateString()}
              </span>
              <div
                className="w-[34px] h-[34px] rounded-full"
                style={{ background: preGeneratedGradients[index] }}
              ></div>
              <div className="flex flex-col flex-1">
              <div className="flex justify-between items-center w-full">
                  <span className="text-[15px] font-semibold mr-2 leading-[21px] w-[145px] text-left text-[#FFFFFF]">
                    {truncateAddress(item.wallet_address)}
                  </span>
                  {item.wallet_type in walletIcons && (
                    <div className="relative flex-shrink-0 w-8 h-8">
                      <div className="group w-full h-full flex items-center justify-center">
                        <img
                          src={walletIcons[item.wallet_type]}
                          alt={`${item.wallet_type} wallet`}
                          className="w-auto h-6"
                        />
                        {/* Tooltip with Fixed Position */}
                        <div
                          className="absolute hidden group-hover:flex top-[-220%] left-1/2 transform -translate-x-1/2 
                      px-2 py-2 border border-[#404040] bg-black text-white text-[13px] shadow-md min-w-[180px] 
                      z-50 rounded text-center"
                        >
                          {item.wallet_type === "backpack" ? (
                            <div className="w-[267px]">
                              Connected with Backpack and will get
                              <span style={{ color: "#00EEFD" }}>
                                {" "}
                                +30% bonus rank
                              </span>{" "}
                              for all transactions
                            </div>
                          ) : item.wallet_type === "phantom" ? (
                            <div className="w-[267px]">
                              Connected with Phantom and will get
                              <span style={{ color: "#00EEFD" }}>
                                {" "}
                                +40% bonus rank
                              </span>{" "}
                              for all transactions
                            </div>
                          ) : item.wallet_type === "okxos" ? (
                            <div className="w-[267px]">
                              Connected with OKX wallet and will get
                              <span style={{ color: "#00EEFD" }}>
                                {" "}
                                +40% bonus rank
                              </span>{" "}
                              for all transactions
                            </div>
                          ) :  item.wallet_type === "metamask" ? (
                            <div>Connected with Metamask</div>
                          ):(
                            <div className="w-[180px]">
                              Connected with{" "}
                              {walletDisplayNames[item.wallet_type] ||
                                item.wallet_type}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Referral Information */}
                {item.referrals?.length > 0 && item.referrals[0]?.referred && (
                  <div className="md:text-[11px] text-[10px] text-gray-300 whitespace-nowrap">
                    Invited by{" "}
                    <span className="font-normal">
                      {truncatAddress(
                        item.referrals[0]?.referred?.wallet_address || "N/A"
                      )}
                    </span>{" "}
                    ref. code:{" "}
                    <span
                      className="relative font-normal text-gray-300 cursor-pointer group"
                      onClick={() => {
                        if (item.referrals[0]?.referred?.referral_code) {
                          navigator.clipboard.writeText(
                            item.referrals[0].referred.referral_code
                          );
                          setTooltipText("Copied!");
                          toast.success("Referral code copied to clipboard!", {
                            position: "top-right",
                            autoClose: 3000,
                            theme: "dark",
                          });
                          setTimeout(
                            () => setTooltipText("Copy referral code"),
                            4000
                          );
                        }
                      }}
                    >
                      {/* Referral code */}
                      <span className="group-hover:font-bold group-focus:font-bold text-white">
                        {item.referrals[0]?.referred?.referral_code || "N/A"}
                      </span>

                      {/* Tooltip */}

                      <div className="absolute bottom-full mb-1 left-1/2 transform -translate-x-1/2 text-xs bg-black text-white text-center px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity min-w-[102px] py-2 font-bold z-50">
                        {tooltipText}
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewcomerComp;
