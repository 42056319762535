import React, { useState, useEffect } from "react";
import { useGetArtistCollection } from "./hooks/helper";

function ArtistPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: collections = [] } = useGetArtistCollection();

  // Filter collections to only show those with isArtistOfTheWeek === true
  const featuredCollections = collections.filter(
    (collection) => collection.isArtistOfTheWeek
  );

  const collectionImages = featuredCollections[0]?.collectionImages || [];

  const truncatAddress = (address: string): string =>
    address.length > 15
      ? `${address.slice(0, 5)}.......${address.slice(-6)}`
      : address;

  // Close modal on Esc key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") setIsOpen(false);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };
  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % collectionImages.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + collectionImages.length) % collectionImages.length
    );
  };

  const closeModal = () => setIsOpen(false);

  return (
    <div className="flex flex-col items-center justify-center w-full my-6 overflow-hidden">
      {/* Spotlight Header */}
      <div className="hidden mb-6 sm:block">
        <img src="/assets/main_v4/Artistusers.svg" />
        <p className="text-[46px] leading-[56px] text-white font-semibold -mt-20 text-center">
          Artist Spotlight of the Week
        </p>
      </div>

      <div className="w-full md:hidden">
      <span className="block text-[23px] font-semibold mb-2 mt-4 ml-6 text-start">
          Artist Spotlight
        </span>
      </div>

      {featuredCollections.map((collection) => (
        <div className="flex md:flex-row mt-[28px]  justify-between md:w-[900px] w-[328px] flex-col">
          <div className="md:h-[447px] md:w-[508px] w-[328px] flex-col md:flex-row md:flex justify-items-center mr-4 border border-white/40 px-6 py-8 bg-black rounded-[12px] gap-6 mb-6">
            {collection.artists.map((artist) => (
              <div
                key={artist.id}
                className="w-[214px] mx-auto flex flex-col items-center text-center"
              >
                <img
                  className="mb-4 rounded-full mt-8 w-[100px] h-[100px]"
                  src={artist.pfp}
                />
                <p className="text-[32px] text-center leading-[39px] text-white mb-3 font-semibold">
                  {artist.name}
                </p>
                <button
                  onClick={() => {
                    if (artist.x_handle_url) {
                      window.open(
                        artist.x_handle_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                  className="flex items-center justify-center h-[33px] gap-2 px-4 border border-gray rounded-[30px] bg-[#222222] mb-6 cursor-pointer"
                >
                  <img src="/assets/main_v4/twitter.svg" />
                  <p className="text-[14px] font-normal"> {artist.x_handle}</p>
                </button>
                <p className="text-center text-[15px] leading-[19px]">
                  {artist.bio}
                </p>
              </div>
            ))}
          </div>

          <div className="relative">
            <img
              src={collection.collectionImages[0]}
              onClick={() => openModal(0)}
              className="cursor-pointer rounded-[4px] h-[447px] w-[360px] object-cover"
            />
            {/* Overlay for total images count */}
            <div className="absolute top-2 right-2 bg-black/30 text-white text-[14px] px-2 flex gap-1 justify-center  py-1 w-[52px] h-[28px] rounded-[6px]">
              {collection.collectionImages.length}{" "}
              <img src="/assets/main_v4/Images.svg" />
            </div>
          </div>
        </div>
      ))}

      {/* NFT Collection Section */}
      {featuredCollections.map((collection) => (
        <div className="hidden sm:block  mt-8 w-[900px]">
          <h2 className="text-[23px] font-semibold mb-6">
            {collection.collectionName}
          </h2>

          <div className="md:h-[256px] h-auto border border-white/40 rounded-[12px] flex p-6 gap-6">
            <img
              src="/assets/main_v4/artbabnner.svg"
              className="rounded-[4px]"
            />
            <div className="flex flex-col justify-between flex-1">
              <div>
                <h3 className="text-[20px] font-semibold mb-2">
                  {collection.descriptionTitle}
                </h3>
                <p className="text-[14px] text-gray-300 mb-3">
                  {collection.collectionDescription}
                </p>
                <div className="flex items-center gap-2 mb-6 text-sm">
                  <span className="text-gray-400">Contract address:</span>
                  <span className="font-medium">
                    {truncatAddress(collection.contractAddress)}
                  </span>
                </div>
              </div>

              <div className="flex gap-4">
                <div>
                  <p className="text-[14px] text-[#CCCCCC] leading-[17px] md:w-[179px] w-full mb-2">
                    Holders’ Townesquare Bonus
                  </p>
                  <div className="flex items-center gap-1 px-2 py-2 border rounded-full border-white/40">
                    <img
                      src="/assets/main_v4/poster_images/Lightning.png"
                      className="w-[17px] h-[17px]"
                    />
                    <span className="text-[#00eefd] font-bold">+30%</span>
                    <span className="text-sm">rank bonus</span>
                  </div>
                </div>
                {collection.totalSupply != null && (
                  <div>
                    <p className="text-[14px] text-[#CCCCCC] leading-[17px] mb-2">
                      Total items
                    </p>
                    <div className="px-6 py-2 text-center border rounded-full border-white/40 h-[36px]">
                      {collection.totalSupply}
                    </div>
                  </div>
                )}

                <div>
                  <p className="text-[14px] text-[#CCCCCC] leading-[17px] mb-2">
                    Links
                  </p>
                  <div className="flex gap-3">
                    <button
                      onClick={() => {
                        if (collection.x_handle) {
                          window.open(
                            collection.x_handle,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className="bg-[#222222] rounded-full"
                    >
                      <img src="/assets/main_v4/twibig.svg" />
                    </button>
                    <button
                      onClick={() => {
                        if (collection.website) {
                          window.open(
                            collection.website,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className="bg-[#222222] rounded-full"
                    >
                      <img src="/assets/main_v4/webbig.svg" />
                    </button>

                    {/* <button className=" h-[40px] text-[15px] w-[150px] ml-4 rounded-[32px] text-white bg-[#9264F8] opacity-40">
                      Mint finished
                    </button> */}
                    <button
                      onClick={() => {
                        if (collection.website) {
                          window.open(
                            collection.website,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className=" h-[40px] text-[15px] w-[150px] ml-4 rounded-[32px] text-white bg-[#9264F8]  hover:bg-[#9F71FF]"
                    >
                      Get NFTs
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* // mobileview */}

      {featuredCollections.map((collection) => (
        <div className="block md:hidden flex-col mt-8 w-[328px]">
          <h2 className="text-[23px] font-semibold mb-6">
            {collection.collectionName}
          </h2>

          <div className=" border border-white/40 rounded-[12px] flex flex-col p-6 gap-6">
            <img src="/assets/main_v4/artbabnner.svg" className="rounded-[4px]" />
            <div className="flex flex-col flex-1 ">
              <div>
                <h3 className="text-[20px] font-semibold mb-2">
                  {collection.descriptionTitle}
                </h3>
                <p className="text-[14px] text-gray-300 mb-3">
                  {collection.collectionDescription}
                </p>
                <div className="flex items-center gap-2 mb-6 text-sm">
                  <span className="text-gray-400">Contract address:</span>
                  <span className="font-medium">
                    {truncatAddress(collection.contractAddress)}
                  </span>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-[14px] text-[#CCCCCC] leading-[17px] md:w-[179px] mb-2">
                  Holders’ Townesquare Bonus
                </p>
                <div className="border border-white/40 rounded-full px-2 py-2 flex w-[179px] items-center gap-1">
                  <img
                    src="/assets/main_v4/poster_images/Lightning.png"
                    className="w-[17px] h-[17px]"
                  />
                  <span className="text-[#00eefd] font-bold">+30%</span>
                  <span className="text-sm">rank bonus</span>
                </div>
              </div>

              <div className="flex gap-4">
              {collection.totalSupply != null && (
                  <div>
                    <p className="text-[14px] text-[#CCCCCC] leading-[17px] mb-2">
                      Total items
                    </p>
                    <div className="px-6 py-2 text-center border rounded-full border-white/40 h-[36px]">
                      {collection.totalSupply}
                    </div>
                  </div>
                )}

                <div>
                  <p className="text-[14px] text-[#CCCCCC] leading-[17px] mb-2">
                    Links
                  </p>
                  <div className="flex gap-3">
                    <button
                      onClick={() => {
                        if (collection.x_handle) {
                          window.open(
                            collection.x_handle,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className="bg-[#222222] rounded-full"
                    >
                      <img src="/assets/main_v4/twibig.svg" />
                    </button>
                    <button
                      onClick={() => {
                        if (collection.website) {
                          window.open(
                            collection.website,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className="bg-[#222222] rounded-full"
                    >
                      <img src="/assets/main_v4/webbig.svg" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                      onClick={() => {
                        if (collection.website) {
                          window.open(
                            collection.website,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                      className=" h-[40px] text-[15px] w-[150px] mt-4 rounded-[32px] text-white bg-[#9264F8]  hover:bg-[#9F71FF]"
                    >
                      Get NFTs
                    </button>
            </div>
          </div>
        </div>
      ))}

      <div className="mt-8 md:w-[900px] w-[328px]">
        <h2 className="text-[23px] font-semibold mb-5">
          Previous artist features
        </h2>
        <div className="flex flex-row flex-wrap gap-6">
          {collections
            .filter((collection) => collection.isArtistOfTheWeek === false)
            .map((collection) => (
              <div
                key={collection.id}
                className="flex flex-col w-[328px] h-[205px] border border-white/40 rounded-[12px] p-6 gap-4"
              >
                {/* Artist Info */}
                <div className="flex flex-row gap-4 items-center">
                  <img
                    src={
                      collection.artists[0]?.pfp ||
                      "/assets/main_v4/groupartist.svg"
                    }
                    // src={
                    //   collection.artists[0]?.pfp ||
                    //   "/assets/main_v4/groupartist.svg"
                    // }
                    className="rounded-full w-[72px] h-[72px]"
                    alt={collection.artists[0]?.name || "Artist Image"}
                  />
                  <h2 className="text-[23px] font-semibold">
                    {collection.artists.length > 1
                      ? `${collection.artists[0]?.name} & ${collection.artists[1]?.name}`
                      : collection.artists[0]?.name || "Unknown Artist"}
                  </h2>
                </div>

                {/* NFT Collection Info */}
                <p className="text-[13px]">NFT Collection</p>
                <div className="flex items-center gap-3 -mt-2">
                  <img
                    src={"/assets/main_v4/artimage.svg"}
                    className="rounded-[4px] w-[48px] h-[48px]"
                    alt={collection.collectionName || "Collection Image"}
                  />
                  <h2 className="text-[18px] font-semibold">
                    {collection.descriptionTitle}
                  </h2>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Image Modal */}
      {isOpen && collectionImages.length > 0 && (
        <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full bg-black/90">
          {/* Close Button */}
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 md:top-6 md:right-6 h-[50px] w-[50px] md:h-[60px] md:w-[60px] 
                 bg-[#282828] hover:bg-[#323232] rounded-full flex justify-center items-center"
          >
            <img
              className="w-6 h-6 md:h-8 md:w-8"
              src="/assets/main_v4/X.svg"
              alt="Close"
            />
          </button>

          {/* Image */}
          <img
            src={collectionImages[currentIndex]}
            className="md:w-[600px] w-[320px] h-[500px] md:h-[600px] rounded-[4px] object-contain"
            alt={`Collection image ${currentIndex + 1}`}
          />

          {collectionImages.length > 1 && (
            <div className="flex items-center justify-between w-full px-4 mt-4 md:absolute md:top-1/2 md:-translate-y-1/2 md:mt-0">
              {/* Previous Button */}
              <button
                onClick={prevImage}
                className="h-[50px] w-[50px] md:h-[60px] md:w-[60px] bg-[#282828] hover:bg-[#323232] 
                    flex justify-center items-center rounded-full"
              >
                <img src="/assets/main_v4/Vector.svg" alt="Previous" />
              </button>

              {/* Image Counter */}
              <div className="block text-lg text-white  md:hidden">
                {currentIndex + 1} / {collectionImages.length}
              </div>

              {/* Next Button */}
              <button
                onClick={nextImage}
                className="h-[50px] w-[50px] md:h-[60px] md:w-[60px] bg-[#282828] hover:bg-[#323232] 
                    flex justify-center items-center rounded-full"
              >
                <img src="/assets/main_v4/Vector2.svg" alt="Next" />
              </button>
            </div>
          )}
          {/* Image Counter for large scn*/}
          <div className="hidden mt-4 text-lg text-white md:block ">
            {currentIndex + 1} / {collectionImages.length}
          </div>
        </div>
      )}
    </div>
  );
}

export default ArtistPage;
