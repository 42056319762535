import React, { useState } from "react";
import { AiFillThunderbolt } from "react-icons/ai";
 
import ChartContainer from "./tremappp";
import TreemapChart from "./TopProject";


const MonadVolume: React.FC = () => {
  const [selectedTime, setSelectedTime] = useState("1d");
  return (
    <div className="p-6  flex-1 mx-16">
      {/* Stats Section */}
      <div className="flex justify-center mb-12">
        <div className="grid grid-cols-4 gap-4 w-[746px]">
          {[
            { label: "Total volume (USD)", value: "$20,000" },
            { label: "Total volume (tMON)", value: "20,000" },
            { label: "Total transactions", value: "14,400" },
            { label: "Active protocols", value: "12" },
          ].map((stat, index) => (
            <div
              key={index}
              className="bg-[#1A1A1A] h-[98px] w-[174px] rounded-xl flex flex-col items-center justify-center border border-white/10"
            >
              <p className="text-2xl font-bold mb-1">{stat.value}</p>
              <p className="text-sm text-gray-400">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Top Projects Section */}
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Top projects</h2>
        <div className="bg-[#12101B]  border border-[#8D8D8D] p-1 rounded-full flex gap-1">
          {["1d", "7d", "30d"].map((time) => (
            <button
              key={time}
              className={`px-6 py-1.5 rounded-full text-sm transition-all ${
                selectedTime === time
                  ? "bg-[#9264F8] text-white"
                  : "text-gray-400"
              }`}
              onClick={() => setSelectedTime(time)}
            >
              {time}
            </button>
          ))}
        </div>
      </div>

      {/* <div className="grid grid-cols-4 gap-2 mb-6">
        {[
          {
            name: "aPriori",
            percent: "20.2%",
            value: "$1,200.45",
            chart: "-4.2%",
            logo: "/assets/main_v4/apori.svg",
            rank: "1",
          },
          {
            name: "Kuru",
            percent: "18.4%",
            value: "$1,186.26",
            chart: "+5.1%",
            logo: "/assets/main_v4/kuru.svg",
            rank: "2",
          },
          {
            name: "Ethena",
            percent: "16.8%",
            value: "$1,126.16",
            chart: "+3.9%",
            logo: "/assets/main_v4/ethena.svg",
            rank: "3",
          },
          {
            name: "Magma",
            percent: "8.2%",
            value: "$1,200.45",
            chart: "-4.2%",
            logo: "/assets/main_v4/magma.svg",
          },
          {
            name: "Narwhal",
            percent: "7.4%",
            value: "$1,200.45",
            chart: "+2.2%",
            logo: "/assets/main_v4/narwa.svg",
          },
          {
            name: "Aave",
            percent: "5.2%",
            value: "$1,200.45",
            chart: "-1.2%",
            logo: "/assets/main_v4/AAVE.svg",
          },
          {
            name: "Lido",
            percent: "4.8%",
            value: "$1,200.45",
            chart: "+3.1%",
            logo: "/assets/main_v4/lido.svg",
          },
          {
            name: "1inch",
            percent: "3.9%",
            value: "$1,200.45",
            chart: "-1.4%",
            logo: "/assets/main_v4/inch.svg",
          },
          {
            name: "Perpl",
            percent: "3.5%",
            value: "$1,200.45",
            chart: "+0.4%",
            logo: "/assets/main_v4/pearl.svg",
          },
          {
            name: "Wormhole",
            percent: "3.4%",
            value: "$1,200.45",
            chart: "+1.2%",
            logo: "/assets/main_v4/worhh.svg",
          },
          {
            name: "Uniswap",
            percent: "2.9%",
            value: "$1,200.45",
            chart: "-0.9%",
            logo: "/assets/main_v4/unnisw.svg",
          },
          {
            name: "PancakeSwap",
            percent: "2.8%",
            value: "$1,200.45",
            chart: "+0.4%",
            logo: "/assets/main_v4/pancake.svg",
          },
          {
            name: "Jupiter",
            percent: "2.4%",
            value: "$1,200.45",
            chart: "-2.2%",
            logo: "/assets/main_v4/jupite.svg",
          },
        ].map((project, index) => (
          <div
            key={index}
            className={`p-4 rounded-lg ${
              project.chart.includes("+")
                ? "bg-[#2AB576]/40"
                : "bg-[#FF4471]/40"
            } ${index < 3 ? "col-span-1 row-span-2" : "col-span-1"}`}
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="relative">
                  <img
                    src={project.logo}
                    alt={project.name}
                    className="h-10 w-10 rounded-full"
                  />
                  
                </div>
                <span className="leading-[24px] text-[20px] font-semibold">
                  {project.name}
                </span>
              </div>
              {project.rank && (
                <span className="bg-[#FFF5BD] w-[31px] text-center h-[31px] px-2 py-0.5 rounded-lg text-[#484747] font-medium">
                  {project.rank}
                </span>
              )}
            </div>

            
            <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
              <div className="flex items-center font-bold">
                <AiFillThunderbolt size={12} color="#00EEFD" />
                <p className="text-[#00EEFD] text-[12px] pl-1">40%</p>
              </div>
              <p className="text-[12px]">bonus</p>
            </div>
            <div className="mt-2">
              <div className="flex items-baseline gap-2">
                <span className="text-3xl font-bold">{project.percent}</span>
                <span
                  className={`text-sm ${
                    project.chart.includes("+")
                      ? "text-[#2AB576]"
                      : "text-[#FF4471]"
                  }`}
                >
                  {project.chart}
                </span>
              </div>
              <p className="text-sm text-gray-400 mt-2">{project.value}</p>
            </div>
          </div>
        ))}
      </div> */}

      {/* <ChartContainer/> */}
       
      <TreemapChart />

      {/* All Projects Table */}
      <div className="mb-4 flex justify-between items-center mt-4 ">
        <h2 className="text-xl font-semibold">All projects</h2>
        <div className="bg-[#12101B]  border border-[#8D8D8D] p-1 rounded-full flex gap-1">
          {["1d", "7d", "30d"].map((time) => (
            <button
              key={time}
              className={`px-6 py-1.5 rounded-full text-sm transition-all ${
                selectedTime === time
                  ? "bg-[#9264F8] text-white"
                  : "text-gray-400"
              }`}
              onClick={() => setSelectedTime(time)}
            >
              {time}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-[#121212] rounded-xl p-4">
        {/* Table Header */}
        <div className="grid grid-cols-6 text-[#CCCCCC] text-[14px]  gap-20 pb-4 border-b border-white/10 px-4">
          <div className="col-span-2">  </div>
          <div>Volume (USD)</div>
          <div className="col-span-1">Volume (tMON)</div>
          <div className="col-span-1">Transactions</div>
          <div className="col-span-1">Volume Share</div>
        </div>

        {/* Table Rows */}
        {[
          {
            rank: 1,
            name: "aPriori",
            volumeUSD: "$20,000",
            volumeMON: "20,000",
            transactions: "83,021",
            logo: "/assets/main_v4/apori.svg",
            bonus: "+40%",
            volumeshare: "20.2%",
          },
          {
            rank: 2,
            name: "Kuru",
            volumeUSD: "$20,000",
            volumeMON: "20,000",
            transactions: "23,021",
            logo: "/assets/main_v4/kuru.svg",
            volumeshare: "20.2%",
          },
          {
            rank: 3,
            name: "Ethena",
            volumeUSD: "$20,000",
            volumeMON: "20,000",
            transactions: "23,021",
            logo: "/assets/main_v4/ethena.svg",
            bonus: "+40%",
            volumeshare: "20.2%",
          },
          {
            rank: 4,
            name: "Magma",
            volumeUSD: "$20,000",
            volumeMON: "20,000",
            transactions: "83,021",
            logo: "/assets/main_v4/magma.svg",
            volumeshare: "20.2%",
          },
          {
            rank: 5,
            name: "Narwhal finance",
            volumeUSD: "$20,000",
            volumeMON: "20,000",
            transactions: "83,021",
            logo: "/assets/main_v4/narwa.svg",
            volumeshare: "20.2%",
          },
        ].map((project, index) => (
          <div
            key={index}
            className={`grid grid-cols-6 items-center h-[52px] px-4 py-2 border-b border-white/10 last:border-0 ${
              index % 2 === 0 ? "bg-[#222222]" : "bg-[#121212]"
            }`}
          >
            <div className="col-span-2 flex items-center gap-2">
              <span className="text-gray-400 mr-2">{project.rank}</span>
              <div className="flex items-center gap-2">
                <img
                  src={project.logo}
                  alt={project.name}
                  className="h-8 w-8 rounded-full"
                />
                <span className="font-semibold text-[18px] leading-[24px]">
                  {project.name}
                </span>
                {project.bonus && (
                  <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
                    <div className="flex items-center font-bold">
                       <img src="/assets/main_v4/poster_images/Lightning.png" className="w-[14px] h-[14px]"/>
                      <p className="text-[#00EEFD] text-[12px] pl-1">40%</p>
                    </div>
                    <p className="text-[12px]">bonus</p>
                  </div>
                )}
              </div>
            </div>
            <div className=" text-center  font-semibold text-[15px]">
              {project.volumeUSD}
            </div>
            <div className=" text-center   ml-8 font-semibold text-[15px]">
              {project.volumeMON}
            </div>
            <div className="text-center ml-8 font-semibold text-[15px]">
              {project.transactions}
            </div>
            <div className="text-center ml-10 font-semibold text-[15px]">
              {project.volumeshare}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonadVolume;
