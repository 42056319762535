import React from "react";

interface Referral {
  created_at: string;
  user: {
    wallet_address: string;
  };
}

interface ReferredModalProps {
  referrals: Referral[];
  onClose: () => void;
}

const truncateAddress = (address: string): string =>
  address.length > 15 ? `${address.slice(0, 4)}...${address.slice(-10)}` : address;

const ReferredModal: React.FC<ReferredModalProps> = ({ referrals, onClose }) => {
  console.log("Total Referrals:", referrals.length);

  
  const sortedReferrals = [...referrals].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
      <div className="relative flex flex-col items-center bg-[#222222] rounded-[16px] px-8 py-14 md:w-[375px] w-[320px] border border-[#FFFFFF66]">
        <button className="absolute top-4 right-5 hover:bg-black" onClick={onClose}>
          <img src="/assets/main_v4/clos.svg" className="px-2" alt="close" />
        </button>
        <p className=" mb-8 text-[23px] text-center text-white">My Referrals  </p>
        <ul className="w-full text-white mb-2 text-sm">
          {sortedReferrals.length > 0 ? (
            sortedReferrals.map((referral, index) => (
              <li
                key={index}
                className="flex justify-between py-2  border-b border-gray-600 overflow-hidden"
              >
                <span className="text-[15px] text-[#CCCCCC]">
                  {new Date(referral.created_at).toLocaleDateString()}
                </span>
                <span className=" text-[15px] text-white font-semibold">
                  {truncateAddress(referral.user.wallet_address)}
                </span>
              </li>
            ))
          ) : (
            <p className="text-gray-400 text-center">No referrals yet</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ReferredModal;
