import { PinataSDK } from "pinata-web3";
import { FILEBASE_ACCESS_KEY, FILEBASE_SECRET_KEY, PINATA_GATEWAY, PINATA_JWT } from "../pages/MainV3/hooks/env";
import { defineChain } from "viem";

import { http, createConfig } from "wagmi";
import AWS from "aws-sdk";
import { mainnet, sepolia, polygon, base, arbitrum } from "wagmi/chains";

import { injected, metaMask } from "wagmi/connectors";

const phantomConnectorInjected = injected({
  target: "phantom",
});

const okxConnectorInjected = injected({
  target: "okxWallet",   
});



const backPackConnectorInjected = injected({
  target:  "backpack"
});

const connectors = [
  phantomConnectorInjected,
  okxConnectorInjected,
  backPackConnectorInjected,
  ...(typeof window !== "undefined" ? [metaMask()] : []), // Conditionally add metaMask
];

export const monadTestnet = defineChain({
  id: 10143,
  name: "Monad",
  nativeCurrency: { name: "Monad Testnet", symbol: "MON", decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        "https://testnet-rpc2.monad.xyz/52227f026fa8fac9e2014c58fbf5643369b3bfc6",
      ],
    },
  },
  testnet: true,
});

export const config = createConfig({
  chains: [monadTestnet],
  connectors,
  transports: {
    [monadTestnet.id]: http(),
  },
  ssr: false,
});

export const pinata = new PinataSDK({
  pinataJwt: PINATA_JWT,
  pinataGateway: PINATA_GATEWAY,
});

export const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  accessKeyId: FILEBASE_ACCESS_KEY,
  secretAccessKey: FILEBASE_SECRET_KEY,
  endpoint: "https://s3.filebase.com",
  region: "us-east-1",
  s3ForcePathStyle: true,
});
