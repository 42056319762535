import React, { useEffect, useState } from "react";
import { getAllHistory } from "./hooks/helper";
import { HistoryResponse } from "./hooks/types";
import { useWallet } from "./hooks/content";
import { useAccount } from "wagmi";
 

const HistoryTab = () => {
  const [groupedHistory, setGroupedHistory] = useState<
    Record<string, { logo: string; transactions: HistoryResponse[] }>
  >({});
  const [loading, setLoading] = useState<boolean>(true);
  const { pubAddress } = useWallet();
  const account = useAccount();
  const displayAddress = account.address;
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHistory = async () => {
      if (!pubAddress) {
        setError("Wallet address is not connected.");
        return;
      }
      try {
        const response = await getAllHistory(displayAddress || pubAddress);
        console.log("Fetched History Data:", response);
        console.log("Response Length:", response.length);

        if (!Array.isArray(response)) {
          setError("Invalid data format received");
          return;
        }

        // Sort all transactions by created_at (newest first)
        const sortedResponse = [...response].sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        const grouped = sortedResponse.reduce((acc, item) => {
          // Ensure __onboarding__ is never null/undefined
          const normalizedItem = {
            ...item,
            __onboarding__: item.__onboarding__ ?? {},
          };

          const project = normalizedItem.__onboarding__.__project__;
          const projectTitle = project?.title || "Stake & Earn";

          if (!acc[projectTitle]) {
            acc[projectTitle] = {
              logo: project?.logo || "/assets/main_v4/logo.png",
              transactions: [],
            };
          }

          acc[projectTitle].transactions.push(normalizedItem);
          return acc;
        }, {} as Record<string, { logo: string; transactions: HistoryResponse[] }>);

        // Sort transactions within each project group
        Object.values(grouped).forEach((project) => {
          project.transactions.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );
        });

        console.log("Grouped Data:", grouped);
        console.log("Total Projects:", Object.keys(grouped).length);
        console.log(
          "Total Transactions Grouped:",
          Object.values(grouped).reduce(
            (sum, item) => sum + item.transactions.length,
            0
          )
        );

        setGroupedHistory(grouped);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching history:", error);
        setError("Failed to fetch history data.");
        setLoading(false);
      }
    };

    fetchHistory();
  }, [pubAddress]);
  // Loading state
  if (loading) return <p>Loading...</p>;

  // Error state
  if (error) return <p className="text-red-500">{error}</p>;

  // Empty state
  if (!Object.keys(groupedHistory).length) return <p>No history available.</p>;

  return (
    <div className="relative">
      <div className="z-50 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
        {Object.entries(groupedHistory).map(
          ([projectTitle, { logo, transactions }]) => (
            <div
              key={projectTitle}
              className="relative flex flex-col gap-4 border border-white/40 bg-[#222222] md:p-6 px-4 rounded-[16px] md:max-h-[391px] w-[328px] md:w-[500px] overflow-y-auto scroll-container"
            >
              {/* Project Header */}
              <div className="flex items-center gap-2 mt-3">
                <img
                  src={logo}
                  alt={`${projectTitle} Logo`}
                  className="rounded-full w-14 h-14"
                />
                <h3 className="font-bold text-[23px] text-white">
                  {projectTitle}
                </h3>
              </div>
              {/* Transactions */}
              <div className="flex flex-col gap-2 md:w-[450px] w-[288px]">
                {transactions.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-start bg-[#121212] md:h-[70px] h-[86px] py-4 px-3 rounded-lg gap-4"
                  >
                    <div className="flex items-center gap-2">
                      <div className="relative inline-block w-full group">
                        <img
                          src={
                            item.__onboarding__?.onboarding_type === "swapping"
                              ? "/assets/main_v4/swwap.svg"
                              : item.__onboarding__?.onboarding_type ===
                                "lending"
                              ? "/assets/main_v4/lent.svg"
                              : item.__onboarding__?.onboarding_type ===
                                "staking"
                              ? "/assets/main_v4/staked.svg"
                              : item.type === "swapping"
                              ? "/assets/main_v4/swwap.svg"
                              : item.type === "staking"
                              ? "/assets/main_v4/staked.svg"
                              : "/assets/main_v4/default-icon.svg"
                          }
                          alt="Transaction Icon"
                          className="w-[36px] h-[36px] object-cover rounded-full"
                        />

                        <div
                          className=" absolute left-1/2 bottom-[30px] transform -translate-x-1/2
      hidden group-hover:flex bg-black text-white text-[11px] py-2 rounded-md 
      border border-[#404040] w-[180px] justify-end pr-2 items-center z-90
      pointer-events-none"
                        >
                          {item.type + " onboarding app" || "N/A"}
                          <div
                            className="absolute top-full left-1/2 transform -translate-x-1/2 
      border-[6px] border-transparent border-t-black"
                          ></div>
                        </div>
                      </div>
                      <div className="hidden md:flex -ml-4 gap-1">
                        
                        <img
                        src={
                          item?.__onboarding__?.asset_images
                            ?.source_asset_icon ||
                          item?.info?.from_asset_logo ||
                          "logo-image-url"
                        }
                        alt="Source Asset Logo"
                        className="w-[22px] h-[22px] rounded"
                      />
                      <img
                        src={
                          item?.__onboarding__?.asset_images?.dest_asset_icon ||
                          item?.info?.to_asset_logo ||
                          "logo-image-url"
                        }
                        alt="Destination Token Logo"
                        className="w-[22px] h-[22px] rounded"
                      />
                         </div>

                      
                    </div>

                    

                    <div className="flex flex-col p-2">
                    <div className="block md:hidden flex flex-row gap-1"> 
                    <img
                        src={
                          item?.__onboarding__?.asset_images
                            ?.source_asset_icon ||
                          item?.info?.from_asset_logo ||
                          "logo-image-url"
                        }
                        alt="Source Asset Logo"
                        className="w-[22px] h-[22px] rounded"
                      />
                      <img
                        src={
                          item?.__onboarding__?.asset_images?.dest_asset_icon ||
                          item?.info?.to_asset_logo ||
                          "logo-image-url"
                        }
                        alt="Destination Token Logo"
                        className="w-[22px] h-[22px] rounded"
                      />
                    </div>
                      <p className="text-[15px] text-[#CCCCCC] ">
                        Total{" "}
                        <span className="text-[#CCCCCC] font-medium">
                          {item.__onboarding__.onboarding_type === "swapping"
                            ? "Swapped"
                            : item.__onboarding__.onboarding_type === "lending"
                            ? "Lent"
                            : item.__onboarding__.onboarding_type === "staking"
                            ? "Staked"
                            : item.type}
                        </span>
                      </p>
                      <div className="flex gap-2 items-center">
                        <span className="text-[#FFFFFF] font-bold text-[15px]">
                          {Number(item.amount ?? 0).toFixed(3)}
                        </span>

                        <div className="flex  gap-2">
                          <img
                            src={
                              item?.__onboarding__?.asset_images
                                ?.dest_asset_icon ||
                              item?.info?.to_asset_logo ||
                              "logo-image-url"
                            }
                            alt="Destination Logo"
                            className="w-[22px] h-[22px]"
                          />
                          <span className="text-[#FFFFFF] font-medium text-[15px]">
                            {item?.__onboarding__?.asset_images
                              ?.dest_asset_symbol ||
                              item?.info?.to_asset ||
                              ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Right Section */}
                    <div
                      onClick={() =>
                        window.open(item.__onboarding__.generate_link)
                      }
                      className="flex flex-1 justify-end items-center mr-3 cursor-pointer"
                    >
                      <span className="text-[#B882FF] font-medium text-[15px]">
                        {item.__onboarding__.onboarding_type === "swapping"
                          ? "Swap"
                          : item.__onboarding__.onboarding_type === "lending"
                          ? "Lend"
                          : item.__onboarding__.onboarding_type === "staking"
                          ? "Stake"
                          : item.type}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default HistoryTab;
