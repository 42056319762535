import * as React from "react"

function SvgExploreComponent({ fill = "#fff", ...props }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_8016_98046)"
        stroke={fill} 
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.75 8.25h4.5M21.524 14.467l-4.092-9.311a2.25 2.25 0 00-3.182 0V15.75M9.75 15.75V5.156a2.25 2.25 0 00-3.182 0l-4.092 9.311" />
        <path d="M6 19.5A3.75 3.75 0 106 12a3.75 3.75 0 000 7.5zM18 19.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
      </g>
      <defs>
        <clipPath id="clip0_8016_98046">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgExploreComponent
