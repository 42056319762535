import { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import LoginButton from "./LoginBut";
import { getWalletIcon } from "./hooks/helper";
import Cookies from "js-cookie";
import { useAppSelector, useAppDispatch } from "../../controller/state/hooks";
import { toggleSolanaConnectWalletModal } from "../../controller/dialog/dialog";
import { useSolanaWalletProvider } from "./hooks/content";

interface BackpackWindow extends Window {
  backpack?: {
    solana?: {
      connect: () => Promise<{ publicKey: { toBase58: () => string } }>;
    };
  };
}

const ConnectSolanaWalletModal = () => {
  const [skip, setSkip] = useState(false);
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.dialogState.isSolanaConnectWalletModalOpen
  );
  const { connectBackpackWallet, connectPhantomWallet, isConnected } =
    useSolanaWalletProvider();
  function open() {
    dispatch(toggleSolanaConnectWalletModal(true));
  }

  function close() {
    dispatch(toggleSolanaConnectWalletModal(false));
  }

  type PhantomWindow = {
    phantom?: {
      solana?: {
        isPhantom: boolean;
      };
    };
  };

  const getProvider = () => {
    const windowWithPhantom = window as any;
    if ("phantom" in window) {
      const anyWindow: any = window;
      const provider = anyWindow.phantom.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    window.open("https://phantom.app/", "_blank");
  };

  const updateSkipForNow = () => {
    Cookies.set("dontShowAgain", "true", {
      expires: 365,
    });
    dispatch(toggleSolanaConnectWalletModal(false));
  };

  const updateCookie = () => {
    const dontShowAgain = Cookies.get("dontShowAgain");
    console.log("dontShowAgain", dontShowAgain);
    if (dontShowAgain === "true") {
      setSkip(true);
    }
  };

  useMemo(() => {
    updateCookie();
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-30 focus:outline-none"
        onClose={close}
      >
        <DialogBackdrop className="fixed inset-0 w-full bg-black/90" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-center justify-center min-h-full">
            <DialogPanel
              transition
              className="md:w-[557px] w-[320px] bg-[#222222] rounded-[16px] border border-[#FFFFFF66]/40 px-[32px] py-[24px] ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <DialogTitle as="div" className="w-full">
                <div className="flex items-center justify-end w-full">
                  <button className=" hover:bg-black" onClick={close}>
                    <img
                      src="/assets/main_v4/clos.svg"
                      className="px-2 "
                      alt={"close"}
                    />
                  </button>
                </div>
                <div className="my-[16px]">
                  <h1 className="text-[23px] text-center font-medium text-white">
                    Connect your Solana wallet with MadLads NFT to get
                    additional rank bonus!
                  </h1>
                </div>
              </DialogTitle>
              <div>
                <img src="/assets/main_v4/madlads_banner.svg" />
              </div>
              <div className="mt-[32px] flex flex-col items-center justify-center">
                <h2 className="flex gap-2">
                  Connect{" "}
                  <span>
                    <img src="/assets/main_v4/solana_wallet.svg" />
                  </span>{" "}
                  Wallet
                </h2>
                <div className="flex flex-col my-[16px] gap-2">
                  <LoginButton
                    title="Phantom"
                    roundedTop={true}
                    boost={40}
                    logo={getWalletIcon("phantom")}
                    onClick={() => {
                      connectPhantomWallet();
                    }}
                  />
                  <LoginButton
                    title=" Backpack"
                    logo={getWalletIcon("backpack")}
                    onClick={() => {
                      connectBackpackWallet();
                    }}
                    roundedBottom={true}
                  />
                </div>
                {!skip && (
                  <button
                    onClick={() => {
                      updateSkipForNow();
                    }}
                    className="text-[15px] mt-[12px] text-[#B882FF] font-medium"
                  >
                    Skip for now
                  </button>
                )}
                <div className="w-[490px] h-[1px] mt-[32px] bg-[#404040]">
                  {" "}
                </div>
                <div className="flex items-center justify-center mt-[24px]">
                  <p className="text-sm font-medium text-[#AAA] px-2">
                    Powered by{" "}
                  </p>
                  <img
                    src="/assets/main_v3/ts-logo.png"
                    alt="Townesquare Logo"
                    className="h-[19px] w-[95px]"
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConnectSolanaWalletModal;
