import * as React from "react";

interface LeftSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const LeftSvgComponent: React.FC<LeftSvgProps> = ({ color = "#fff", ...props }) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_24_35214)">
        <path
          d="M11.409 14.636a.41.41 0 00.187-.139.355.355 0 00.07-.21V6.71a.354.354 0 00-.07-.21.411.411 0 00-.187-.14.456.456 0 00-.454.082l-4.167 3.788a.379.379 0 00-.09.123.35.35 0 00.09.413l4.167 3.788a.455.455 0 00.454.082z"
          fill={color}  
        />
      </g>
      <defs>
        <clipPath id="clip0_24_35214">
          <path
            fill={color}
            transform="rotate(90 9.75 10.25)"
            d="M0 0H20V20H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftSvgComponent;
