import React from "react";

interface ImageOverlayProps {
  mainImageSrc?: string;
  overlayImageSrc?: string;
  overlaySize?: number; // Tailwind size, e.g., 16 for w-16 h-16
}

const ImageOverlay: React.FC<ImageOverlayProps> = ({
  mainImageSrc,
  overlayImageSrc,
  overlaySize = 4    ,
}) => {
  return (
    <span className="relative w-[24px] h-[24px] rounded-full">
      {/* Main Image */}
      <img
        src={mainImageSrc}
        alt="Main Image"
        className="object-contain w-24 h-full rounded-full"
      />
      {/* Bottom-Right Overlay Image */}
      <img
        src={overlayImageSrc}
        alt="Overlay Image"
        className={`absolute bottom-0 right-0 w-[12px] bg-white h-[12px] object-cover rounded-[7px] shadow-md`}
      />
    </span>
  );
};

export default ImageOverlay;
