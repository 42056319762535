import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";
import { GoArrowLeft } from "react-icons/go";
import {
  ExplorerTabs,
  UserDetailsResponse,
  walletType,
  walletType2,
} from "./hooks/types";
import { useUserDetails, getUserDetails } from "./hooks/helper";
import { EpochFlow, ReferralPageModal } from "./Onboard";
import { resetUser, updateUser } from "../../controller/profile/user";
import { useAppDispatch, useAppSelector } from "../../controller/state/hooks";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useAccount, useDisconnect, useReadContract } from "wagmi";
import { useDisconnect as useThirdwebDisconnect, useActiveWallet } from "thirdweb/react";
import { towneSquareContract } from "../../constants/constants";
import { useCheckUserHasMadladsNFT } from "./hooks";
import { PiSignOutBold } from "react-icons/pi";
import { toggleSolanaConnectWalletModal } from "../../controller/dialog/dialog";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useSolanaWalletProvider } from "./hooks/content";
import { Tooltip } from "react-tooltip";
import { PiCopyBold } from "react-icons/pi";
 
import { AiFillThunderbolt } from "react-icons/ai";
import { useAuth } from "./hooks/Authstate";

interface PrevHeaderProps {
  pubAddress: string | null;
  walletType?: string | null;
  onDisconnect: () => void;
  onConnect?: () => void;
  backbutton?: boolean;
  title: ExplorerTabs | string;
}

const walletIcons: Record<string, string> = {
  [walletType2.Phantom]: "/assets/main_v4/phantom.svg",
  [walletType2.Metamask]: "/assets/main_v4/metamask.svg",
  [walletType2.BackPack]: "/assets/main_v4/backpack.svg",
  [walletType2.Okx]: "/assets/main_v4/Okxicon.svg",
  [walletType2.Haha]: "/assets/main_v4/HahaIcon.svg",
};

const PrevHeader: React.FC<PrevHeaderProps> = ({
  pubAddress,
  walletType,
  onDisconnect,
  onConnect,
  backbutton,
  title,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const walletButtonRef = useRef<HTMLDivElement>(null);
  const [isEpochnOpen, setisEpochnOpen] = useState(false);
  const { disconnect, connectors } = useDisconnect();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setToken } = useAuth();
  const { disconnect: thirdwebDisconnect } = useThirdwebDisconnect();
  const activeWallet = useActiveWallet();

  const location = useLocation();

  // const userDetails = useAppSelector((state) => state.userState.userDetails);
  // const referralCode = userDetails?.data?.user?.referral_code || null;
  // const rankvalue = userDetails?.data?.point?.rank || null;
  const account = useAccount();
  const { solanaAddress, isConnected, disconnectWallet } =
    useSolanaWalletProvider();

  const hasMadladsNFT = useCheckUserHasMadladsNFT(solanaAddress);

  const storedWalletType =
    walletType || sessionStorage.getItem("walletType") || "Unknown";
  const walletIcon = walletIcons[storedWalletType] || null;

  const handleOpenReferralModal = () => {
    setReferralModalOpen(true);
  };

  const handleCloseReferralModal = () => {
    setReferralModalOpen(false);
  };

  const displayAddress = account.address;
  console.log("display Address", displayAddress)


  const truncateAddress = (address: string) =>
    `${address.slice(0, 6)}...${address.slice(-4)}`;

  const copyToClipboard = () => {
    if (userDetails.data?.user.referral_code) {
      navigator.clipboard
        .writeText(userDetails.data?.user.referral_code)
        .then(() => {
          alert("Referral code copied");
        })
        .catch((err) => {
          console.error("Failed to copy text:", err);
        });
    } else {
      console.warn("No referral code available to copy.");
    }
  };

  const handleAccountsChanged = (newAccounts: string[]) => {
    // Handle account changes
  };
  
  const handleChainChanged = (newChainId: string) => {
    // Handle chain changes
  };


  const handleDisconnect = () => {
    const storedWalletType = sessionStorage.getItem("walletType");
  
    // Wallet-specific disconnection logic
    switch (storedWalletType?.toLowerCase()) {
      case "haha":
        if (window.haha) {
          
          window.haha.removeListener("accountsChanged", handleAccountsChanged);
          window.haha.removeListener("chainChanged", handleChainChanged);
          // Reset connection state
          window.haha.request({ method: "wallet_disconnect" });
        }
        break;
      case "okxos":
        if (window.okxwallet) {
          // I use empty request
          window.okxwallet.request({ method: "wallet_disconnect" });
        }
        break;
        case "backpack": 
          if (activeWallet) {
            thirdwebDisconnect(activeWallet);
          }
        break;
      default:
        break;
    }
  
    // General cleanup
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("token");
    setToken(null);
    localStorage.removeItem("token");
    dispatch(resetUser());
    onDisconnect();
    connectors.map((connector) => disconnect({ connector }));
  };



  const userDetails = useUserDetails(
    account.address,
    account.connector?.name.toLowerCase()
  );
  console.log(" the account connection", account.connector?.name)



  const handleCloseModal = () => {
    setisEpochnOpen(false);
  };

  const {
    data: userPrimaryNFT,
    isSuccess,
  }: { data: [string, string] | undefined; isSuccess: boolean } =
    useReadContract({
      ...towneSquareContract,
      functionName: "userPrimaryNFT",
      args: [account.address],
    });

  const handleDropdownClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!account.address) {
      if (onConnect) {
        onConnect();
      }
      return;
    }
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const isWalletButtonClick = walletButtonRef.current?.contains(target);
      const isDropdownClick = dropdownRef.current?.contains(target);

      if (!isWalletButtonClick && !isDropdownClick) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDropdown]);
  return (
    <header
      className={`z-10 flex items-center justify-between md:pt-9 pt-10 px-4 md:mb-10 md:mr-10 pb-4 md:bg-transparent bg-[#1B1B1B]`}
    >
      <div className="block mx-6 mt-2 md:hidden">
        <img
          onClick={() => {
            navigate("/");
          }}
          src="/assets/main_v4/tlogo.svg"
          className="w-[32px] h-[32px]"
          alt="logo"
        />
      </div>

      <div className=" hidden md:flex items-start mt-[13px]">
        {backbutton && (
          <button
            onClick={() => {
              navigate("/onboard", {
                state: { ExplorerTab: "Explore apps" },
              });
            }}
            className="flex items-center justify-center"
            aria-label="Go Back"
          >
            <GoArrowLeft className="text-white w-[40px] h-[40px] p-2 border border-gray-light-3 rounded-md ml-8" />
          </button>
        )}

        <h1 className="ml-8 text-xl font-bold md:text-2xl">{title}</h1>
      </div>

      <div className="md:absolute  md:flex md:items-center md:justify-center md:top-9  md:right-12 md:mr-0 items-center">
        <div className="relative">
          <div
            ref={walletButtonRef}
            onClick={handleDropdownClick}
            className={`flex items-center h-[44px] justify-center text-white border border-[#CCCCCC] gap-x-1 rounded-[32px] cursor-pointer 
        ${account.isConnected ? "w-[189px] px-[16px] py-[10px]" : "py-[10px]"} 
        hover:bg-[#404040] max-md:mx-auto`}
          >
            {account.isConnected ? (
              <div className="flex items-center">
                {walletIcon && (
                  <img
                    src={walletIcon}
                    alt={`${storedWalletType} wallet`}
                    className="w-6 h-6"
                  />
                )}
                <div className="pl-2 pr-4 text-[14px]">
                  {isSuccess && userPrimaryNFT && userPrimaryNFT[1] ? (
                    <div className="flex items-center w-full h-full gap-1">
                      <span className="text-[14px]">
                        {`@${userPrimaryNFT[1]}`}
                      </span>
                      <img
                        src="/assets/main_v4/dbadge.svg"
                        className="z-20 object-contain w-5 h-6"
                      />
                    </div>
                  ) : (
                    account.address && truncateAddress(account.address)
                  )}
                </div>
                <div onClick={handleDropdownClick}>
                  <img src="/assets/main_v4/dropd.svg" />
                </div>
              </div>
            ) : (
              <button
                onClick={() => {
                  if (onConnect) {
                    onConnect();
                    setShowDropdown(false);
                  }
                }}
                className="px-[32px] py-[10px]"
              >
                Connect wallet
              </button>
            )}
          </div>

          {showDropdown && (
            <>
              <div className=" fixed inset-0 bg-black/50 z-40 md:hidden" />
              <div
                ref={dropdownRef}
                className="md:absolute md:top-full md:right-4 mt-2 w-[278px]  bg-[#222222] fixed right-16 rounded-lg shadow-lg text-white py-4 border border-[#CCCCCC] z-50"
                // onMouseEnter={() => setIsHovered(true)}
              >
                <div className="flex flex-col px-6">
                  <div className="flex justify-between">
                    <div className="border border-[#404040] rounded-[6px] p-2 bg-[#0E0E0E] flex-1 w-[115px] h-[70px] px-[12px] py-[10px]">
                      <p className="text-[13px] text-[#AAAAAA]">My Rank</p>
                      <p className="text-[18px] flex items-center">
                        {userDetails.isLoading
                          ? "Loading..."
                          : userDetails.data?.point.rank || "Unavailable"}
                      </p>
                    </div>

                    <div className="border border-[#404040] rounded-[6px] p-2 bg-[#0E0E0E] flex-1 ml-2 w-[115px] h-[70px] px-[12px] py-[10px]">
                      <p className="text-[13px] text-[#AAAAAA]">
                        Referral Code
                      </p>
                      <p className="text-[18px] flex items-center">
                        {userDetails.isLoading
                          ? "Loading..."
                          : userDetails.data?.user.referral_code ||
                            "Unavailable"}
                        {/* <button onClick={copyToClipboard}>
                        <img
                          src={"/assets/main_v4/coppy.svg"}
                          alt="Copy"
                          className="cursor-pointer hover:text-[#9264F8]"
                        />
                      </button> */}
                        <>
                          <Tooltip
                            anchorSelect=".copy-referral-code"
                            className="text-[14px] bg-black border text-white border-[#404040]"
                            place="top"
                            classNameArrow="border-b border-r border-[#404040]"
                          >
                            Copy referral code
                          </Tooltip>
                          <PiCopyBold
                            onClick={copyToClipboard}
                            className="hover:fill-[#9264F8] copy-referral-code font-extrabold"
                            size={18}
                            fill="#FFF"
                          />
                        </>
                      </p>
                    </div>
                  </div>
                  {isConnected ? (
                    <div className="w-[230px] flex items-center justify-between h-[70px] my-3 py-[10px] px-[10px] rounded-md bg-[#0E0E0E] border border-[#404040]">
                      <div>
                        <p className="text-[13px] text-[#AAAAAA]">
                          Solana wallet
                        </p>
                        <div className="flex items-center gap-[6px]">
                          <p className="text-[16px] font-semibold text-[#FFFFFF]">
                            {`${solanaAddress
                              ?.toString()
                              .slice(0, 4)}...${solanaAddress
                              ?.toString()
                              .slice(-3)}`}
                          </p>
                          <>
                            <Tooltip
                              anchorSelect=".disconnect-solana-wallet"
                              className="text-[14px] bg-black border text-white border-[#404040]"
                              place="top"
                              classNameArrow="border-b border-r border-[#404040]"
                            >
                              Disconnect Solana Wallet
                            </Tooltip>
                            <PiSignOutBold
                              className="cursor-pointer disconnect-solana-wallet hover:fill-[#9264F8]"
                              onClick={() => {
                                disconnectWallet();
                              }}
                              size={18}
                              fill="#FFF"
                            />
                          </>
                        </div>
                      </div>
                      <>
                        <Tooltip
                          anchorSelect=".madlads-tooltip"
                          className="text-[14px] flex flex-col w-[221px] bg-black border text-white border-[#404040]"
                          place="top"
                          classNameArrow="border-b border-r border-[#404040]"
                        >
                          {hasMadladsNFT.data ? (
                            <span className="flex flex-col">
                              <span>
                                You are earning
                                <span className="text-[#00EEFD]">
                                  +10% bonus rank{" "}
                                </span>
                              </span>
                              <span>
                                by holding{" "}
                                <span className="font-semibold">Madlads</span>
                              </span>
                            </span>
                          ) : (
                            <span className="flex flex-col">
                              <span>
                                Earn additional
                                <span className="text-[#00EEFD]">
                                  {" "}
                                  +10% bonus rank{" "}
                                </span>
                              </span>
                              <span>
                                by holding{" "}
                                <span className="font-semibold">Madlads</span>
                              </span>
                            </span>
                          )}
                        </Tooltip>
                        <button
                          className={`${
                            hasMadladsNFT.data ? "w-[80px]" : "w-[86px]"
                          } ${
                            hasMadladsNFT.data
                              ? "border-[#00EEFD]"
                              : "border-[#AAA]"
                          } madlads-tooltip border rounded-[38px] h-[28px] flex flex-row justify-evenly gap-[2px] items-center px-[6px]`}
                        >
                          <img
                            src="/assets/main_v4/madlads.svg"
                            className="w-[20px] cursor-custom chogStar-tooltip h-[20px]"
                          />
                          {hasMadladsNFT.data && (
                            <img
                              src="/assets/main_v4/thunder.svg"
                              alt="thunder"
                              className="w-[16px] h-[16px] "
                            />
                          )}
                          {hasMadladsNFT.data ? (
                            <span className="text-[#00EEFD] text-[12px] font-bold">
                              +10%
                            </span>
                          ) : (
                            <span className="text-[12px] text-[#AAAAAA]">
                              No bonus
                            </span>
                          )}
                        </button>
                      </>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        setShowDropdown(false);
                        dispatch(toggleSolanaConnectWalletModal(true));
                      }}
                    >
                      <img
                        alt="click to get madlads rank boost"
                        src="/assets/main_v4/solana_madlads_badge.svg"
                        className="my-3"
                      />
                    </button>
                  )}
                  <div>
                    <img
                      onClick={() => {
                        navigate("/onboard/townesquare-nft", {
                          state: { ExplorerTab: "TownSquare NFT" },
                        });
                      }}
                      className="w-auto h-auto mb-3"
                      src="/assets/main_v4/mintbanner.svg"
                      alt="Click to mint Nad Profile NFT"
                    />
                  </div>

                  <button
                    onClick={handleOpenReferralModal}
                    className="block w-full text-center text-[15px] font-normal mt-4 mb-[16px] rounded-lg"
                  >
                    Apply Referral
                  </button>
                  <button
                    onClick={() => {
                      handleDisconnect();
                      setShowDropdown(false);
                    }}
                    className="block w-full text-center text-[15px] mb-[20px] font-normal rounded-lg"
                  >
                    Disconnect Wallet
                  </button>
                </div>

                <ToastContainer />
              </div>
            </>
          )}
        </div>

        {isMobileNavOpen && (
          <div
            className="fixed inset-0 bg-black/80 z-40 transition-opacity"
            onClick={() => setIsMobileNavOpen(false)}
          />
        )}

        {isMobileNavOpen && (
          <div className="fixed top-0 left-0 w-[288px] h-full bg-[#121212] z-40 flex flex-col items-start px-6 pt-8 pb-6">
            {/* Header Section */}
            <div className="flex items-center justify-end w-full">
              <button
                onClick={() => setIsMobileNavOpen(false)}
                className="fixed top-4 right-4 z-50 text-2xl text-white"
                style={{ transform: "translateY(2px)" }}
              >
                <img src="/assets/main_v4/dcloseicon.svg" />
              </button>
            </div>

            <div className="flex items-center  w-[166px]">
              <Link to="/">
                <img
                  src="/assets/main_v4/theloggo.svg"
                  alt="Townesquare logo"
                />
              </Link>
            </div>
            <img
              src="/assets/main_v4/sidemask.svg"
              alt="Townesquare sidemask"
            />
            <div>
              <button
                onClick={() => setisEpochnOpen(!isEpochnOpen)}
                className="flex items-center justify-between w-[116px]  h-[44px] px-5 py-6 -mt-6 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
              >
                <span className="text-white text-[15px]">Epoch</span>
                <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px]  pr-2 pl-2">
                  1
                </span>
              </button>
            </div>
            <Link to="/onboard/townesquare-nft">
              <img
                className="w-auto h-[70px] my-4 cursor-pointer w-[160px]"
                src="/assets/main_v4/sidelogoo.png"
                alt="Townesquare NFT"
              />
            </Link>

            {/* Navigation Links */}
            <nav className="flex flex-col items-start w-full gap-4 mt-4">
              {[
                {
                  path: "/onboard/ranking",
                  icon: "rankicon.svg",
                  active: "ranksel.svg",
                  label: "Testnet Ranking",
                },
                {
                  path: "/onboard/explore-apps",
                  icon: "Exploreicon.svg",
                  active: "exploresel.svg",
                  label: "Explore Apps",
                },
                // {
                //   path: "/onboard/stake-earn",
                //   icon: "stakeicon.svg",
                //   active: "stakesel.svg",
                //   label: "Stake & Earn",
                // },
                {
                  path: "/onboard/townesquare-nft",
                  icon: "RNNFT.svg",
                  active: "DFNFT.svg",
                  label: "TownSquare NFTs",
                },
                {
                  path: "/onboard/artist-spotlight",
                  icon: "Articon.svg",
                  active: "Ar.svg",
                  label: "Artist Spotlight",
                },
                // {
                //   path: "/onboard/monad-volume-share",
                //   icon: "volume.svg",
                //   active: "volumeSe.svg",
                //   label: "Monad Volume Share",
                // },
              ].map(({ path, icon, active, label }) => {
                const isActive = location.pathname === path;

                return (
                  <Link
                    key={path}
                    to={path}
                    className={`flex items-center gap-3 text-lg w-full py-3 ${
                      isActive ? "text-[#B882FF] font-semibold" : "text-white"
                    }`}
                    onClick={() => setIsMobileNavOpen(false)}
                  >
                    <img
                      src={
                        isActive
                          ? `/assets/main_v4/${active}`
                          : `/assets/main_v4/${icon}`
                      }
                      className="w-6 h-6"
                      alt={`${label} Icon`}
                    />
                    {label}
                  </Link>
                );
              })}
            </nav>
          </div>
        )}

        {isReferralModalOpen && (
          <ReferralPageModal onCancelProcess={handleCloseReferralModal} />
        )}
        {isEpochnOpen && <EpochFlow onCancelProcess={handleCloseModal} />}
      </div>
      <img
        onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
        src="/assets/main_v4/List.svg"
        className="block w-auto h-auto mt-2 md:hidden"
        alt="menu"
      />
    </header>
  );
};

export default PrevHeader;
