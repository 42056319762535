import React from "react";
import ArrowRight from "../assets/main_v4/ArrowRig.svg";

import {
  AiFillStar,
  AiFillThunderbolt,
  AiOutlineArrowRight,
  AiOutlineStar,
} from "react-icons/ai";

interface LoginButtonProps {
  title: string;
  logo: string;
  onClick: () => void;
  showBoost?: boolean;
  recommend?: boolean;
  boost?: number;
  roundedTop?: boolean;
  roundedBottom?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  title,
  logo,
  onClick,
  showBoost,
  recommend,
  boost,
  roundedTop,
  roundedBottom,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex md:h-[58px] md:w-[493px] min-h-[58px] max-h-[128px] h-auto py-4  w-[288px] items-center px-4 -mb-2 bg-[#FFFFFF08] border border-white/40 
      ${roundedTop ? "rounded-t-[8px]" : ""} 
      ${roundedBottom ? "rounded-b-[8px]" : ""}
      ${!roundedTop && !roundedBottom ? "rounded-[1px]" : ""} 
      justify-between hover:bg-[#FFFFFF1A]`}
    >
      <div className="flex items-center gap-2 md:flex-row flex-col">
        <div className="flex flex-row md:flex-row items-center gap-2">
          <img src={logo} className="h-[34px] w-[34px]" alt={title} />
          <p className="text-base font-medium text-white">{title}</p>
        </div>

        {showBoost && (
          <div className="relative group">
            <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[126px]">
              <div className="flex items-center font-bold">
                <AiFillThunderbolt size={12} color="#00EEFD" />
                <p className="text-[#00EEFD] text-[11px]">+{boost}%</p>
              </div>
              <p className="text-[11px]">rank bonus</p>
            </div>
            {/* Tooltip */}
            <div className="relative group">
              <div className="absolute h-[35px] top-[-50px] border border-[#FFFFFF] w-[230px] hidden group-hover:flex bg-black text-[#FFFFFF] text-[11px] p-2 rounded-[4px]">
                Points are awarded solely for transactions.
                <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 border-[6px] border-transparent border-t-[#FFFFFF]"></div>
              </div>
            </div>
          </div>
        )}
        {recommend && (
          <div className="border flex border-[#FFFFFF] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[136px]">
            <div className="flex items-center font-bold">
              <AiFillStar size={13} color="#FFFFFF" />
            </div>
            <p className="text-[11px]">Recommended wallet</p>
          </div>
        )}
      </div>
      <div>
        <img src="/assets/main_v4/ArrowRig.svg" className="px-2" alt={title} />
      </div>
    </button>
  );
};

export default LoginButton;
