import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useWallet } from "./hooks/content";
import LoginButton from "./LoginBut";
import { getUserDetails, getWalletIcon } from "./hooks/helper";
import { createThirdwebClient } from "thirdweb";
import { createWallet, injectedProvider } from "thirdweb/wallets";

import {
  checkUserExist,
  signInWithWallet,
  signUpWithWallet,
} from "./hooks/helper";
import { walletType } from "./hooks/types";
import { ReferralPageModal } from "./Onboard";
import { useAppDispatch } from "../../controller/state/hooks";
import { updateUser } from "../../controller/profile/user";
import { injected, useConnect } from "wagmi";
import { metaMask } from "wagmi/connectors";
import { toggleSolanaConnectWalletModal } from "../../controller/dialog/dialog";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./hooks/Authstate";

export const handleWalletConnection = async (
  address: string,
  walletType: string,
  setPubAddress: (addr: string) => void,
  setConnectedWallet: (type: string) => void,
  session: Storage
) => {
  setPubAddress(address);
  session.setItem("pubAddress", address);
  setConnectedWallet(walletType);
};
interface TsloginProps {
  onCancelProcess: () => void;
}

const client = createThirdwebClient({
  clientId: "1fcdf055b46929e72b7f04b7caad1230",
});
const Tslogin: React.FC<TsloginProps> = ({ onCancelProcess }) => {
  const { pubAddress, setPubAddress, setConnectedWallet } = useWallet();
  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const [walletNotDetected, setWalletNotDetected] = useState(false);
  const [skip, setSkip] = useState(false);
  const [walletTypeState, setWalletTypeState] = useState<string | null>(null);
  const { connect } = useConnect();
  const [forceUpdate, setForceUpdate] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setToken } = useAuth();
  const wallet = createWallet("app.backpack");
  const queryClient = useQueryClient();

  const handleOpenReferralModal = () => {
    setReferralModalOpen(true);
  };

  const handleCloseReferralModal = () => {
    setReferralModalOpen(false);
  };

  const ConnectBackPack = async () => {
    try {
      if (injectedProvider("app.backpack")) {
        console.log("Backpack wallet detected, attempting to connect...");
        const connection = await wallet.connect({ client });
        console.log("Connected to Backpack wallet successfully!");
        setPubAddress(connection.address);
        sessionStorage.setItem("pubAddress", connection.address);
        sessionStorage.setItem("walletType", "Backpack");
        console.log("Connected account address:", connection.address);
        setConnectedWallet(walletType.BackPack);

        const userRecord = await checkUserExist({
          accountAddress: connection.address,
          walletType: walletType.BackPack,
        });
        if (userRecord.statusCode === 201) {
          if (userRecord.data) {
            console.log("User exists, logging in...");
            const res = await signInWithWallet({
              accountAddress: connection.address,
              walletType: walletType.BackPack,
            });
            sessionStorage.setItem("token", res.data.token);
            setToken(res.data.token);
            localStorage.setItem("token", res.data.token);
            console.log("Logged in successfully!");
            const userDetails = await getUserDetails(
              connection.address,
              walletType.BackPack
            );
            dispatch(updateUser(userDetails));
          } else {
            console.log("User does not exist, signing up...");
            const res = await signUpWithWallet({
              accountAddress: connection.address,
              walletType: walletType.BackPack,
            });
            sessionStorage.setItem("token", res.data.token);
            localStorage.setItem("token", res.data.token);
            console.log("Account created successfully!");
            setReferralModalOpen(true);
            const userDetails = await getUserDetails(
              connection.address,
              walletType.BackPack
            );
            dispatch(updateUser(userDetails));
            if (!skip) {
              dispatch(toggleSolanaConnectWalletModal(true));
            }
          }
        } else if (userRecord.statusCode === 404) {
          console.log("User does not exist, signing up...");
          const res = await signUpWithWallet({
            accountAddress: connection.address,
            walletType: walletType.BackPack,
          });
          sessionStorage.setItem("token", res.data.token);
          setToken(res.data.token);
          localStorage.setItem("token", res.data.token);
          setToken(res.data.token);
          console.log("Account created successfully!");
          const userDetails = await getUserDetails(
            connection.address,
            walletType.BackPack
          );
          queryClient.invalidateQueries({ 
            queryKey: ['userReferral'] 
          });
          dispatch(updateUser(userDetails));
          setReferralModalOpen(true); // Open the referral modal here
          if (!skip) {
            dispatch(toggleSolanaConnectWalletModal(true));
          }
        } else {
          console.error("Unexpected response:", userRecord);
          console.error(
            "An unexpected error occurred while checking user existence."
          );
        }
      } else {
        console.error("Backpack wallet not detected");

        const userConfirmed = window.confirm(
          "Backpack wallet not detected. Click OK to install it."
        );

        if (userConfirmed) {
          window.open("https://backpack.app/", "_blank");
        }
      }
    } catch (error) {
      console.error("Failed to connect to Backpack wallet:", error);
      alert("Failed to connect to Backpack wallet. Please try again.");
    }
  };

   
  const checkMetamaskIsInstalled = () => {
    return typeof window.ethereum !== "undefined";
  };

  const connectMetamask = async () => {
    console.log("==== connectMetamask ====");
    if (!checkMetamaskIsInstalled()) {
      alert("Please install MetaMask");
      return;
    }
    try {
      connect(
        {
          connector: metaMask(),
        },
        {
          onSuccess: async (data, variables, context) => {
            if (data.accounts && data.accounts.length > 0) {
              const accountAddress = data.accounts[0];
              setPubAddress(accountAddress);
              sessionStorage.setItem("pubAddress", accountAddress);
              setConnectedWallet(walletType.Metamask);

              console.log("Connected account:", accountAddress);
              // alert(`Connected account: ${accountAddress}`);
              sessionStorage.setItem("walletType", "metamask");

              // Check if user exists
              const userRecord = await checkUserExist({
                accountAddress,
                walletType: walletType.Metamask,
              });

              if (userRecord.statusCode === 201 && userRecord.data) {
                // User exists, log in
                console.log("User exists, logging in...");
                const res = await signInWithWallet({
                  accountAddress,
                  walletType: "metamask",
                });
                sessionStorage.setItem("token", res.data.token);
                setToken(res.data.token);
                localStorage.setItem("token", res.data.token);
                console.log("Logged in successfully!");

                const userDetails = await getUserDetails(
                  accountAddress,
                  walletType.Metamask
                );
                onCancelProcess();
                dispatch(updateUser(userDetails));
                if (!skip) {
                  dispatch(toggleSolanaConnectWalletModal(true));
                }
              } else {
                // User does not exist, sign up
                console.log("User does not exist, signing up...");
                const res = await signUpWithWallet({
                  accountAddress,
                  walletType: walletType.Metamask,
                });
                sessionStorage.setItem("token", res.data.token);
                setToken(res.data.token);
                localStorage.setItem("token", res.data.token);
                console.log("Account created successfully!");
                const userDetails = await getUserDetails(
                  accountAddress,
                  walletType.Metamask
                );
                onCancelProcess();
                dispatch(updateUser(userDetails));
                handleOpenReferralModal();
                if (!skip) {
                  dispatch(toggleSolanaConnectWalletModal(true));
                }
              }
              queryClient.invalidateQueries({ 
                queryKey: ['userReferral'] 
              });

              queryClient.invalidateQueries({
                queryKey: ["getFetchUserDetails", accountAddress],
              });
            } else {
              console.warn("No accounts found");
            }
          },
        }
      );
    } catch (err) {
      console.log("Failed to connect wallet", err);
      console.error("Failed to connect to metamask wallet. Please try again.");
    }
  };

  const connectToOKXWallet = async () => {
    console.log("==== OKX Wallet Connection ====");
  
    if (!window?.okxwallet) {
      const install = window.confirm("OKX Wallet not found. Install now?");
      if (install) window.open("https://www.okx.com/web3", "_blank");
      return;
    }
  
    try {
      console.log("Forcing wallet connection request...");
      
      // 🔹 Ensure we force wallet reconnection every time  
      const accounts = await window.okxwallet.request({
        method: "eth_requestAccounts",
      });
  
      if (!accounts || accounts.length === 0) {
        console.warn("No accounts authorized");
        return;
      }
  
      const accountAddress = accounts[0];
      console.log("Connected account:", accountAddress);
  
      // 🔹 Set wallet address and trigger state update
      setPubAddress(accountAddress);
      sessionStorage.setItem("pubAddress", accountAddress);
      sessionStorage.setItem("walletType", "okxos");
      
      setConnectedWallet(walletType.Okx);
  
      console.log("Updated sessionStorage:", sessionStorage);
      
      console.log("Checking if user exists...");
      const userRecord = await checkUserExist({
        accountAddress,
        walletType: walletType.Okx,
      });
  
      if (userRecord.statusCode === 201 && userRecord.data) {
        console.log("User found, signing in...");
        const res = await signInWithWallet({
          accountAddress,
          walletType: walletType.Okx,
        });

        sessionStorage.setItem("token", res.data.token);
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        console.log("User signed in successfully");
        const userDetails = await getUserDetails(accountAddress, walletType.Okx);
        dispatch(updateUser(userDetails));
      } else {
        console.log("New user detected, signing up...");
        const res = await signUpWithWallet({
          accountAddress,
          walletType: walletType.Okx,
        });
        sessionStorage.setItem("token", res.data.token);
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        console.log("User signed up successfully");
      
        setToken(res.data.token);
        console.log("User log in again up successfully");
        setToken(res.data.token);
        console.log("the token is" , setToken)
        const userDetails = await getUserDetails(accountAddress, walletType.Okx);
        dispatch(updateUser(userDetails));
        queryClient.invalidateQueries({ queryKey: ['userReferral'] })
        queryClient.invalidateQueries({
        queryKey: ["getFetchUserDetails", accountAddress],
        });
        setReferralModalOpen(true);
      }
      onCancelProcess(); 
      dispatch(toggleSolanaConnectWalletModal(false));
      queryClient.invalidateQueries({ 
        queryKey: ['userReferral'] 
      });
      
      console.log("the token is" , setToken)
      console.log("OKX connection flow completed successfully");      
      queryClient.invalidateQueries({
        queryKey: ["getFetchUserDetails", accountAddress],
      });
  
    
      setForceUpdate(prev => !prev); 
  
    } catch (error: any) {
      console.error("OKX Connection Error:", {
        errorCode: error.code,
        message: error.message,
        stack: error.stack
      });
  
      if (error.code === 4001) {
        console.warn("User rejected connection request");
        alert("Connection request canceled by user");
      } else {
        alert("Failed to connect to OKX Wallet. Please try again.");
      }
    }
  }; 


  const getPhantomProvider = (): any | undefined => {
    if ("phantom" in window) {
      console.log("Phantom wallet detected");
      const anyWindow: any = window;
      const provider = anyWindow.phantom;

      return provider;
    } else {
      alert("Please install Phantom wallet");
      window.open("https://phantom.app/", "_blank");
    }
  };

  const connectPhantomWallet = async () => {
    const provider = getPhantomProvider();
    if (!provider) {
      return;
    }

    connect(
      {
        connector: injected({
          target: "phantom",
        }),
      },
      {
        onSuccess: async (data, variables, context) => {
          try {
            if (data.accounts && data.accounts.length > 0) {
              const accountAddress = data.accounts[0];
              setPubAddress(accountAddress);
              sessionStorage.setItem("pubAddress", accountAddress);
              console.log("Connected account:", accountAddress);
              // alert(`Connected account: ${accountAddress}`);
              setConnectedWallet(walletType.Phantom);
              sessionStorage.setItem("walletType", "Phantom");
              console.log("walletin :", walletType.Phantom);

              // Check if user exists
              const userRecord = await checkUserExist({
                accountAddress,
                walletType: walletType.Phantom,
              });
              if (userRecord.statusCode === 201 && userRecord.data) {
                // User exists, log in
                console.log("User exists, logging in...");
                const res = await signInWithWallet({
                  accountAddress,
                  walletType: "phantom",
                });
                console.log("Logged in successfully!");
                setToken(res.data.token);
                sessionStorage.setItem("token", res.data.token);
                localStorage.setItem("token", res.data.token);
                const userDetails = await getUserDetails(
                  accountAddress,
                  walletType.Phantom
                );
                queryClient.invalidateQueries({ 
                  queryKey: ['userReferral'] 
                });
                onCancelProcess();
                dispatch(updateUser(userDetails));
                if (!skip) {
                  dispatch(toggleSolanaConnectWalletModal(true));
                }
              } else {
                // User does not exist, sign up
                console.log("User does not exist, signing up...");
                const res = await signUpWithWallet({
                  accountAddress,
                  walletType: walletType.Phantom,
                });
                sessionStorage.setItem("token", res.data.token);
                setToken(res.data.token);
                localStorage.setItem("token", res.data.token);
                console.log("Account created successfully!");
                onCancelProcess();
                handleOpenReferralModal();
                const userDetails = await getUserDetails(
                  accountAddress,
                  walletType.Phantom
                );
                dispatch(updateUser(userDetails));
                if (!skip) {
                  dispatch(toggleSolanaConnectWalletModal(true));
                }
              }
              queryClient.invalidateQueries({ 
                queryKey: ['userReferral'] 
              });

              queryClient.invalidateQueries({
                queryKey: [
                  "getFetchUserDetails",
                  accountAddress,
                ],
              });
            } else {
              console.warn("No accounts found");
            }
          } catch (err) {
            console.log("Failed to connect wallet", err);
            console.error(
              "Failed to connect to Phantom wallet. Please try again."
            );
          }
        },
        onError(error, variables, context) {
          console.error("Failed to connect Phantom wallet", error);
          console.error(
            "Failed to connect to Phantom wallet. Please try again."
          );
        },
      }
    );
  };

  const connectToHaha = async () => {
    try {
      console.log("==== Connecting to Haha Wallet ====");

      // Check if HaHa wallet is installed
      if (!window.haha) {
        window.open(
          "https://chromewebstore.google.com/detail/haha-wallet/andhndehpcjpmneneealacgnmealilal",
          "_blank"
        );
        throw new Error("Please install HaHa Wallet extension first");
      }

      // Request accounts directly from the provider
      const accounts = await window.haha.request({
        method: "eth_requestAccounts"
      });

      if (!accounts || accounts.length === 0) {
        throw new Error("No accounts found");
      }

      const address = accounts[0];
      console.log("haha Connected Address:", address);

      // Store wallet details
      setPubAddress(address);
      sessionStorage.setItem("pubAddress", address);
    
      sessionStorage.setItem("walletType", "haha");
      setConnectedWallet(walletType.Haha);

      // Check if user exists
      console.log("Checking if user exists...");

      const userRecord = await checkUserExist({
        accountAddress: address,
        walletType: walletType.Haha,
      });

      if (userRecord.statusCode === 201 && userRecord.data) {
        console.log("User exists, logging in...");
        const res = await signInWithWallet({
          accountAddress: address,
          walletType: walletType.Haha,
        });

        sessionStorage.setItem("token", res.data.token);
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        console.log("Logged in successfully!");

        const userDetails = await getUserDetails(address, walletType.Haha);
        dispatch(updateUser(userDetails));
      } else {
        console.log("User does not exist, signing up...");
        const res = await signUpWithWallet({
          accountAddress: address,
          walletType: walletType.Haha,
        });

        sessionStorage.setItem("token", res.data.token);
        setToken(res.data.token);
        localStorage.setItem("token", res.data.token);
        console.log("Account created successfully!");

        const userDetails = await getUserDetails(address, walletType.Haha);
        dispatch(updateUser(userDetails));
        queryClient.invalidateQueries({ 
          queryKey: ['userReferral'] 
        });
        
      }

      // Set up event listeners
      window.haha.on("accountsChanged", (newAccounts: string[]) => {
        if (newAccounts.length === 0) {
        
          setPubAddress(null);
          setConnectedWallet(null);
          sessionStorage.removeItem("pubAddress");
          sessionStorage.removeItem("walletType");
          sessionStorage.removeItem("token");
          localStorage.removeItem("token");
        } else {
          setPubAddress(newAccounts[0]);
        }
      });

      window.haha.on("chainChanged", () => {
        
        window.location.reload();
      });

    } catch (error) {
      console.error("Failed to connect to Haha Wallet:", error);
      alert("Failed to connect to Haha Wallet. Please try again.");
    }
  };


  const disconnectWallet = () => {
    setPubAddress(null);
    sessionStorage.removeItem("pubAddress");
  };

  useEffect(() => {
    if (pubAddress) {
      dispatch(toggleSolanaConnectWalletModal(false));
    }
  }, [pubAddress, dispatch]);

  useEffect(() => {
    const storedAddress = sessionStorage.getItem("pubAddress");
    if (storedAddress) {
      setPubAddress(storedAddress);

      const storedWalletType = sessionStorage.getItem("walletType") as
        | "metamask"
        | "phantom"
        | "backpack"
        | "okxos"
        | "haha"
        | null;

      if (storedWalletType) {
        console.log("Stored Wallet Type:", storedWalletType);
        setConnectedWallet(storedWalletType);
      } else {
        setConnectedWallet(null);
      }

      if (storedWalletType === "metamask" && checkMetamaskIsInstalled()) {
        connectMetamask();
      } else if (storedWalletType === "phantom" && getPhantomProvider()) {
        connectPhantomWallet();
      } else if (storedWalletType === "backpack") {
        ConnectBackPack();
      }
    }
  }, []);

  const updateCookie = () => {
    const dontShowAgain = Cookies.get("dontShowAgain");
    console.log("dontShowAgain", dontShowAgain);
    if (dontShowAgain === "true") {
      setSkip(true);
    }
  };

  useEffect(() => {
    updateCookie();
  }, []);

  return (
    <>
      {walletNotDetected && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg text-center">
            <p className="text-lg font-semibold mb-4">Wallet not detected</p>
            <button
              onClick={() => {
                window.open("https://backpack.app/", "_blank");
                setWalletNotDetected(false);
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              OK
            </button>
          </div>
        </div>
      )}

      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90">
        <div className="relative flex flex-col items-center bg-[#222222] rounded-[16px] p-6 md:w-[557px] w-[320px] h-[622px] md:h-[512px] md:mt-[64px] border border-[#FFFFFF66] z-60">
          <button
            className="absolute top-4 right-5 hover:bg-black"
            onClick={onCancelProcess}
          >
            <img
              src="/assets/main_v4/clos.svg"
              className="px-2 "
              alt={"close"}
            />
          </button>
          <p className="py-5 mb-6 text-[23px] text-center">
            Connect wallet to continue
          </p>
          <div className="flex flex-col gap-2 ">
            <LoginButton
              title="Phantom"
              showBoost={true}
              roundedTop={true}
              recommend={true}
              boost={40}
              logo={getWalletIcon("phantom")}
              onClick={connectPhantomWallet}
            />

            <LoginButton
              title=" Backpack"
              logo={getWalletIcon("backpack")}
              showBoost={true}
              recommend={true}
              boost={40}
              onClick={ConnectBackPack}
            />
            <LoginButton
              title="OKX Wallet"
              logo={"/assets/main_v4/Okxicon.svg"}
              showBoost={true}
              recommend={true}
              boost={40}
              onClick={connectToOKXWallet}
            />
            <LoginButton
              title="HaHa Wallet"
              logo={"/assets/main_v4/HahaIcon.svg"}
              showBoost={true}
              recommend={true}
              boost={40}
              onClick={connectToHaha}
            />
            <LoginButton
              title="MetaMask"
              logo={getWalletIcon("metamask")}
              roundedBottom={true}
              onClick={connectMetamask}
            />
          </div>

          <div className="md:w-[490px] h-[1px] md:mt-[36px] bg-[#404040] w-[288px] mt-7">
            {" "}
          </div>
          <div className="flex items-center justify-center mt-2 md:mt-7">
            <p className="text-sm font-medium text-[#AAA] px-2">Powered by </p>
            <img
              src="/assets/main_v3/ts-logo.png"
              alt="Townesquare Logo"
              className="h-[19px] w-[95px]"
            />
          </div>
        </div>
        {isReferralModalOpen && (
          <ReferralPageModal onCancelProcess={handleCloseReferralModal} />
        )}
      </div>
    </>
  );
};

export default Tslogin;
