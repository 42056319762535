import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useGetAllOnboardingApps, useGetAllProtocols } from "./hooks/helper";
import Tslogin from "./Tsconnect";
import PrevHeader from "./Header";
import { useWallet } from "./hooks/content";
import { EpochFlow } from "./Onboard";
import ExploreDApp from "./Exploredapp";
import Ranking from "./Ranking";
import { AiFillThunderbolt } from "react-icons/ai";
import { ExplorerTabs } from "./hooks/types";
import TownesquareNFT from "./TownesquareNFT";
import StakeAndEarn from "./StakeAndEarn";
import ArtistPage from "./Artist";
import MonadVolume from "./MonadVolume";
import SvgExploreComponent from "../../assets/SVG/Explore1";
import SvgRankComponent from "../../assets/SVG/rank5";

const ExploreView = () => {
  const { dappId } = useParams<{ dappId: string }>();
  const location = useLocation();
  const { state } = location;
  const { name, description, logo, networkUrls, networks } = state as {
    name: string;
    description: string;
    logo: string;
    networkUrls: string[];
    networks: string[];
  };
  console.log("here: ", networks);
  const onboardingAppsData = useGetAllOnboardingApps(dappId || "");
  const onboardingApps = (onboardingAppsData.data || []).filter(
    (app) => app.is_active && !app.is_archived
  );
  //console.log("Fetched onboarding apps:", onboardingApps);
  const [isEpochnOpen, setisEpochnOpen] = useState(false);
  const { tab } = useParams();
  const { pubAddress, setPubAddress, connectedWallet } = useWallet();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ExplorerTabs | string>(
    "Explore apps"
  );

  const handleOConnect = () => {
    setShowLoginModal(true);
  };

  const handleranking = () => {};

  const handleOfConnect = () => {
    setShowLoginModal(false);
  };

  const handleCloseModal = () => {
    setisEpochnOpen(false);
  };

  const disconnectWallet = () => {
    setPubAddress(null);
    sessionStorage.removeItem("walletType");
    sessionStorage.removeItem("userDetails");
    sessionStorage.removeItem("pubAddress");
  };
  useEffect(() => {
    if (pubAddress) {
      setShowLoginModal(false);
    } else {
      setShowLoginModal(false);
    }
  }, [pubAddress]);

  return (
    <div className="flex min-h-screen text-white bg-black">
      {/* Sidebar */}
      <aside className="w-[244px] z-30 fixed h-screen bg-[#121212] p-[22px] hidden md:flex flex-col justify-between">
        <div>
          <div className="flex items-center mt-4 w-[166px]">
            <Link to="/">
              <img src="/assets/main_v4/theloggo.svg" alt="Townesquare logo" />
            </Link>
          </div>
          <img src="/assets/main_v4/sidemask.svg" alt="Townesquare sidemask" />
          <div className="">
            <button
              onClick={() => setisEpochnOpen(!isEpochnOpen)}
              className="flex items-center justify-between w-[126px] h-[44px] px-8 py-6  -mt-6 gap-2 rounded-full border border-[#B882FF] text-sm font-medium bg-[#1a1a1a]"
            >
              <span className="text-white text-[15px]">Epoch</span>
              <span className="text-white bg-[#9264F8] rounded w-[20px] h-[20px]  pr-2 pl-2">
                1
              </span>
            </button>
          </div>

          <Link to="/onboard/townesquare-nft">
            <img
              className="w-auto h-auto my-8 cursor-pointer"
              src="/assets/main_v4/sidelogoo.png"
              alt="Townesquare NFT"
            />
          </Link>

          <nav>
            <ul className="flex flex-col gap-[32px] text-sm font-bold">
              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "ranking" ? "text-purple-400" : ""
                }`}
                onClick={() => {
                  setActiveTab("Ranking");
                  sessionStorage.setItem("ExplorerTab", "Ranking");
                }}
              >
                <SvgRankComponent
                  fill={activeTab === "ranking" ? "#B882FF" : "white"}
                />
                Testnet Ranking
              </li>

              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  tab === "explore-apps" ? "text-purple-400" : "text-purple-400"
                }`}
                onClick={() => {
                  setActiveTab("Explore apps");
                  sessionStorage.setItem("ExplorerTab", "Explore apps");
                }}
              >
                <SvgExploreComponent
                  fill={activeTab === "Explore apps" ? "#B882FF" : "#B882FF"}
                />
                Explore apps
              </li>

              <li
                className={`flex items-center cursor-pointer gap-3 text-[15px] ${
                  activeTab === "townesquare-nft" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/townesquare-nft"
                  className="flex items-center gap-3"
                >
                  {/* Preload images */}
                  <link
                    rel="preload"
                    href="/assets/main_v4/DFNFT.svg"
                    as="image"
                  />
                  <link
                    rel="preload"
                    href="/assets/main_v4/RNNFT.svg"
                    as="image"
                  />
                  <img
                    src={
                      activeTab === "townesquare-nft"
                        ? "/assets/main_v4/DFNFT.svg"
                        : "/assets/main_v4/RNNFT.svg"
                    }
                    alt="NFT Icon"
                  />
                  TownSquare NFTs
                </Link>
              </li>

              <li
                className={`flex items-center gap-3 cursor-pointer text-[15px] ${
                  activeTab === "artist-spotlight" ? "text-purple-400" : ""
                }`}
              >
                <Link
                  to="/onboard/artist-spotlight"
                  className="flex items-center gap-3"
                >
                  <link
                    rel="preload"
                    href="/assets/main_v4/Ar.svg"
                    as="image"
                  />
                  <link
                    rel="preload"
                    href="/assets/main_v4/Articon.svg"
                    as="image"
                  />
                  <img
                    src={
                      activeTab === "artist-spotlight"
                        ? "/assets/main_v4/Ar.svg"
                        : "/assets/main_v4/Articon.svg"
                    }
                    className="w-6 h-6"
                    alt="aRT Icon"
                  />
                  Artist Spotlight
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 md:ml-[240px] min-h-screen  w-[calc(100vw-240px)]">
        {activeTab === "Ranking" && (
          <>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <Ranking />
          </>
        )}

        {activeTab == "Stake & Earn" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div
              className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"
              style={{
                backgroundImage: "url(/assets/main_v4/StakeAndEarnBg.svg)",
                backgroundSize: "contain",
              }}
            ></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title="Stake & Earn"
            />
            <StakeAndEarn onConnect={handleOConnect} />
          </div>
        )}

        {activeTab == "TownSquare NFT" && (
          <div className="relative bg-no-repeat bg-contain bg-[radial-gradient(circle,_rgba(14,15,31,1)_30%,_rgba(0,0,0,1)_100%)] ">
            <div className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <TownesquareNFT onConnect={handleOConnect} />
          </div>
        )}

        {activeTab == "Artist spotlight" && (
          <div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <ArtistPage />
          </div>
        )}

        {activeTab == "Monad Volume Share" && (
          <div className="relative bg-black bg-no-repeat bg-contain ">
            <div className="absolute w-[80%] -left-1/4 h-[80%] bg-no-repeat bg-contain"></div>
            <PrevHeader
              pubAddress={pubAddress}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={activeTab}
            />
            <MonadVolume />
          </div>
        )}

        {activeTab === "Explore apps" && (
          <>
            <PrevHeader
              pubAddress={pubAddress}
              backbutton={true}
              onDisconnect={disconnectWallet}
              onConnect={handleOConnect}
              title={`${name} Onboarding dApps`}
            />
            <div className="px-4 text-white bg-black md:px-8">
            <div className="md:hidden w-full flex">
                <button
                  onClick={() => {
                    navigate("/onboard", {
                      state: { ExplorerTab: "Explore apps" },
                    });
                  }}
                  className="flex items-center justify-center -ml-6 "
                  aria-label="Go Back"
                >
                  <GoArrowLeft className="text-white w-[40px] h-[40px] p-2 border border-gray-light-3 rounded-md ml-8" />
                </button>

                <span className="block text-[23px] font-semibold mb-8 mt-4 ml-4 text-start">
                  {`${name} Onboarding dApps`}
                </span>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={logo}
                  alt={name}
                  className="w-[80px] h-[80px] rounded-full border border-white/40 object-cover"
                />
                <div>
                  <p className="text-[29px]">{name}</p>
                  <div className="flex flex-row justify-between">
                    {networks?.map((network: any, i) => (
                      <div
                        key={i}
                        className="flex items-center justify-start gap-1 border rounded-[30px] px-2 py-1 border-gray-light-3 mr-2 border-[#666666]"
                      >
                        <img src={network.logo} className="w-[14px] h-[14px]" />
                        <p className="text-white text-[12px]">
                          {network.chain}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <p className="mb-6 text-base text-[14px] text-gray-light2 mt-6  md:w-[680px]">
                {description}
              </p>

              {/* 3 Box for Swapping, Staking, Lending */}
              <div className="flex flex-wrap gap-[16px] mb-6">
                {onboardingApps.map((app, index) => (
                   <div
                   key={index}
                   className="py-8 px-6 w-full  md:w-[290px] flex flex-col h-[342px] bg-[#222222] border-[#666666] border rounded-lg"
                 >
                    <div className="flex items-center justify-between">
                      <p className="flex text-xl">
                        {app.onboarding_type.charAt(0).toUpperCase() +
                          app.onboarding_type.slice(1)}{" "}
                        app
                      </p>
                      {app.bonus_percentage !== null &&
                        app.bonus_percentage > 0 && (
                          <div className="border flex border-[#00EEFD] items-center justify-center gap-1 rounded-[20px] h-[24px] w-[98px]">
                            <div className="flex items-center font-bold">
                            <img src="/assets/main_v4/Lightning.svg"/>
                              <p className="text-[#00EEFD] text-[12px] pl-1">
                                {" "}
                                {app.bonus_percentage} %{" "}
                              </p>
                            </div>
                            <p className="text-[12px]">bonus</p>
                          </div>
                        )}
                    </div>
                    <div className="flex flex-col mt-[24px] gap-2">
                      <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                        From{" "}
                        <div className="flex items-center gap-x-1">
                          <img
                            src={app.asset_images.source_asset_icon}
                            alt={name}
                            className="w-[16px] h-[16px] object-cover"
                          />
                          <span className="font-medium text-white">
                            {app.asset_images.source_asset_symbol}{" "}
                            <span className="text-[#CCCCCC]">
                              (
                              {app.source_chain.charAt(0).toUpperCase() +
                                app.source_chain.slice(1)}
                              )
                            </span>
                          </span>
                        </div>
                      </p>
                      <p className="text-[15px] text-gray-light flex gap-2 font-medium">
                        To{" "}
                        <div className="flex items-center gap-x-1">
                          <img
                            src={app.asset_images.dest_asset_icon}
                            alt={name}
                            className="w-[16px] h-[16px] object-cover"
                          />
                          <span className="font-medium text-white">
                            {app.asset_images.dest_asset_symbol}{" "}
                            <span className="text-[#CCCCCC]">
                              (
                              {app.dest_chain.charAt(0).toUpperCase() +
                                app.dest_chain.slice(1)}
                              )
                            </span>
                          </span>
                        </div>
                      </p>
                    </div>
                    <p className="text-[15px] md:mt-10 mt-8 font-medium">
                      Onboarding link
                    </p>
                    <div className="rounded-[40px] mt-[8px] px-4 py-[12px] bg-[#121212] h-[44px] border flex items-center justify-between border-gray-light2">
                      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                        {app.generate_link}
                      </p>
                      <img
                        src="/assets/main_v4/minicopy.svg"
                        alt={app.title || ""}
                        className="w-[20px] h-[20px] object-cover cursor-pointer"
                        onClick={() =>
                          navigator.clipboard.writeText(app.generate_link || "")
                        }
                      />
                    </div>
                    <div className="flex items-center justify-center mt-6">
                      <a
                        href={app.generate_link || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                         className="w-full md:w-[180px]"
                      >
                         <button className="rounded-full md:w-[180px] w-full  text-base font-medium h-[40px] bg-primary-default hover:bg-[#9F71FF]">
                          {app.onboarding_type === "staking"
                            ? "Stake now"
                            : app.onboarding_type === "swapping"
                            ? "Swap now"
                            : app.onboarding_type === "lending"
                            ? "Lend now"
                            : ""}
                        </button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </main>
      {showLoginModal && <Tslogin onCancelProcess={handleOfConnect} />}
      {isEpochnOpen && <EpochFlow onCancelProcess={handleCloseModal} />}
    </div>
  );
};

export default ExploreView;
