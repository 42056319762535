import React, { useState, useEffect, useMemo } from "react";
import {
  Field,
  Input,
  Label,
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";
import ImageOverlay from "./ImageOverlay";
import aporiLogo from "../../assets/V4/apori.svg";
import { RiArrowDownSFill, RiErrorWarningFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";
import {
  useAggregator,
  useSolanaWalletProvider,
  useWallet,
} from "./hooks/content";
import { OutputTokenModal } from "./OutputTokenModal";
import {
  getPendingTransaction,
  getStakeAggregatorTxn,
  getWalletBonus,
  getWalletIcon,
  isBridgeTxnType,
  updateTransActivity,
  isSwapTxnType,
  isStakeTxnType,
  isLendTxnType,
  fetchMonadRate,
  getProtocolWebsite,
} from "./hooks/helper";
import InitializationModal from "./InitialiazationModal";
import { Tooltip } from "react-tooltip";
import { capitalize, set } from "lodash";
import { InputTokenModal } from "./InputTokenModal";
import { useDebouncedMutation } from "./hooks/useDebounceMutation";
import {
  BridgeTxnType,
  GasFeeType,
  StakeTxnType,
  ApprovalTxnType,
  SwapTxnType,
} from "./hooks/types";
import {
  useAccount,
  useBalance,
  useChainId,
  useReadContract,
  useReadContracts,
  useSendTransaction,
  useSwitchChain,
} from "wagmi";
import { HiArrowSmallRight } from "react-icons/hi2";
import { useCheckUserHasMadladsNFT, useFetchTransactionInfo } from "./hooks";
import {
  erc20Abi,
  formatEther,
  formatUnits,
  hexToNumber,
  parseEther,
} from "viem";
import {
  lilChogstarsContract,
  towneSquareContract,
} from "../../constants/constants";

type Token = {
  tokenLogo: string;
  chainLogo: string;
  tokenSymbol: string;
};

type OutputToken = {
  tokenLogo: string;
  chainLogo: string;
  tokenSymbol: string;
  apy: number;
  protocolName: string;
};

const StakeAndEarn = ({ onConnect }: { onConnect?: () => void }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputAmount, setInputAmount] = useState<string>("");
  const { pubAddress, setPubAddress, connectedWallet } = useWallet();
  const [isTxnInitialized, setIsTxnInitialized] = useState(false);
  const [progress, setProgress] = useState(0);

  const [currentStage, setCurrentStage] = useState(0);
  const { outputToken, inputToken } = useAggregator();
  const [outputAmount, setOutputAmount] = useState<string>("");
  const [gasFee, setGasFee] = useState<GasFeeType[]>([]);
  const [txnPayload, setTxnPayload] = useState<
    (BridgeTxnType | ApprovalTxnType | SwapTxnType | StakeTxnType)[]
  >([]);
  const [txnTransaction, setTxnTransaction] =
    useState<
      (BridgeTxnType | ApprovalTxnType | SwapTxnType | StakeTxnType)[]
    >();
  const [noOfProtocol, setNoOfProtocol] = useState<number>(0);
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isRetry, setIsRetry] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputTokenValue, setInputTokenValue] = useState<number | undefined>(0);
  const [outputTokenValue, setOutputTokenValue] = useState<number | undefined>(
    0
  );
  const account = useAccount();
  const { sendTransactionAsync } = useSendTransaction();
  const { switchChainAsync } = useSwitchChain();

  const chainId = useChainId();

  const { debouncedMutate, isPending } = useDebouncedMutation(
    getStakeAggregatorTxn,
    500
  );

  const { solanaAddress } = useSolanaWalletProvider();

  const hasMadLadsNFT = useCheckUserHasMadladsNFT(solanaAddress);

  const { data: stakeAggregatorTxnInfo, isSuccess } = useFetchTransactionInfo({
    inputToken,
    outputToken,
    userAddress: account.address,
  });

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (stakeAggregatorTxnInfo) {
      const filterTxn = stakeAggregatorTxnInfo.txn_function.filter(
        (txn) => txn.type != "approval"
      );

      setTxnTransaction(filterTxn);
      setLastIndex(filterTxn.length - 1);
      setNoOfProtocol(filterTxn.length);
    }
  }, [stakeAggregatorTxnInfo, outputToken, inputToken]);

  useEffect(() => {
    fetchMonadRate(inputToken?.from_asset_address).then((res) => {
      setInputTokenValue(res);
    });

    fetchMonadRate().then((res) => {
      setOutputTokenValue(res);
    });
  }, [inputToken, outputToken]);

  const getModalText = () => {
    if (progress === 100) {
      return `Transaction completed successfully`;
    }
    if (progress >= 0 && progress < 100) {
      return `Transaction initiating ${currentStage} of ${txnPayload.length}`;
    }
    // if (isStarting) {
    //   return `Transaction initiating ${currentStage} of ${totalStages}`;
    // }
    return `Transaction failed at stage ${currentStage}`;
  };

  const balance = useBalance({
    address: account.address,
    token:
      inputToken?.from_asset_address ==
      "0x0000000000000000000000000000000000000000"
        ? ""
        : `${inputToken?.from_asset_address}`,
    chainId: Number(inputToken?.chain),
  });

  const result = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: outputToken?.to_asset_address,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [`${account.address}`],
        chainId: Number(outputToken?.chain),
      },
      {
        address: outputToken?.to_asset_address,
        abi: erc20Abi,
        functionName: "decimals",
        chainId: Number(outputToken?.chain),
      },
      {
        address: outputToken?.to_asset_address,
        abi: erc20Abi,
        functionName: "symbol",
        chainId: Number(outputToken?.chain),
      },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    const value = e.target.value;
    setInputAmount(value);
    setOutputAmount("");
    setIsRetry(false);

    if (
      Number(value) >
      Number(balance.data?.value) / Math.pow(10, Number(balance.data?.decimals))
    ) {
      setErrorMessage("Insufficient balance");
    }

    if (inputToken && outputToken && pubAddress && Number(value) > 0) {
      console.log(value, inputAmount, "innnns");
      debouncedMutate(
        {
          fromAsset: inputToken?.from_asset_address,
          toAsset: outputToken?.to_asset_address,
          amount: Number(value),
          destinationChain: outputToken?.to_network,
          destinationChainId: outputToken?.chain,
          sourceChain: inputToken?.from_network,
          sourceChainId: inputToken?.chain,
          userEvmAddress: pubAddress,
          getQuote: true,
          protocolId: outputToken.__project__.id,
        },
        {
          onSuccess(data, variables, context) {
            console.log(data);
            if (data) {
              for (let i = 0; i < data?.data.txn_function.length; i++) {
                if (data.data.txn_function[i].type == "stake") {
                  const txn = data.data.txn_function[i] as StakeTxnType;
                  setOutputAmount(Number(txn.quote.amountOut).toFixed(8));
                }
              }
              const filterTxn = data.data.txn_function.filter(
                (txn) => txn.type != "approval"
              );
              setGasFee(data.data.gas_fee);
              setTxnTransaction(filterTxn);
              setLastIndex(filterTxn.length - 1);
              setNoOfProtocol(filterTxn.length);
              setTxnPayload(data.data.txn_function);
            }
          },
          onError(error: any, variables, context) {
            setErrorMessage(error?.message);
          },
        }
      );
    }
  };

  const handleStake = async () => {
    setProgress(0);
    setIsLoading(true);

    let txnId: string;
    if (outputToken && inputToken && account.address) {
      debouncedMutate(
        {
          fromAsset: inputToken?.from_asset_address,
          toAsset: outputToken?.to_asset_address,
          amount: Number(inputAmount),
          destinationChain: outputToken?.to_network,
          destinationChainId: outputToken?.chain,
          sourceChain: inputToken?.from_network,
          sourceChainId: inputToken?.chain,
          userEvmAddress: account.address,
          getQuote: false,
          protocolId: outputToken.__project__.id,
        },
        {
          onSuccess: async (data, variables, context) => {
            setTxnPayload(data.data.txn_function);
            txnId = data.data.txn_id;
            setIsTxnInitialized(true);
            let index = 0;
            try {
              const pending_txn_id = localStorage.getItem("failed_txn");
              if (pending_txn_id) {
                const pendingTxn = await getPendingTransaction(
                  pending_txn_id,
                  account.address
                );

                if (pendingTxn?.id && pendingTxn.failure_point) {
                  index = parseInt(pendingTxn.failure_point, 10);
                  setProgress((index / txnPayload?.length) * 100); // Resume progress
                  console.log(`Resuming transaction at stage ${index + 1}`);
                }
              }
              if (txnPayload) {
                for (index; index < txnPayload?.length; index++) {
                  setCurrentStage(index + 1);
                  const txn = txnPayload[index];
                  if (Number(txn.chainId) !== chainId) {
                    await switchChainAsync({ chainId: Number(txn.chainId) });
                  }
                  await sendTransactionAsync(
                    {
                      to: txn.data.to,
                      data: txn.data.data,
                      value:
                        txn.data.value &&
                        parseEther(formatEther(BigInt(txn.data.value._hex))),
                      chainId: Number(txn.chainId),
                    },
                    {
                      onSuccess: () => {
                        setProgress(((index + 1) / txnPayload.length) * 100);
                      },
                      onError: async (error: any) => {
                        console.error(error.message);
                        //setProgress((index / txnPayload.length) * 100);
                        await updateTransActivity(txnId, "failure", index);
                        localStorage.setItem("failed_txn", txnId);
                        // dispatch(updateTxnInitializing(false));
                        setIsRetry(true);
                        // setIsQuote(true);
                        //setCurrentStage(0);
                        setIsLoading(false);
                        setIsTxnInitialized(false);
                        return;
                      },
                    }
                  );
                }

                setIsTxnInitialized(false);
                setIsModalOpen(true);
                setIsRetry(false);
                setCurrentStage(0);
                setIsLoading(false);
                setIsTxnInitialized(false);
                setIsModalOpen(true);
                await updateTransActivity(txnId, "success");
                localStorage.removeItem("failed_txn");
                console.log("All transaction stages completed successfully.");
              }
            } catch (error) {}
          },
          onError(error: any, variables, context) {
            setIsLoading(false);
            setIsTxnInitialized(false);
            setErrorMessage(error?.message);
          },
        }
      );
    }
  };

  useMemo(() => {
    setOutputAmount("");
    if (inputToken && outputToken && pubAddress && inputAmount) {
      debouncedMutate(
        {
          fromAsset: inputToken?.from_asset_address,
          toAsset: outputToken?.to_asset_address,
          amount: Number(inputAmount),
          destinationChain: outputToken?.to_network,
          destinationChainId: outputToken?.chain,
          sourceChain: inputToken?.from_network,
          sourceChainId: inputToken?.chain,
          userEvmAddress: pubAddress,
          getQuote: true,
          protocolId: outputToken.__project__.id,
        },
        {
          onSuccess(data, variables, context) {
            console.log(data);
            if (data) {
              for (let i = 0; i < data?.data.txn_function.length; i++) {
                if (data.data.txn_function[i].type == "stake") {
                  const txn = data.data.txn_function[i] as StakeTxnType;
                  setOutputAmount(Number(txn.quote.amountOut).toFixed(8));
                }
              }
              const filterTxn = data.data.txn_function.filter(
                (txn) => txn.type != "approval"
              );
              setGasFee(data.data.gas_fee);
              setTxnTransaction(filterTxn);
              setLastIndex(filterTxn.length - 1);
              setNoOfProtocol(filterTxn.length);
              setTxnPayload(data.data.txn_function);
            }
          },
          onError(error: any, variables, context) {
            setErrorMessage(error?.message);
          },
        }
      );
    }
  }, [inputToken, outputToken]);

  const nftBalance = useReadContract({
    ...towneSquareContract,
    functionName: "balanceOf",
    args: [account.address],
  });

  const chogStarBalance = useReadContract({
    ...lilChogstarsContract,
    functionName: "balanceOf",
    args: [account.address],
  });

  const isDisabled =
    (!isRetry &&
      (isPending ||
        Number(inputAmount) >
          Number(balance.data?.value) /
            Math.pow(10, Number(balance.data?.decimals)) ||
        Number(inputAmount) <= 0)) ||
    !outputToken;

  //SuccessModal
  const SuccessModal: React.FC<{
    onCancelProcess: () => void;
    inputAmount: string;
    outputAmount: string;
  }> = ({ onCancelProcess, inputAmount, outputAmount }) => {
    console.log(
      Number(getWalletBonus(connectedWallet ?? "")) + 40,
      Number(chogStarBalance.data) >= 1 && 30,
      "bonus"
    );
    const { inputToken, outputToken } = useAggregator();
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
        <div className="md:w-[417px] w-[328px] h-[410px] border border-[#FFFFFF66] bg-[#222222] rounded-lg text-white p-6 relative flex flex-col items-center">
          <button
            onClick={() => onCancelProcess()}
            className="absolute mt-2 top-4 right-4 hover:opacity-80"
          >
            <AiOutlineClose size={22} color="#ffffff" />
          </button>

          <AiFillCheckCircle size={66} color="#2AB576" className="mt-6" />

          <div className="w-full mt-[16px] flex flex-col gap-[12px]">
            <div className="flex justify-between">
              <p className="text-[#CCCCCC] text-[15px]">Staked</p>
              <p className="text-[#FFFFFF] text-[15px]">
                {" "}
                <span className="text-[#AAAAAA]">{`~$${
                  inputTokenValue &&
                  (inputTokenValue * Number(inputAmount)).toFixed(4)
                }`}</span>{" "}
                {inputAmount} {inputToken?.from_asset}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[#CCCCCC] text-[15px]">You received</p>
              <p className="text-[#FFFFFF] text-[15px]">
                {outputAmount} {outputToken?.to_asset}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[#CCCCCC] text-[15px]">Protocol</p>
              <p className="text-[#FFFFFF] text-[15px]">
                {outputToken?.__project__.title}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[#CCCCCC] text-[15px]">Total ranking boost</p>
              <p className="text-[#00EEFD] text-[15px] font-bold">
                {" "}
                {`+${
                  getWalletBonus(connectedWallet ?? "") +
                  Number(Number(nftBalance.data) >= 1 && 40) +
                  Number(Number(chogStarBalance.data) >= 1 && 30)
                }%`}
              </p>
            </div>
          </div>

          <p className="mt-[32px] text-[15px] text-center">
            Manage staking on{" "}
            <a
              href={getProtocolWebsite(outputToken?.__project__.title)}
              target="_blank"
              className="text-[#9264F8] cursor-pointer"
            >
              {outputToken?.__project__.title}
            </a>
          </p>

          <button
            className="mt-[32px] h-[42px] w-[120px] bg-[#9264F8] font-semibold hover:bg-[#9F71FF] text-white rounded-full flex items-center justify-center cursor-pointer"
            onClick={onCancelProcess}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col  items-center min-h-screen">
      <div className="flex flex-col items-center justify-center mb-[36px] ">
      <div className="md:hidden w-full">
        <span className="block text-[23px] font-semibold #323232 ml-6 mb-4 text-start">
          Stake & Earn
        </span>
      </div>
        <h1 className="text-white text-center text-[28px] font-semibold">
          Stake $MON & earn yield with boosted rewards
        </h1>
        <span className="flex text-[28px] text-center font-semibold">
          {" "}
          on <img className="mx-2" src="/assets/main_v4/mmonad.svg" /> testnet
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <div
          className={`md:w-[500px] w-[328px] items-center flex-col flex gap-[8px] h-[134px] bg-[#121212] border ${
            errorMessage ? "border-[#FF4471]" : "border-[#AAA]"
          }  rounded-[16px] pt-[16px] pl-[16px] pr-[24px] pb-[24px] `}
        >
          <div className="flex items-end w-full">
            <Field className="flex-1">
              <Label className="block text-[14px] leading-4">Stake</Label>
              <Input
                value={inputAmount}
                className="block placeholder:text-[29px] text-white placeholder:text-white text-[29px] leading-[36.54px] mt-1 w-full no-spinner h-[42px] bg-[#121212] outline-none placeholder:"
                placeholder="0"
                name="stakeAmount"
                type="number"
                onWheel={(e) => e.currentTarget.blur()} // Disable mouse wheel
                onChange={handleChange}
              />
            </Field>
            <InputTokenModal />
          </div>
          <div className="flex justify-between w-full">
            <p className="text-[14px] text-[#AAA]">
              {" "}
              {`$ ${
                inputTokenValue && inputAmount
                  ? (inputTokenValue * Number(inputAmount)).toFixed(4)
                  : ""
              }`}
            </p>
            <p className="text-[14px] text-[#AAA]">{`Balance: ${
              balance.data
                ? (
                    Number(balance.data?.value) /
                    Math.pow(10, balance.data?.decimals)
                  ).toFixed(4)
                : "-"
            }`}</p>
          </div>
        </div>
        {errorMessage && (
          <p className="text-[#FF4471] ml-2 text-[15px]">{errorMessage}</p>
        )}
        <div className="md:w-[500px] w-[328px] relative flex-col flex gap-[8px] h-[134px] bg-[#121212] border-[#AAA] rounded-[16px] pt-[16px] pl-[16px] pr-[24px] pb-[24px] ">
          <div className="flex items-end w-full">
            <Field className="flex-1">
              <Label className="block text-[14px] leading-4">
                You will receive
              </Label>
              <Input
                disabled
                value={outputAmount}
                className="block placeholder:text-[29px] text-[#AAA] placeholder:text-[#AAA] text-[29px] leading-[36.54px] mt-1 w-full no-spinner h-[42px] bg-[#121212] outline-none"
                placeholder="0"
                name="stakeAmount"
                type="number"
              />
            </Field>
            <div className="flex items-center gap-3">
              {isPending && !isLoading && (
                <div className="flex gap-2">
                  <img
                    className="animate-spin animate-ease-in w-[20px] h-[20px]"
                    src="/assets/main_v4/loading.svg"
                  />
                  <p className="text-[14px]">Fetching best quotes</p>
                </div>
              )}
              <OutputTokenModal />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <p className="text-[14px] text-[#AAA]">
              {" "}
              {`$ ${
                outputTokenValue && outputAmount
                  ? (outputTokenValue * Number(outputAmount)).toFixed(4)
                  : ""
              }`}
            </p>
            <p className="text-[14px] text-[#AAA]">{`Balance: ${
              result.data
                ? formatUnits(result.data?.[0], result.data?.[1])
                : "-"
            }`}</p>
          </div>
        </div>

        {account.isConnected && outputToken && (
          <div className="flex flex-col">
            <div className="flex items-center justify-between mt-[16px] h-[29px] w-full text-gray-300">
              {/* <div className="flex items-center space-x-2">
                <button className="border w-[145px] h-[29px] font-medium text-[15px] border-[#9264F8] text-[#9264F8] bg-[#9264F8] bg-opacity-20 px-[16px] py-1 rounded-full">
                  Transaction flow
                </button>
                <p className="text-[15px] text-[#AAAAAA]">via {noOfProtocol} protocols</p>
              </div> */}
              <div className="flex items-center space-x-2">
                <div>
                  <button
                    onClick={open}
                    className="border w-[145px] h-[29px] font-medium text-[15px] border-[#9264F8] text-[#9264F8] bg-[#9264F8] bg-opacity-20 px-[16px] py-1 rounded-full"
                  >
                    Transaction flow
                  </button>

                  <Dialog
                    open={isOpen}
                    as="div"
                    className="relative z-10 focus:outline-none"
                    onClose={close}
                  >
                    <DialogBackdrop className="fixed inset-0 bg-black/80" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                      <div className="flex items-center justify-center min-h-full p-4">
                        <DialogPanel
                          transition
                          className="md:w-[493px] h-fit py-7 rounded-xl bg-[#222222] border border-white/40 pl-6 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                          <DialogTitle
                            as="div"
                            className="flex justify-between mb-6 text-white "
                          >
                            <p className="text-[18px]">Transaction flow</p>
                            <button onClick={close} className="mr-6">
                              <IoMdClose className="w-[24px] h-[24px]" />
                            </button>
                          </DialogTitle>

                          <div className={`rounded-lg mb-6`}>
                            <div className="flex flex-col gap-3 p-2">
                              {txnTransaction?.map((txn, index) => (
                                <div key={index}>
                                  {isBridgeTxnType(txn) && (
                                    <div className="flex flex-col gap-3">
                                      {index == 0 && (
                                        <div className="flex items-center">
                                          <div className="flex gap-4 w-[100px]">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              1
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You send
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout.sourceAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout.sourceChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.sourceAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex items-center">
                                        <div className="flex gap-4 w-[100px">
                                          <p className="text-[#FFF] text-[13px] font-semibold">
                                            {index + 2}
                                          </p>
                                          <p className="f text-[#CCC] text-[13px]">
                                            {/* {" "}
                                    {index == lastIndex
                                      ? "You get"
                                      : `${txn.type}`} */}
                                            {capitalize(txn.type)}
                                          </p>
                                        </div>
                                        <div
                                          key={index}
                                          className="flex items-center gap-2 justify-evenly"
                                        >
                                          <div className="flex items-center gap-1">
                                            {/* <img
                                        src={txn.layout.sourceAssetLogo}
                                        alt="Source Asset Logo"
                                        className="w-[20px] h-[20px]"
                                      /> */}
                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.sourceAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.sourceChainLogo
                                              }
                                            />
                                            <p className="text-[#FFF] text-[13px]">
                                              {txn.layout.sourceAsset}{" "}
                                              {/* <span className="text-[#818181]">
                                        ({txn.abstractionFlow.from.blockchain})
                                      </span> */}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />

                                          <div
                                            key={index}
                                            className="flex items-center gap-1"
                                          >
                                            <img
                                              src={txn.layout.protocolLogo}
                                              className="w-[20px] h-[20px]"
                                            />
                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.protocolName}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                                          <div className="flex items-center justify-center gap-1">
                                            {/* <img
                                        src={txn.layout.destinationAssetLogo}
                                        alt="To Logo"
                                        className="w-[20px] h-[20px]"
                                      /> */}

                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.destinationAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.destinationChainLogo
                                              }
                                            />

                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.destinationAsset}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {index == lastIndex && (
                                        <div className="flex items-center">
                                          <div className="flex gap-4 w-[100px]">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              {index + 3}
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You get
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout
                                                    .destinationAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout
                                                    .destinationChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.destinationAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {isSwapTxnType(txn) && (
                                    <div className="flex flex-col gap-3 ">
                                      {index == 0 && (
                                        <div className="flex items-center">
                                          <div className="flex w-[100px] gap-4">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              1
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You send
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              {/* <img
                                          className="w-[20px] h-[20px]"
                                          src={txn.layout.sourceAssetLogo}
                                        /> */}

                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout.sourceAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout.sourceChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.sourceAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex items-center">
                                        <div className="flex w-[100px] gap-4">
                                          <p className="text-[#FFF] text-[13px] font-semibold">
                                            {index + 2}
                                          </p>
                                          <p className="f text-[#CCC] text-[13px]">
                                            {capitalize(txn.type)}
                                          </p>
                                        </div>
                                        <div
                                          key={index}
                                          className="flex items-center gap-2 justify-evenly"
                                        >
                                          <div className="flex items-center gap-1">
                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.sourceAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.sourceChainLogo
                                              }
                                            />
                                            <p className="text-[#FFF] text-[13px]">
                                              {txn.layout.sourceAsset}{" "}
                                              {/* <span className="text-[#818181]">
                                        ({txn.abstractionFlow.from.blockchain})
                                      </span> */}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />

                                          <div
                                            key={index}
                                            className="flex items-center gap-1"
                                          >
                                            <img
                                              src={txn.layout.protocolLogo}
                                              className="w-[20px] h-[20px]"
                                            />
                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.protocolName}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                                          <div className="flex items-center justify-center gap-1">
                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.destinationAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.destinationChainLogo
                                              }
                                            />
                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.destinationAsset}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {index == lastIndex && (
                                        <div className="flex items-center">
                                          <div className="flex w-[100px] gap-4">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              {index + 3}
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You get
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout
                                                    .destinationAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout
                                                    .destinationChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.destinationAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {isStakeTxnType(txn) && (
                                    <div className="flex flex-col gap-3">
                                      {index == 0 && (
                                        <div className="flex items-center">
                                          <div className="flex w-[100px] gap-4">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              1
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You send
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout.sourceAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout.sourceChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.sourceAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex items-center">
                                        <div className="flex w-[100px] gap-4">
                                          <p className="text-[#FFF] text-[13px] font-semibold">
                                            {index + 2}
                                          </p>
                                          <p className="f text-[#CCC] text-[13px]">
                                            {/* {" "}
                                    {index == lastIndex
                                      ? "You get"
                                      : `${txn.type}`} */}
                                            {capitalize(txn.type)}
                                          </p>
                                        </div>
                                        <div
                                          key={index}
                                          className="flex items-center gap-2 justify-evenly"
                                        >
                                          <div className="flex items-center gap-1">
                                            {/* <img
                                        src={txn.layout.sourceAssetLogo}
                                        alt="Source Asset Logo"
                                        className="w-[20px] h-[20px]"
                                      /> */}
                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.sourceAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.sourceChainLogo
                                              }
                                            />
                                            <p className="text-[#FFF] text-[13px]">
                                              {txn.layout.sourceAsset}{" "}
                                              {/* <span className="text-[#818181]">
                                        ({txn.abstractionFlow.from.blockchain})
                                      </span> */}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />

                                          <div
                                            key={index}
                                            className="flex items-center gap-1"
                                          >
                                            <img
                                              src={txn.layout.protocolLogo}
                                              className="w-[20px] h-[20px]"
                                            />
                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.protocolName}
                                            </p>
                                          </div>
                                          <HiArrowSmallRight className="text-[#AEAFBC]" />
                                          <div className="flex items-center justify-center gap-1">
                                            {/* <img
                                        src={txn.layout.destinationAssetLogo}
                                        alt="To Logo"
                                        className="w-[20px] h-[20px]"
                                      /> */}

                                            <ImageOverlay
                                              mainImageSrc={
                                                txn.layout.destinationAssetLogo
                                              }
                                              overlayImageSrc={
                                                txn.layout.destinationChainLogo
                                              }
                                            />

                                            <p className="text-[13px] text-[#FFF]">
                                              {txn.layout.destinationAsset}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {index == lastIndex && (
                                        <div className="flex items-center">
                                          <div className="flex gap-4 w-[100px]">
                                            <p className="text-[#FFF] text-[13px] font-semibold">
                                              {index + 3}
                                            </p>
                                            <p className="text-[13px] text-[#CCCCCC]">
                                              You get
                                            </p>
                                          </div>
                                          <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-1">
                                              <ImageOverlay
                                                mainImageSrc={
                                                  txn.layout
                                                    .destinationAssetLogo
                                                }
                                                overlayImageSrc={
                                                  txn.layout
                                                    .destinationChainLogo
                                                }
                                              />
                                              <p className="text-[13px] text-[#FFFFFF]">
                                                {txn.layout.destinationAsset}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          <p className="text-[13px] text-[#CCCCCC]">
                            Transaction flow auto-refreshes every 30sec
                          </p>
                        </DialogPanel>
                      </div>
                    </div>
                  </Dialog>
                </div>

                <p className="text-[15px] text-[#AAAAAA]">
                  via {noOfProtocol} protocols
                </p>
              </div>
              {outputAmount && (
                <div className="flex items-center space-x-2">
                  <img
                    src="/assets/main_v4/GasPump.svg"
                    alt="Gas Pump"
                    className="w-4 h-4"
                  />
                  <span className="text-[15px] text-[#AAAAAA]">
                    -
                    {`${
                      outputTokenValue &&
                      (
                        outputTokenValue *
                        Number(gasFee[0]?.totalFee.toFixed(4))
                      ).toFixed(4)
                    }`}
                  </span>
                  <span className="text-[15px] text-white">
                    {gasFee[0]?.totalFee.toFixed(4)} MON
                  </span>
                </div>
              )}
            </div>
            <div className="flex  flex-col mt-[20px] md:w-[500px]  w-[328px] text-white p-4  rounded-lg border border-[#666666]">
              <div className="flex flex-row h-[30px] gap-2">
                <div className="rounded-full">
                  <img
                    src={`https://townesquare-media-new.s3.eu-west-2.amazonaws.com/${outputToken.__project__.title}-rounded.svg`}
                    alt="logo"
                    className="w-[30px] h-[30px]"
                  />
                </div>
                <span className="font-bold text-[18px]">
                  {outputToken.__project__.title}
                </span>
              </div>
              <div className="flex flex-col gap-2 mt-[16px] ">
                <div className="flex justify-between">
                  <span className="text-[15px]">Annual percentage yield</span>
                  <span className="font-semibold text-[15px]text-white">
                    {outputToken.apr ? `APY ${outputToken.apr}%` : "NA"}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[15px]">Total value staked</span>
                  <span className="font-semibold text-[15px] text-white">
                    {outputToken.totalValStaked
                      ? `$ ${outputToken.totalValStaked}`
                      : "NA"}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-[15px]">Stakers</span>
                  <span className="font-semibold text-[15px] text-white">
                    {outputToken.noOfStakers
                      ? `${outputToken.noOfStakers}`
                      : "NA"}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex md:flex-row  flex-col md:items-center justify-between mt-[20px]">
              <p className="text-[15px] mb-2 md:mb-0">Rank bonus</p>
              <div
                className={`flex flex-row gap-1 ${
                  connectedWallet === "metamask"
                    ? "flex-1 justify-end"
                    : "md:justify-end w-[260px]"
                }`}
              >
                {/* TowneSquare Tooltip */}
                {/* <Tooltip
                  anchorSelect=".townesquare-tooltip"
                  className="text-[14px] bg-black border text-white border-[#404040]"
                  place="top"
                  classNameArrow="border-b border-r border-[#404040]"
                >
                  TowneSquare bonus: Earn{" "}
                  <span className="text-[#00EEFD]">+30% bonus rank</span>
                </Tooltip>

                <div className="w-[83px] items-center townesquare-tooltip hover:bg-white/10 cursor-custom flex flex-row justify-center rounded-[40px] border border-[#666666] p-1">
                  <img
                    src="/assets/main_v4/Lightning.svg"
                    className="w-[22px] h-[22px]"
                  />
                  <p className="text-[#00EEFD] text-[15px] font-bold pl-1">
                    +30%
                  </p>
                </div> */}

                {Number(chogStarBalance.data) >= 1 && (
                  <>
                    <Tooltip
                      anchorSelect=".chogStar-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="top"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]"> +30% bonus rank </span>
                      by holding{" "}
                      <span className="font-semibold">Lil Chogstars </span>
                    </Tooltip>

                    <div className="w-[83px]  cursor-custom flex chogStar-tooltip hover:bg-white/10 flex-row justify-center items-center rounded-[40px] border border-[#666666] p-1">
                      <img
                        src="/assets/main_v4/chogstar.svg"
                        className="w-[26px] h-[22px] object-cover"
                      />
                      <p className="text-[#00EEFD] text-[15px] font-bold pl-1">
                        +30%
                      </p>
                    </div>
                  </>
                )}

                {/* NFT Collection Tooltip */}
                {Number(nftBalance.data) >= 1 && (
                  <>
                    <Tooltip
                      anchorSelect=".nft-collection-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="top"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]">+30% bonus rank </span>
                      by holding{" "}
                      <span className="font-semibold">
                        NAD Profile NFT - OG edition{" "}
                      </span>
                    </Tooltip>

                    <div className="w-[83px]  cursor-custom flex nft-collection-tooltip hover:bg-white/10 flex-row justify-center items-center rounded-[40px] border border-[#666666] p-1">
                      <img
                        src="/assets/main_v4/Nad_Profile_NFT.svg"
                        className="w-[26px] h-[22px] object-cover"
                      />
                      <p className="text-[#00EEFD] text-[15px] font-bold pl-1">
                        +30%
                      </p>
                    </div>
                  </>
                )}

                {hasMadLadsNFT.data && (
                  <>
                    <Tooltip
                      anchorSelect=".madlads-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="top"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      You are earning
                      <span className="text-[#00EEFD]">+10% bonus rank </span>
                      by holding <span className="font-semibold">MadLads</span>
                    </Tooltip>

                    <div className="w-[83px]  cursor-custom flex madlads-tooltip hover:bg-white/10 flex-row justify-center items-center rounded-[40px] border border-[#666666] p-1">
                      <img
                        src="/assets/main_v4/madlads.svg"
                        className="w-[32px] cursor-custom chogStar-tooltip h-[32px]"
                      />
                      <p className="text-[#00EEFD] text-[15px] font-bold pl-1">
                        +10%
                      </p>
                    </div>
                  </>
                )}

                {connectedWallet && connectedWallet !== "metamask" && (
                  <>
                    <Tooltip
                      anchorSelect=".wallet-tooltip"
                      className="text-[14px] bg-black border text-white border-[#404040]"
                      place="top"
                      classNameArrow="border-b border-r border-[#404040]"
                    >
                      {`Connected with ${capitalize(
                        connectedWallet
                      )}: You are earning `}
                      <span className="text-[#00EEFD]">
                        {`+${getWalletBonus(connectedWallet)}%`}
                      </span>
                    </Tooltip>
                    <div className="w-[83px] cursor-custom wallet-tooltip flex hover:bg-white/10 flex-row justify-center item-center rounded-[40px] border border-[#666666] p-1">
                      <img
                        src={getWalletIcon(connectedWallet)}
                        className="w-[22px] h-[22px]"
                      />
                      <p className="text-[#00EEFD] text-[15px] font-bold pl-1">
                        {`+${getWalletBonus(connectedWallet)}%`}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>

            {isRetry && account.isConnected && (
              <div className="w-full mt-[20px] space-y-4">
                <div className="flex items-center justify-between space-x-2">
                  <div className="flex items-center">
                    <p className="text-sm font-semibold text-white">
                      Transaction {currentStage} of {txnPayload.length}
                    </p>
                  </div>

                  <div className="flex items-center space-x-1">
                    <p className="text-sm font-semibold text-[#FF4471]">
                      Transaction Failed
                    </p>
                    <RiErrorWarningFill fill="#FF4471" />
                  </div>
                </div>
                {/* Progress Indicator */}
                <div className="relative w-full h-2 bg-[#666] border border-gray-500 rounded-[10px] overflow-hidden">
                  <div
                    className="h-full rounded-full bg-[#FF4471]"
                    style={{
                      width: `${Math.max(progress, 10)}%`, // Ensure the progress never goes below 10%
                    }}
                  />
                </div>{" "}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-center items-center mt-[32px] ">
          {account.isConnected ? (
            // <button
            //   disabled={Boolean(inputAmount) == false || outputToken == null}
            //   className="h-[42px] disabled:opacity-40 w-[162px] px-6 border bg-[#9264F8] font-semibold border-primary-default hover:bg-[#9F71FF] text-white rounded-full flex items-center justify-center cursor-pointer"
            //   onClick={() => {
            //     handleStake();
            //   }}
            // >
            //   {!isLoading ? (
            //     "Stake"
            //   ) : (
            //     <img className="animate-spin" src="/assets/loading.svg" />
            //   )}
            // </button>
            <button
              onClick={() => {
                handleStake();
              }}
              disabled={isDisabled}
              className="h-[42px] disabled:opacity-40 w-[162px] px-6 border bg-[#9264F8] font-semibold border-primary-default hover:bg-[#9F71FF] text-white rounded-full flex items-center justify-center cursor-pointer"
            >
              {isRetry ? (
                "Retry"
              ) : isLoading ? (
                <img className="animate-spin" src="/assets/loading.svg" />
              ) : (
                "Stake"
              )}
            </button>
          ) : (
            <button
              className="h-[42px] w-[162px] px-6 border bg-[#9264F8] font-semibold border-primary-default hover:bg-[#9F71FF] text-white rounded-full flex items-center justify-center cursor-pointer"
              onClick={() => onConnect && onConnect()}
            >
              Connect Wallet
            </button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <SuccessModal
          inputAmount={inputAmount}
          outputAmount={outputAmount}
          onCancelProcess={() => setIsModalOpen(false)}
        />
      )}
      <InitializationModal
        onText={getModalText()}
        progress={progress}
        onCancel={() => {
          setIsTxnInitialized(false);
          setIsLoading(false);
        }}
        isTxnInitialized={isTxnInitialized}
      />
    </div>
  );
};

export default StakeAndEarn;
