import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useRef, useCallback } from "react";
import axios, { CancelTokenSource } from "axios";

export function useDebouncedMutation<TData, TVariables>(
  mutationFn: (variables: TVariables, cancelToken: CancelTokenSource) => Promise<TData>,
  delay: number
): UseMutationResult<TData,Error, TVariables> & { debouncedMutate: (variables: TVariables, options?: UseMutationOptions<TData, unknown, TVariables>) => void } {
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const mutation = useMutation<TData, Error, TVariables>({
    mutationFn: async (variables: TVariables) => {
      // Cancel any ongoing request
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Request canceled due to parameter change.");
        console.log("Request canceled due to parameter change.")
      }

      // Create a new CancelToken
      cancelTokenRef.current = axios.CancelToken.source();

      // Execute the mutation function
      return mutationFn(variables, cancelTokenRef.current);
    },
  });

  const debouncedMutate = useCallback(
    (variables: TVariables, options?: UseMutationOptions<TData, Error, TVariables>) => {
      // Clear the previous debounce timer
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      // Set a new debounce timer
      timeoutRef.current = setTimeout(() => {
        mutation.mutate(variables, options); // Pass options correctly here
      }, delay);
    },
    [mutation, delay]
  );

  return { ...mutation, debouncedMutate };
}
