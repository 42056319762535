import React, { useState, useRef, useEffect } from "react";
import TopRanking from "./Topranking";
import LeftSvgComponent from "../../assets/SVG/lefticon";
import RightSvgComponent from "../../assets/SVG/righticon";
import NewcomerComp from "./newcommeritem";

const UserRanking: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"topRanking" | "newcomers">("topRanking");
  const scrollPosition = useRef(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleTabChange = (tab: "topRanking" | "newcomers") => {
    // Save scroll position before changing tabs
    if (contentRef.current) {
      scrollPosition.current = contentRef.current.scrollTop;
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    // Restore scroll position after tab switch
    if (contentRef.current) {
      contentRef.current.scrollTop = scrollPosition.current;
    }
  }, [activeTab]);

  return (
    <>
      {/* Desktop view */}
      <div className="hidden md:relative z-10 md:flex justify-center pt-10 gap-6 bg-black text-white px-8 mb-6">
        <TopRanking title="Top 100 ranked users" />
        <NewcomerComp title="TowneSquare newcomers" />
      </div>

      {/* Mobile view with tab switching */}
      <div className="block md:hidden relative z-10 pt-10 bg-black text-white px-4 text-center">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleTabChange("topRanking");
            }}
            disabled={activeTab === "topRanking"}
          >
            <LeftSvgComponent color={activeTab === "topRanking" ? "#555" : "#fff"} />
          </button>
          <h2 className="text-lg font-bold">
            {activeTab === "topRanking" ? "Top 100 ranked users" : "TowneSquare newcomers"}
          </h2>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleTabChange("newcomers");
            }}
            disabled={activeTab === "newcomers"}
          >
            <RightSvgComponent color={activeTab === "newcomers" ? "#555" : "#fff"} />
          </button>
        </div>

        {/* Scrollable content container */}
        <div ref={contentRef} className="overflow-auto max-h-[500px]">
          {activeTab === "topRanking" ? <TopRanking title="" /> : <NewcomerComp title="" />}
        </div>
      </div>
    </>
  );
};

export default UserRanking;
