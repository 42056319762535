import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAssetsByOwner,
  getRanking,
  getStakeAggregatorAssets,
  getStakeAggregatorTxn,
  getUserDetails,
  getUserPrimaryName,
} from "./helper";
import { InputToken, OutputToken, UserItem } from "./types";
import axios from "axios";
import { BACKEND_URL } from "./env";

export const useFetchStakeAggregatorAssets = () => {
  const fetchStakeAggregatorAssets = async () => {
    const response = await getStakeAggregatorAssets();
    return response?.data;
  };

  return useQuery({
    queryKey: ["fetchStakeAggregatorAssets"],
    queryFn: () => fetchStakeAggregatorAssets(),
  });
};

export const useFetchTransactionInfo = ({
  inputToken,
  outputToken,
  userAddress,
}: {
  inputToken: InputToken | null;
  outputToken: OutputToken | null;
  userAddress?: string;
}) => {
  const getFetchTransactionInfo = async () => {
    if (inputToken && outputToken) {
      const response = await getStakeAggregatorTxn({
        fromAsset: inputToken?.from_asset_address,
        toAsset: outputToken?.to_asset_address,
        amount: Number(10),
        destinationChain: outputToken?.to_network,
        destinationChainId: outputToken?.chain,
        sourceChain: inputToken?.from_network,
        sourceChainId: inputToken?.chain,
        userEvmAddress: userAddress ?? "",
        getQuote: true,
        protocolId: outputToken.__project__.id,
      });
      return response?.data;
    }
  };

  return useQuery({
    queryKey: [
      "FetchTransactionDetails",
      inputToken?.from_asset,
      outputToken?.to_asset,
    ],
    queryFn: () => getFetchTransactionInfo(),
  });
};

export const preloadData = async () => {
  const response = await getRanking();

  const mappedData = response.data
    .map((item: any) => ({
      user_id: item.user_id,
      user_adddress: item.user_adddress,
      points: item.points,
      rank: item.rank,
      username: "",
    }))
    .sort((a: UserItem, b: UserItem) => a.rank - b.rank)
    .slice(0, 100);

  return mappedData;
};

export const getRankingData = async () => {
  const response = await getRanking();
  let results: UserItem[] = [];
  const mappedData = response.data
    .map((item: any) => ({
      user_id: item.user_id,
      user_adddress: item.user_adddress,
      points: item.points,
      rank: item.rank,
      username:""
    }))
    .sort((a: UserItem, b: UserItem) => a.rank - b.rank)
    .slice(0, 100);

  try {
    for (let i = 0; i < mappedData.length; i += 10) {
      const batch = mappedData.slice(i, i + 10);
      const batchresult = await Promise.all(
        batch.map(async (data) => ({
          user_id: data.user_id,
          user_adddress: data.user_adddress,
          points: data.points,
          rank: data.rank,
          username: (await getUserPrimaryName(data.user_adddress)) || "",
        }))
      );

      results.push(...batchresult);

      if (i + 10 < mappedData.length) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait before next batch
      }
    }

    return results;
  } catch (error) {
    return mappedData;
  }
  //return mappedData;
};

export const useFetchRankingData = () => {
  const { data: prefetchRankData } = useQuery({
    queryKey: ["prefetchRankData"],
    queryFn: preloadData,
  });

  return useQuery({
    queryKey: ["getRankingData"],
    queryFn: () => getRankingData(),
    staleTime: 86400000, // 24 hours (1 day)
    refetchOnWindowFocus: false, // Prevent refetching when switching tabs
    placeholderData: prefetchRankData,
  });
};


export const useCheckUserHasMadladsNFT = (userAddress?: string) => {
  const checkUserHasMadLadsNFT = async () => {
    if (!userAddress) {
      return;
    }
    try {
      const assets = await getAssetsByOwner(userAddress);
      const madLadsNFT = assets.result.items.find(
        (asset) =>
          asset?.grouping[0]?.group_value ==
          "J1S9H3QjnRtBbbuD4HjPV6RpRhwuk4zKbxsnCHuTgh9w"
      );
      if (madLadsNFT) {
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return useQuery({
    queryKey: ["checkUserHasMadLadsNFT", userAddress],
    queryFn: () => checkUserHasMadLadsNFT(),
    staleTime: 86400000, // 24 hours (1 day)
    refetchOnWindowFocus: false, // Prevent refetching when switching tabs
  });
};
