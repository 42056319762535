import * as React from "react";

interface RightSvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const RightSvgComponent: React.FC<RightSvgProps> = ({ color = "#fff", ...props }) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_24_35210)">
        <path
          d="M8.591 6.364a.41.41 0 00-.187.139.355.355 0 00-.07.21v7.576c0 .075.024.148.07.21a.41.41 0 00.187.14c.076.029.16.036.241.022a.432.432 0 00.213-.104l4.167-3.788a.378.378 0 00.09-.123.349.349 0 000-.29.378.378 0 00-.09-.123L9.045 6.445a.455.455 0 00-.454-.082z"
          fill={color}  
        />
      </g>
      <defs>
        <clipPath id="clip0_24_35210">
          <path
            fill={color}
            transform="rotate(-90 10.25 10.25)"
            d="M0 0H20V20H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightSvgComponent;
