interface userData {
  id: string;
  wallet_address: string;
  wallet_type: string;
  is_active: boolean;
  referral_code: string;
  created_at: string;
  updated_at: string;
}

export interface checkUserResponse {
  statusCode: number;
  data: userData;
}

export enum walletType {
  Metamask = "metamask",
  Phantom = "phantom",
  BackPack = "backpack",
  Okx = "okxos",
  Haha ="haha"
}

export enum walletType2 {
  Metamask = "metamask",
  Phantom = "Phantom",
  BackPack = "Backpack",
  Okx = "okxos",
  Haha ="haha"
}

export interface signUpResponse {
  statusCode: number;
  message?: string;
  data: {
    message?: string;
    token: string;
  };
  error?: any;
}

export interface signInResponse {
  statusCode: number;
  message?: string;
  data: {
    message?: string;
    token: string;
  };
  error?: any;
}

export type GetAllChainResponse = {
  statusCode: string;
  data: listOfChain[];
};

export interface GetNewcommerResponse {
  data: {
    id: string;
    wallet_address: string;
    wallet_type: walletType;
    is_active: boolean;
    referral_code: string;
    created_at: string;
    updated_at: string;
    referrals: {
      id: string;
      user_id: string;
      referred_by: string;
      created_at: string;
      updated_at: string;
      referred: {
        id: string;
        wallet_address: string;
        wallet_type: walletType;
        is_active: boolean;
        solana_address: string | null;
        referral_code: string;
        created_at: string;
        updated_at: string;
      };
    }[];
  }[];
}


export interface GetRefferalResponse {
  user: {
    id: string;
    wallet_address: string;
    wallet_type: string;
    is_active: boolean;
    referral_code: string;
    created_at: string;
    updated_at: string;
  };
  referrals: {
    id: string;
    user_id: string;
    referred_by: string;
    created_at: string;
    updated_at: string;
    user: {
      id: string;
      wallet_address: string;
      wallet_type: string;
      is_active: boolean;
      referral_code: string;
      created_at: string;
      updated_at: string;
    };
  }[];
}



export type listOfChain = {
  name: string;
  symbol: string;
  icon: string;
  chainID: number;
  slug: string;
  nativeAsset: string;
  addressType: string;
  isBorrowStatusActive: boolean;
  isLendStatusActive: boolean;
  isStakeStatusActive: boolean;
  isSwapStatusActive: boolean;
  assets: Asset[];
};

export type ArtistResponse = {
  statusCode: number;
  message: string;
  data: ArtistCollection[];
  error: null | string;
};

export type ArtistCollection = {
  id: number;
  collectionName: string;
  x_handle: string;
  collectionDescription: string;
  contractAddress: string;
  website: string;
  descriptionTitle: string;
  chainId: string;
  collectionImages: string[];
  isArtistOfTheWeek: boolean;
  created_at: string;
  updated_at: string;
  artists: Artist[];
  totalSupply: string;
};

type Artist = {
  id: number;
  bio: string;
  x_handle: string;
  x_handle_url: string;
  name: string;
  pfp: string;
};

export type Asset = {
  name: string;
  symbol: string;
  slug: string;
  icon: string;
  decimal: number;
  address: string;
  isNativeasset: boolean;
  destinations: Destination[];
};

export type Destination = {
  chain: string;
  symbol: string;
  icon: string;
  chainID: number;
  slug: string;
  nativeAsset: string;
  addressType: string;
  isBorrowStatusActive: boolean;
  isLendStatusActive: boolean;
  isStakeStatusActive: boolean;
  isSwapStatusActive: boolean;
  assets: Token[];
};

export type Token = {
  name: string;
  symbol?: string;
  slug: string;
  icon: string;
  decimal: number;
  address: string;
  isNativeasset: boolean;
  isWrapped: boolean;
};

export type FetchProjectRespomseData = {
  statusCode: number;
  data: Project[];
};

interface Project {
  id: number;
  title: string;
  is_claimed: boolean;
  company_id: number | null;
  created_at: string;
  updated_at: string;
}

export type UserDetailsResponse = {
  statusCode: number;
  message: string;
  data: {
    user: {
      id: string;
      wallet_address: string;
      wallet_type: string;
      is_active: boolean;
      referral_code: string;
      created_at: string;
      updated_at: string;
    };
    point: {
      id: string;
      points: number | null;
      user_id: string;
      user_adddress: string;
      rank: number;
    };
  };
  error: string | null;
};

export type FetchAllOnboardingAppByProjectIdResponse = {
  statusCode: number;
  data: FetchAllOnboardingAppByProjectIdData[];
};

export type FetchAllOnboardingAppByProjectIdData = {
  id: number;
  name: string;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: string | null;
  min_amount: number | null;
  max_amount: number | null;
  redirect_url: string | null;
  source_chain: string | null;
  source_asset: string | null;
  dest_chain: string | null;
  dest_asset: string | null;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  bonus_percentage: number | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  is_archived: boolean;
  flow: null;
  banner: string | null;
  company_id: string | null;
  abstraction_flow: AbstractionData | null;
  generate_link: string;
  __project__: {
    id: number;
    title: string;
    is_claimed: boolean;
    company_id: string | null;
    description: string;
    logo: string;
    created_at: string;
    updated_at: string;
  };
  asset_images: {
    source_asset_icon: string;
    dest_asset_icon: string;
    source_asset_symbol: string;
    dest_asset_symbol: string;
  };
};
export interface ApiResponse<T> {
  statusCode: number;
  data: T;
  message: string;
  error: any;
}

export interface Protocol {
  onboardingApps: number;
  networkUrls: any;
  id: number;
  title: string;
  description: string;
  logo: string;
  icon: string;
  active_campaign: boolean;
  company_id: string;
  __onboarding__: Onboarding[];
  onboardingCount: number;
  is_claimed: boolean;
  created_at: string;
  updated_at: string;
  actions: ProtocolCategory[];
  networks: ProtocolNetwork[];
}

export interface HistoryResponse {
  id: string;
  onboarding_id: number;
  type: string;
  amount: number;
  status: string;
  info: InfoData | null;
  user_address: string;
  failure_point: string;
  created_at: string;
  updated_at: string;
  __onboarding__: OnboardingApp;
  __project__: OnboardingProject;
}

export interface InfoData {
  address: string;
  amount: number;
  category: string;
  created_at: string;
  fromChainId: string;
  from_asset: string;
  from_asset_address: string;
  from_asset_logo: string;
  from_network_url: string;
  toChainId: string;
  to_asset: string;
  to_asset_address: string;
  to_asset_logo: string;
  to_network_url: string;
  txn_id: string;
  updated_at: string;
}

export interface Onboarding {
  id: number;
  protocol_id: number;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: number | null;
  min_amount: number;
  max_amount: number;
  redirect_url: string;
  source_chain: string;
  source_asset: string;
  dest_chain: string;
  dest_asset: string;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  logo: string | null;
  title: string | null;
  bonus_percentage: number | null;
  subtitle: string | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  is_archived: boolean;
  flow: number[] | null;
  banner: string | null;
  company_id: string;
  abstraction_flow: string | null;
  source_asset_address: string;
}

interface ProtocolNetwork {
  id: number;
  protocol_id: number;
  chain: string;
  logo: string;
}

interface ProtocolCategory {
  id: number;
  protocol_id: number;
  action: string;
}

// interface Network {
//   id: number;
//   name: string;
//   icon: string;
//   chain_id: string;
//   chain_symbol: string;
//   is_mainnet: boolean;
// }

export type FetchSingleOnbaordingAppResponse = {
  data: {
    id: number | null;
    project_id: number | null;
    onboarding_type: string;
    onboarding_link: string | null;
    apr: string | null;
    min_amount: number | null;
    max_amount: number | null;
    redirect_url: string;
    source_chain: string;
    source_asset: string;
    dest_chain: string;
    dest_asset: string;
    theme: string;
    page_color: string;
    accent_color: string;
    logo: string;
    title: string;
    subtitle: string;
    bonus_percentage: number | null;
    website: string;
    poster: null;
    is_active: boolean;
    flow: null;
    banner: string;
    company_id: string;
    abstraction_flow: null;
    source_asset_address: `0x${string}` | undefined;
  };
};
export interface OnboardingApp {
  id: number;
  project_id: number;
  onboarding_type: string;
  onboarding_link: string | null;
  apr: number | null;
  min_amount: number;
  max_amount: number;
  redirect_url: string;
  source_chain: string;
  source_asset: string;
  dest_chain: string;
  dest_asset: string;
  theme: string | null;
  page_color: string | null;
  accent_color: string | null;
  bonus_percentage: number | null;
  logo: string | null;
  title: string | null;
  subtitle: string | null;
  website: string | null;
  poster: string | null;
  is_active: boolean;
  is_archived: boolean;
  flow: number[] | null;
  banner: string | null;
  company_id: string;
  abstraction_flow: string | null;
  __project__: OnboardingProject;
  __has_project__: boolean;
  generate_link: string;
  source_asset_address: string;
  asset_images: AssetImages;
}

interface OnboardingProject {
  id: number;
  title: string;
  is_claimed: boolean;
  company_id: string;
  description: string;
  logo: string;
  created_at: string;
  updated_at: string;
}

interface AssetImages {
  source_asset_icon: string;
  dest_asset_icon: string;
  source_asset_symbol: string;
  dest_asset_symbol: string;
}

export type AbstractionData = {
  id: string;
  transaction_layout: {
    layout: {
      bridge: {
        from: {
          name: string;
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        to: {
          asset: string;
          name: string;
          blockchain: string;
          assetLogo: string;
        };
        protocols: Protocol[];
      };
      swap: {
        from: {
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        to: {
          asset: string;
          blockchain: string;
          assetLogo: string;
        };
        protocols: Protocol[];
      };
      stake: {
        asset: {
          asset_slug: string;
          blockchain_slug: string;
        };
        logo: string;
        name: string;
      };
    };
  };
};

export type ExplorerTabs =
  | "Explore apps"
  | "Ranking"
  | "Stake & Earn"
  | "onboarding"
  | "TownSquare NFT";
export type OutputToken = {
  id: number;
  category: string;
  chain: string;
  to_asset: string;
  to_asset_address: string;
  to_asset_logo: string;
  to_network: string;
  to_network_id: string;
  to_network_url: string;
  apr: string;
  noOfStakers: string;
  totalValStaked: string;
  __project__: {
    id: number;
    title: string;
  };
};

export type InputToken = {
  id: number;
  category: string;
  chain: string;
  from_asset: string;
  from_asset_logo: string;
  from_asset_address: string;
  from_asset_decimal: string;
  from_network: string;
  from_network_url: string;
};

export type StakeAggregatorAssetsData = {
  sourceAssets: InputToken[];
  stakeFeatures: OutputToken[];
};

export type StakeAggregatorAssetsResponse = {
  statusCode: number;
  data: StakeAggregatorAssetsData;
};

export type ApprovalTxnType = {
  type: string;
  data: {
    to: `0x${string}`;
    data: `0x${string}`;
    from: string;
    value: {
      _hex: string;
      _isBigNumber: boolean;
    };
  };
  chainId: number;
  quote: {
    eta: string;
    amountIn: number;
    amountOut: number;
    gasFee: string;
  };
};

export type BridgeTxnType = {
  type: string;
  data: {
    to: `0x${string}`;
    data: `0x${string}`;
    from: string;
    value: {
      _hex: string;
      _isBigNumber: boolean;
    };
  };
  chainId: number;
  quote: {
    amountIn: number;
    amountOut: string;
    gasFee: string;
    eta: string;
  };
  layout: {
    sourceAsset: string;
    sourceAssetLogo: string;
    destinationAsset: string;
    sourceChain: string;
    sourceChainLogo: string;
    destinationChain: string;
    destinationAssetLogo: string;
    destinationChainLogo: string;
    protocolName: string;
    protocolLogo: string;
  };
};

export type StakeTxnType = {
  type: string;
  data: {
    chainId: string;
    to: `0x${string}`;
    data: `0x${string}`;
    from: string;
    value: {
      _hex: string;
      _isBigNumber: boolean;
    };
  };
  chainId: number;
  quote: {
    amountIn: number;
    amountOut: string;
    gasFee: string;
    eta: string;
  };
  layout: {
    sourceAsset: string;
    sourceAssetLogo: string;
    destinationAsset: string;
    sourceChain: string;
    sourceChainLogo: string;
    destinationChain: string;
    destinationAssetLogo: string;
    destinationChainLogo: string;
    protocolName: string;
    protocolLogo: string;
  };
};

export type SwapTxnType = {
  type: string;
  data: {
    to: `0x${string}`;
    data: `0x${string}`;
    from: string;
    value: {
      _hex: string;
      _isBigNumber: boolean;
    };
  };
  chainId: number;
  quote: {
    amountIn: number;
    amountOut: string;
    gasFee: string;
    eta: string;
  };
  layout: {
    sourceAsset: string;
    sourceAssetLogo: string;
    destinationAsset: string;
    sourceChain: string;
    sourceChainLogo: string;
    destinationChain: string;
    destinationAssetLogo: string;
    destinationChainLogo: string;
    protocolName: string;
    protocolLogo: string;
  };
};

export type LendTxnType = {
  type: string;
  data: {
    to: `0x${string}`;
    data: `0x${string}`;
    from: string;
    value: {
      _hex: string;
      _isBigNumber: boolean;
    };
  };
  chainId: number;
  quote: "";
  layout: {
    sourceAsset: string;
    sourceAssetLogo: string;
    destinationAsset: string;
    sourceChain: string;
    sourceChainLogo: string;
    destinationChain: string;
    destinationAssetLogo: string;
    destinationChainLogo: string;
    protocolName: string;
    protocolLogo: string;
  };
};

export type GasFeeType = {
  chainName: string;
  totalFee: number;
  nativeAssetSymbol: string;
};

export type TransactionResponse = {
  statusCode: 201;
  data: {
    txn_function: (
      | BridgeTxnType
      | ApprovalTxnType
      | SwapTxnType
      | StakeTxnType
    )[];
    txn_id: string;
    gas_fee: GasFeeType[];
    apr: string;
  };
  error: null;
};

export interface Transaction {
  id: string;
  onboarding_id: number;
  type: string;
  amount: number;
  status: string;
  user_address: string;
  failure_point: string | null;
  created_at: string;
  updated_at: string;
}

export type CheckUsernameResponse = {
  data: {
    isExist: boolean;
  };
};

export type GetPrimaryNameResponse = {
  data: {
    id: string;
    primaryUsername: string;
    userAddress: string;
    created_at: string;
    updated_at: string;
  };
};

export interface UserItem {
  user_id: string;
  user_adddress: string;
  points: string;
  rank: number;
  isCurrentUser?: boolean;
  createdAt?: string;
  username?: string
}

export interface RankResponse {
  data: {
    points: string;
    user_id: string;
    user_adddress: string;
    rank: number;
  }[];
}
